import { useMemo, useState } from 'react';
import { OrderStateType } from '../../../../types/OrderStateType';
import { OrderFeedbackType } from '../../../../types/OrderFeedbackType';
import './ActionsCorner.scss';

import { ActionTypeAdmin } from '../../../../types/ActionType';
import { Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';

import { CancelDialog } from './Dialogs/Cancel';
import { CloseDialog } from './Dialogs/Close';

import { OrderType } from '../../../../types/OrderType';
import { ReleasePaymentDialog } from './Dialogs/Release';
import { ReverseDialog } from './Dialogs/Reverse';
import { OrderDetailsType } from '../../../../types/OrderDetailsType';
import { getUserInfo } from '../../../../../../helpers/localStorageHandler';
import Stomp from 'stompjs';

type ActionsCornerProps = {
	orderState: OrderStateType;
	orderType: OrderType;
	actions: ActionTypeAdmin[];
	orderNumber: string;
	merchantId: string;
	feedback: OrderFeedbackType;
	userName: string;
	quantity: string;
	amount: string;
	isActionsDisabled: boolean;
	appealTask: OrderDetailsType['appealTask'];
	triggerAction: (action: ActionTypeAdmin, otp?: string) => void;
	getOrderDetails: () => void;
	otpVlaid: { value: boolean };
	loading: boolean;
	stompClient: Stomp.Client | null;
};

function ActionsCorner({
	orderState,
	triggerAction,
	actions,
	orderType,
	appealTask,
	orderNumber,
	otpVlaid,
	loading,
	stompClient,
}: ActionsCornerProps) {
	const { t } = useTranslation();
	const userInfo = getUserInfo();

	// dialogs states
	const [showCancelDialog, setShowCancelDialog] = useState(false);
	const [showCloseDialog, setShowCloseDialog] = useState(false);
	const [showReleaseDialog, setShowReleaseDialog] = useState(false);
	const [showRevertDialog, setShowRevertDialog] = useState(false);

	// don't require a popup

	const handelClose = () => triggerAction('CANCEL_APPEAL');
	const handelCancelOrder = () => triggerAction('CANCEL');
	const handelReleaseOrder = (otp: string) => triggerAction('RELEASE_PAYMENT', otp);
	const handelRevertOrder = () => triggerAction('REVERT');

	const canTriggerAction = useMemo(() => {
		if (orderState === 'APPEAL' && userInfo?.role !== 'SUPER_USER') {
			return appealTask?.assigneeId !== userInfo?.id;
		}
		if (!stompClient) {
			return true;
		}
		return false;
	}, [orderState, appealTask, stompClient]);
	return (
		<div className='actions-corner-container'>
			{orderState ? (
				<>
					<div className='buttons-container'>
						{(orderState === 'OPENED' || orderState === 'TRANSFERRED' || orderState === 'EXPIRED') && (
							<button
								className='bg-[#fcd632] border-none text-black w-[125px] h-[56px] xss:mb-2 lg:mb-0'
								onClick={() => triggerAction('APPEAL')}
								disabled={canTriggerAction || loading}
								id='olc_APPEAL'
							>
								{t<string>('appeal')}
							</button>
						)}
						{actions.includes('REVERT') && (
							<button
								className='bg-[#0183ff] border-none text-[white] w-[120px] h-[56px] xss:mb-2 lg:mb-0'
								onClick={() => setShowRevertDialog(true)}
								disabled={canTriggerAction}
								id='olc_REVERT'
							>
								{t<string>('reverseToPreviousStage')}
							</button>
						)}
						{actions.includes('RELEASE_PAYMENT') && (
							<button
								className='bg-[#0183ff] border-none text-[white] w-[120px] h-[56px] xss:mb-2 md:lg-0'
								onClick={() => setShowReleaseDialog(true)}
								disabled={canTriggerAction}
								id='olc_RELEASE_PAYMENT'
							>
								{t<string>('releasePayment')}
							</button>
						)}
						{actions.includes('CANCEL_APPEAL') && (
							<button
								className='bg-[#f6465d] border-none text-[white] w-[120px] h-[56px] xss:mb-2 lg:mb-0'
								onClick={() => setShowCloseDialog(true)}
								disabled={canTriggerAction}
								id='olc_CANCEL_APPEAL'
							>
								{t<string>('closeAppeal')}
							</button>
						)}
						{actions.includes('CANCEL') && (
							<button
								className='bg-[white] border-[black] border-[1px] w-[120px] h-[56px] xss:mb-2 lg:mb-0'
								onClick={() => setShowCancelDialog(true)}
								disabled={canTriggerAction}
								id='olc_CANCEL'
							>
								{t<string>('cancelOrder')}
							</button>
						)}
					</div>
				</>
			) : (
				<Skeleton active paragraph={{ rows: 1 }} />
			)}
			<CancelDialog
				showCancelDialog={showCancelDialog}
				setShowCancelDialog={setShowCancelDialog}
				handelCancel={handelCancelOrder}
				loading={loading}
			/>
			<CloseDialog
				showCloseDialog={showCloseDialog}
				setShowCloseDialog={setShowCloseDialog}
				handelClose={handelClose}
				loading={loading}
			/>
			<ReleasePaymentDialog
				showReleaseDialog={showReleaseDialog}
				setShowReleaseDialog={setShowReleaseDialog}
				handelReleaseOrder={handelReleaseOrder}
				orderType={orderType}
				orderNumber={orderNumber}
				otpVlaid={otpVlaid}
				loading={loading}
			/>
			<ReverseDialog
				showRevertDialog={showRevertDialog}
				setShowRevertDialog={setShowRevertDialog}
				handelRevertOrder={handelRevertOrder}
				loading={loading}
			/>
		</div>
	);
}

export default ActionsCorner;
