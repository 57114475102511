import { Grid, Modal, Table, notification } from 'antd';
import { Request } from '../types/Request';
import { useEffect, useMemo, useState } from 'react';
import { columns } from '../columns';
import { useTranslation } from 'react-i18next';
import { TransactionReportModal } from '../modals/TransactionReportModal';
import axios from 'axios';
import { API_ENDPOINTS } from '../../../assets/api/endpoints';
import { getUserInfo } from '../../../helpers/localStorageHandler';
import AdminUserListTable from './AdminUserListTable';
import { ContactUserModal } from '../modals/contactUserModal';
import { useLocation } from 'react-router-dom';
import { useConfigurationContext } from '../../../store/configurationContext';
import { StyledPagination } from '../../../components/table.style';

type WalletTableProps = {
	data: Request[];
	loading: boolean;
	page: number;
	numberOfEntries: number;
	setPage: (page: number) => void;
	total: number;
	setOnRequest: (value: boolean) => void;
	onRequest: boolean;
};

export const RequestTable = (props: WalletTableProps) => {
	const { lg } = Grid.useBreakpoint();
	const token = getUserInfo()?.token;
	const location = useLocation();

	const [transactionReportModalOpen, setTransactionReportModalOpen] = useState(false);
	const [transactionReportData, setTransactionReportData] = useState<{ payload: any }>();
	const [showUserListPopup, setShowUserListPopup] = useState(false);
	const [showMailModal, setShowMailModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [showOtpModal, setShowOtpModal] = useState(false);
	const queryParams = new URLSearchParams(location.search);
	const transactionNumber = queryParams.get('transactionNumber');
	const { configurationState } = useConfigurationContext();
	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value || '';
	const secondary_color = configurationState.find((item) => item.configKey === 'secondary_color')?.value || '';

	const { t } = useTranslation();

	useEffect(() => {
		if (transactionNumber) {
			getTransaction(transactionNumber || '');
		}
	}, [location.search]);

	const showRequestConfirmation = (
		value: string,
		recaptcha?: string,
		isEnabled?: boolean,
		comments?: string,
		otp?: string,
	) => {
		// Construct headers conditionally
		const headers: Record<string, string> = {
			Authorization: `Bearer ${token}`,
		};

		if (isEnabled && recaptcha) {
			headers['RECAPTCHA-TOKEN'] = recaptcha;
		}
		setIsLoading(true);
		axios
			.post(
				API_ENDPOINTS.sendRequestAction,
				{
					action: value,
					transactionNumber: transactionReportData?.payload.transactionNumber,
					otp: otp ?? undefined,
					comment: comments ?? undefined,
				}, // Pass an empty object for the request body
				{ headers }, // Include headers
			)
			.then((res) => {
				onView(transactionReportData?.payload);
				setShowOtpModal(false);
				props.setOnRequest(!props.onRequest);
			})
			.catch((err) => {
				if (err.response?.data.code === 2050 || err.response?.data.code === 2051) {
					setShowOtpModal(true);
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const onView = (record: Request) => {
		const transactionId = record?.transactionNumber;
		getTransaction(transactionId);
	};

	const getTransaction = (transactionNumber: number | string) => {
		axios
			.get(
				API_ENDPOINTS.operationReport
					.replace('%operation%', 'TRADING_ACCOUNT')
					.replace('%transactionNumber%', `${transactionNumber}`),
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			)
			.then((response) => {
				setTransactionReportData({
					payload: {
						...response.data,
					},
				});
				setTransactionReportModalOpen(true);
			})
			.catch(console.error);
	};
	const walletTableColumns = useMemo(
		() =>
			columns({
				isMobile: !lg,
				translate: t,
				onView,
			}),
		[lg, t],
	);

	const handelAssign = (userId: number) => {
		axios
			.post(
				API_ENDPOINTS.reassignRequest
					.replace('%taskId%', `${transactionReportData?.payload?.request?.requestId}`)
					.replace('%adminId%', `${userId}`),
				{},
				{
					headers: { Authorization: `Bearer ${token}` },
				},
			)
			.then(() => {
				setShowUserListPopup(false);
				onView(transactionReportData?.payload);
				notification['success']({ message: '', description: t<string>('reassignSuccessfully') });
			})
			.catch((err: any) => console.error(err))
			.finally(() => setShowUserListPopup(false));
	};

	return (
		<div>
			{transactionReportModalOpen && (
				<TransactionReportModal
					open={transactionReportModalOpen}
					payload={transactionReportData?.payload}
					setOpen={setTransactionReportModalOpen}
					setShowUserListPopup={setShowUserListPopup}
					handelAssign={handelAssign}
					onRequestAction={showRequestConfirmation}
					showMailModal={setShowMailModal}
					isRequestLoading={isLoading}
					showOtpModal={showOtpModal}
					setShowOtpModal={setShowOtpModal}
				/>
			)}
			<AdminUserListTable
				showClosePopup={showUserListPopup}
				setShowClosePopup={setShowUserListPopup}
				handelAssign={handelAssign}
			/>
			<ContactUserModal
				open={showMailModal}
				transactionNumber={transactionReportData?.payload.transactionNumber}
				userName={transactionReportData?.payload.userName}
				phone={transactionReportData?.payload.phone}
				email={transactionReportData?.payload.email}
				setOpen={setShowMailModal}
			/>
			<StyledPagination bgColor={themeColor} color={secondary_color}>
				<Table
					columns={walletTableColumns}
					dataSource={props.data}
					loading={props.loading}
					pagination={{
						pageSize: props.numberOfEntries,
						onChange(page, pageSize) {
							props.setPage(page - 1);
						},
						total: props.total,
						current: props.page + 1,
						showSizeChanger: false,
					}}
					tableLayout='fixed'
					onRow={(record: Request) => ({
						onClick: (event: any) => {
							onView(record);
						},
					})}
					rowKey='id'
				/>
			</StyledPagination>
		</div>
	);
};
