import { Modal, Spin, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@ant-design/icons';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { RollbackOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { useMemo } from 'react';
import { useConfigurationContext } from '../../../../../../store/configurationContext';
import { useDirectionContext } from '../../../../../../store/DirectionContext';

export function PhotoPreview({
	showModal,
	setShowModal,
	paymentProofFileId,
	photo,
	pdfUrl,
}: {
	showModal: boolean;
	setShowModal: (value: boolean) => void;
	paymentProofFileId: string;
	photo: string;
	pdfUrl: string;
}) {
	const { t } = useTranslation();
	const { configurationState } = useConfigurationContext();
	const { directionState } = useDirectionContext();
	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value || '';
	const secondary_color = configurationState.find((item) => item.configKey === 'secondary_color')?.value || '';

	const iconsStyles = useMemo(() => {
		return {
			fontSize: '18px',
			cursor: 'pointer',
			color: secondary_color,
			margin: '5px 0px',
			backgroundColor: themeColor,
			width: '30px',
			height: '30px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: '10px',
		};
	}, [configurationState]);
	const downloadFile = () => {
		const a = document.createElement('a');
		a.href = `data:image/jpeg;base64,${photo}`;
		a.download = paymentProofFileId;
		a.click();
		setShowModal(false);
	};
	return (
		<Modal onCancel={() => setShowModal(false)} open={showModal} footer={null}>
			<div className='flex justify-center flex-col align-middle items-center'>
				{!pdfUrl && !photo ? (
					<Spin spinning={!pdfUrl && !photo}></Spin>
				) : (
					<>
						<div className='flex justify-center items-center text-2xl m-auto w-[100%] my-7 mx-3'>
							{pdfUrl && (
								<Tooltip title={t('download')}>
									<DownloadOutlined style={iconsStyles} onClick={downloadFile} />
								</Tooltip>
							)}
							{photo ? (
								<TransformWrapper>
									{({ zoomIn, zoomOut, resetTransform }) => (
										<>
											<div
												className='flex flex-col p-2 rounded'
												style={
													directionState === 'ltr'
														? { paddingLeft: '0px', paddingRight: '8px' }
														: { paddingLeft: '8px', paddingRight: '0px' }
												}
											>
												<Tooltip title={t('zoomIn')}>
													<ZoomInOutlined onClick={() => zoomIn()} style={iconsStyles} />
												</Tooltip>
												<Tooltip title={t('zoomOut')}>
													<ZoomOutOutlined onClick={() => zoomOut()} style={iconsStyles} />
												</Tooltip>
												<Tooltip title={t('reset')}>
													<RollbackOutlined onClick={() => resetTransform()} style={iconsStyles} />
												</Tooltip>
												<Tooltip title={t('download')}>
													<DownloadOutlined style={iconsStyles} onClick={downloadFile} />
												</Tooltip>
											</div>
											<TransformComponent>
												<img
													src={`data:image/jpeg;base64,${photo}`}
													alt=''
													style={{ maxWidth: '100%', maxHeight: '70vh', objectFit: 'contain' }}
												/>
											</TransformComponent>
										</>
									)}
								</TransformWrapper>
							) : (
								<object data={pdfUrl} type='application/pdf' width='100%' height='500px' className='pdf-viewer'>
									<p>
										{t('Your browser does not support this File.')}{' '}
										<a href={pdfUrl} target='_blank' rel='noopener noreferrer'>
											{t('View File')}
										</a>
									</p>
								</object>
							)}
						</div>
					</>
				)}
			</div>
		</Modal>
	);
}
