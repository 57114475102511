import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './AddUser.scss';
import { getUserInfo } from '../../helpers/localStorageHandler';
import { Button, Form, Input, InputNumber, Select, Spin, Switch } from 'antd';
import axios from 'axios';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCountriesContext } from '../../store/CountriesContext';
import Header from '../../components/Header/Header';
import { PASSWORD_CHAR_LENGHT, PASSWORD_VALIDATION } from '../../assets/config/validation-regex';
const AddUser = () => {
	const params = useParams();
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const [adminUser, setAdminUser] = useState({
		email: '',
		firstname: '',
		lastname: '',
		phone: '',
		password: '',
		role: '',
		countryCode: '',
		canHandleAppeals: true,
		canHandleRequests: true,
	});
	const [user, setUser] = useState({
		email: '',
		firstname: '',
		lastname: '',
		phone: '',
		password: '',
		role: '',
		countryCode: '',
		canHandleAppeals: true,
		canHandleRequests: true,
	});
	const [loading, setLoading] = useState(false);
	const [passwordMessage, setPasswordMessage] = useState('');
	const token = getUserInfo()?.token;
	const queryParams = new URLSearchParams(location.search);
	const userId = queryParams.get('userAdmin');

	const { countriesState } = useCountriesContext();

	const submitRecord = () => {
		setLoading(true);

		let updatedData = { ...adminUser, email: undefined, password: undefined };
		let data = userId ? updatedData : adminUser;

		axios(`${API_ENDPOINTS.addAdminUser}${userId ? `/${userId}` : ''}`, {
			method: userId ? 'PUT' : 'POST',
			headers: { Authorization: `Bearer ${token}` },
			data,
		})
			.then((_) => navigate('/admin-users'))
			.catch((err) => console.error(err))
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		if (userId) {
			axios
				.get(API_ENDPOINTS.getUserAdmin.replace('%userId%', `${userId}`), {
					headers: { Authorization: `Bearer ${token}` },
				})
				.then(({ data }) => {
					setAdminUser(data);
					setUser(data);
				});
		}
	}, []);

	const isValid = useMemo(() => {
		if (!userId && !PASSWORD_VALIDATION.test(adminUser.password)) {
			return false;
		}
		if (
			!adminUser.firstname ||
			!adminUser.lastname ||
			!adminUser.email ||
			(!adminUser.password && !userId) ||
			!adminUser.phone ||
			!adminUser.role ||
			!adminUser.countryCode
		)
			return false;
		if (
			adminUser.firstname === user.firstname &&
			adminUser.lastname === user.lastname &&
			adminUser.phone === user.phone &&
			adminUser.role === user.role &&
			adminUser.countryCode === user.countryCode &&
			adminUser.canHandleAppeals === user.canHandleAppeals &&
			adminUser.canHandleRequests === user.canHandleRequests
		)
			return false;
		return true;
	}, [adminUser]);

	return (
		<div>
			<Header title={params.tradingPlatform ? t<string>('editAdmin') : t<string>('addNewAdmin')} />
			<div className='border border-solid  border-[#E6E6E6] rounded-md max-w-[900px] mx-auto  p-10  my-6 bg-white'>
				<Spin spinning={loading}>
					<div>
						<Form layout='vertical'>
							<div className='flex flex-col items-center justify-center w-full'>
								<div className='w-full flex justify-center'>
									<Form.Item label={<b>{t<string>('email')}</b>} className='max-w-[300px] min-w-[300px]'>
										<Input
											disabled={Boolean(userId)}
											value={adminUser.email}
											onChange={(e) => setAdminUser({ ...adminUser, email: e.target.value })}
										/>
									</Form.Item>
								</div>
								<div className='w-full flex justify-center'>
									<Form.Item label={<b>{t<string>('firstName')}</b>} className='max-w-[300px] min-w-[300px]'>
										<Input
											value={adminUser.firstname}
											onChange={(e) => setAdminUser({ ...adminUser, firstname: e.target.value })}
										/>
									</Form.Item>
								</div>
								<div className='w-full flex justify-center'>
									<Form.Item label={<b>{t<string>('lastName')}</b>} className='max-w-[300px] min-w-[300px]'>
										<Input
											value={adminUser.lastname}
											onChange={(e) => setAdminUser({ ...adminUser, lastname: e.target.value })}
										/>
									</Form.Item>
								</div>
								<div className='w-full flex justify-center'>
									<Form.Item label={<b>{t<string>('phoneNumber')}</b>} className='max-w-[300px] min-w-[300px]'>
										<Input
											value={adminUser.phone}
											onChange={(e) => setAdminUser({ ...adminUser, phone: e.target.value })}
										/>
									</Form.Item>
								</div>
								{!Boolean(userId) && (
									<div className='w-full flex justify-center'>
										<Form.Item label={<b>{t<string>('password')}</b>} className='max-w-[300px] min-w-[300px]'>
											<Input.Password
												placeholder={t<string>('password')}
												autoComplete='new-password'
												disabled={Boolean(userId)}
												value={adminUser.password}
												onChange={(e) => {
													if (!PASSWORD_VALIDATION.test(e.target.value)) {
														setPasswordMessage(t<string>('passwordMsg', { value: PASSWORD_CHAR_LENGHT }));
													} else {
														setPasswordMessage('');
													}
													setAdminUser({ ...adminUser, password: e.target.value });
												}}
											/>
											{passwordMessage && <p className='text-red-600 my-2 p-0'>{passwordMessage}</p>}
										</Form.Item>
									</div>
								)}
								<div className='w-full flex justify-center'>
									<Form.Item label={<b>{t<string>('country')}</b>} className='max-w-[300px] min-w-[300px]'>
										<Select
											value={adminUser.countryCode}
											loading={false}
											onChange={(value) => setAdminUser({ ...adminUser, countryCode: value })}
										>
											{countriesState?.countries.map((item, index) => (
												<Select.Option key={index} value={item.countryCode}>
													{item.countryCode}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</div>
								<div className='w-full flex justify-center'>
									<Form.Item label={<b>{t<string>('role')}</b>} className='max-w-[300px] min-w-[300px]'>
										<Select
											value={adminUser.role}
											loading={false}
											onChange={(value) => setAdminUser({ ...adminUser, role: value })}
										>
											<Select.Option value={'SUPER_USER'}>{'SUPER_USER'}</Select.Option>
											<Select.Option value={'ADMIN'}>{'ADMIN'}</Select.Option>
										</Select>
									</Form.Item>
								</div>
								<div className='w-full flex justify-center'>
									<Form.Item label={t<string>('canHandleAppeal')}>
										<Switch
											checked={adminUser.canHandleAppeals}
											checkedChildren={t<string>('yes')}
											unCheckedChildren={t<string>('no')}
											onChange={(value) => setAdminUser({ ...adminUser, canHandleAppeals: value })}
										/>
									</Form.Item>
									<Form.Item label={t<string>('canHandleRequest')}>
										<Switch
											checked={adminUser.canHandleRequests}
											checkedChildren={t<string>('yes')}
											unCheckedChildren={t<string>('no')}
											onChange={(value) => setAdminUser({ ...adminUser, canHandleRequests: value })}
										/>
									</Form.Item>
								</div>
							</div>
						</Form>
						<div className='flex justify-center'>
							<Button
								onClick={submitRecord}
								disabled={!isValid}
								className='px-10 bg-[#01A2FF] font-bold'
								style={{ boxShadow: 'none' }}
								type='primary'
							>
								{t<string>('submit')}
							</Button>
						</div>
					</div>
				</Spin>
			</div>
		</div>
	);
};

export default AddUser;
