import { useEffect } from 'react';
import { Checkbox, Input, Modal, Spin } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './dialogs.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import { OrderType } from '../../../../../types/OrderType';
import { getUserInfo } from '../../../../../../../helpers/localStorageHandler';
import axios from 'axios';
import { API_ENDPOINTS } from '../../../../../../../assets/api/endpoints';
import { getUserLanguageInCookie } from '../../../../../../../helpers/cookiesHandler';
import { convertMilliseconds } from '../../../../../../../helpers/getRemaingTime';

const numberFormatter = (num: number) => {
	return `${num > 9 ? '' : '0'}${num}`;
};
const OTP_LENGTH = 6;
export function ReleasePaymentDialog({
	showReleaseDialog,
	setShowReleaseDialog,
	handelReleaseOrder,
	orderType,
	orderNumber,
	otpVlaid,
	loading,
}: {
	showReleaseDialog: boolean;
	setShowReleaseDialog: (value: boolean) => void;
	handelReleaseOrder: (otp: string) => void;
	orderType: OrderType;
	orderNumber: string;
	otpVlaid: { value: boolean };
	loading: boolean;
}) {
	const { t } = useTranslation();
	const [isFirstChecked, setIsFirstChecked] = useState(false);
	const [isSecondChecked, setIsSecondChecked] = useState(false);
	const [minutes, setMinutes] = useState('0');
	const [seconds, setSeconds] = useState('0');
	const [expireTime, setExpireTime] = useState(0);
	const [currentCountdown, setCurrentCountdown] = useState(0);
	const [otp, setOtp] = useState(['', '', '', '', '', '']);

	const token = getUserInfo()?.token;
	let intervalId: any;
	const handleOtpChange = (index: number, value: string) => {
		const newOtp = [...otp];
		newOtp[index] = value;
		// Move to the next input field if a digit is entered
		if (value !== '' && index < 6) {
			const nextInput = document.getElementById(`otp-input-${index + 1}`) as HTMLInputElement | null;
			if (nextInput) {
				nextInput.focus();
			}
		}
		if (value == '') {
			const nextInput = document.getElementById(`otp-input-${index - 1}`) as HTMLInputElement | null;
			if (nextInput) {
				nextInput.focus();
			}
		}

		setOtp(newOtp);
	};

	useEffect(() => {
		setOtp(['', '', '', '', '', '']);
		if (showReleaseDialog) {
			axios(`${API_ENDPOINTS.getOrderOtp.replace('%orderNumber%', orderNumber)}`, {
				method: 'POST',
				headers: { Authorization: `Bearer ${token}` },
			}).then((res) => {
				setExpireTime(res.data.remainingExpiryTime);
				setCurrentCountdown(res.data.remainingExpiryTime);
			});
		}
	}, [showReleaseDialog]);

	useEffect(() => {
		clearInterval(intervalId);
		intervalId = setInterval(() => {
			setCurrentCountdown((prevCountdown) => prevCountdown - 1000);
		}, 1000);

		return () => clearInterval(intervalId);
	}, [expireTime]);

	useEffect(() => {
		const { minutes, seconds } = convertMilliseconds(currentCountdown);
		if (currentCountdown <= 0) {
			setShowReleaseDialog(false);
			clearInterval(intervalId);
			return;
		}
		setMinutes(numberFormatter(Math.floor(minutes)));
		setSeconds(numberFormatter(seconds));
	}, [currentCountdown]);

	useEffect(() => {
		if (otpVlaid.value) {
			setIsFirstChecked(false);
			setIsSecondChecked(false);
			setShowReleaseDialog(false);
		}
	}, [otpVlaid]);
	return (
		<Modal
			maskClosable={false}
			destroyOnClose={true}
			title={
				<div className='flex items-center'>
					<InfoCircleOutlined style={{ fontSize: '30px', textAlign: 'center', display: 'block' }} />
					<h4 className='m-0 px-2 text-center'>{t<string>('releaseMerchantPayment')}</h4>
				</div>
			}
			open={showReleaseDialog}
			onCancel={() => setShowReleaseDialog(false)}
			footer={null}
		>
			<div className='checkBox-container'>
				<Checkbox
					onChange={(e) => setIsFirstChecked(e.target.checked)}
					style={{ marginBottom: '20px' }}
					id='olc_releseOption_1'
				>
					{orderType === 'BUY'
						? t<string>('releaseMerchantPaymentMessage1')
						: t<string>('releaseMerchantPaymentMessageSell')}
				</Checkbox>
				<Checkbox
					onChange={(e) => setIsSecondChecked(e.target.checked)}
					style={{ marginBottom: '20px' }}
					id='olc_releseOption_2'
				>
					{orderType === 'BUY'
						? t<string>('releaseMerchantPaymentMessage2')
						: t<string>('releaseMerchantPaymentMessageSell2')}
				</Checkbox>
			</div>
			<p>{t<string>('enterYourOtp')}</p>
			<div className='mb-4 flex xss:flex-col md:flex-row '>
				<div className='xss:mb-2 md:mb-0' style={{ direction: 'ltr', marginBlockStart: '5px' }}>
					{otp.map((digit, index) => (
						<Input
							key={index}
							id={`otp-input-${index}`}
							value={digit}
							onChange={(e) => handleOtpChange(index, e.target.value)}
							maxLength={1}
							style={{ width: '2rem', marginRight: '1rem', textAlign: 'center' }}
							onPaste={(e) => {
								const copiedValue = e.clipboardData.getData('text/plain').split('');
								const otpValue =
									copiedValue.length > OTP_LENGTH
										? copiedValue.slice(0, OTP_LENGTH)
										: [...copiedValue, ...Array(OTP_LENGTH - copiedValue.length).fill('')];
								setOtp(otpValue);
							}}
						/>
					))}
				</div>
				<div className='xss:m-0 sm:m-auto'>
					{t<string>('expireIn')}:{' '}
					{getUserLanguageInCookie() === 'ar' ? (
						<>
							<span>{seconds}</span> : <span>{minutes}</span>{' '}
						</>
					) : (
						<>
							<span>{minutes}:</span> <span>{seconds}</span>
						</>
					)}
				</div>
			</div>
			<div className='flex justify-end'>
				<button
					className='bg-[#e5f1ff] border-none py-2 px-4 m-2 text-[grey] rounded cursor-pointer'
					onClick={() => {
						setShowReleaseDialog(false);
					}}
					id='olc_releseOption_no'
				>
					{t<string>('cancel')}
				</button>
				<Spin spinning={loading}>
					<button
						className='bg-[#0077ff] border-none py-2 px-4 m-2 text-[white] rounded cursor-pointer'
						disabled={!isFirstChecked || !isSecondChecked || otp.some((value) => value === '')}
						style={{ opacity: !isFirstChecked || !isSecondChecked || otp.some((value) => value === '') ? '.5' : '1' }}
						onClick={() => {
							handelReleaseOrder(otp.join(''));
						}}
						id='olc_releseOption_yes'
					>
						{t<string>('submit')}
					</button>
				</Spin>
			</div>
		</Modal>
	);
}
