import { ExclamationCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Modal, notification, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { API_ENDPOINTS } from '../../../assets/api/endpoints';
import { getUserInfo } from '../../../helpers/localStorageHandler';
import { getUserLanguageInCookie } from '../../../helpers/cookiesHandler';
import { convertMilliseconds } from '../../../helpers/getRemaingTime';

const numberFormatter = (num: number) => {
	return `${num > 9 ? '' : '0'}${num}`;
};

const OTP_LENGTH = 6;
export function OTPTransactionModal({
	showOtpModal,
	setShowOtpModal,
	amount,
	transactionNumber,
	vervifyOtp,
	type,
	isLoading,
	showOtp,
}: {
	showOtpModal: boolean;
	setShowOtpModal: (value: boolean) => void;
	amount: string;
	transactionNumber: string;
	vervifyOtp: (otp: string) => void;
	type: boolean;
	isLoading: boolean;
	showOtp: boolean;
}) {
	const { t } = useTranslation();
	const [isChecked, setIsChecked] = useState(false);
	const [otp, setOtp] = useState(['', '', '', '', '', '']);
	const [minutes, setMinutes] = useState('0');
	const [seconds, setSeconds] = useState('0');
	const [expireTime, setExpireTime] = useState(0);
	const [currentCountdown, setCurrentCountdown] = useState(0);
	const token = getUserInfo()?.token;

	let intervalId: any;
	const handleOtpChange = (index: number, value: string) => {
		const newOtp = [...otp];
		newOtp[index] = value;
		// Move to the next input field if a digit is entered
		if (value !== '' && index < 6) {
			const nextInput = document.getElementById(`otp-input-${index + 1}`) as HTMLInputElement | null;
			if (nextInput) {
				nextInput.focus();
			}
		}
		if (value == '') {
			const nextInput = document.getElementById(`otp-input-${index - 1}`) as HTMLInputElement | null;
			if (nextInput) {
				nextInput.focus();
			}
		}

		setOtp(newOtp);
	};

	useEffect(() => {
		clearInterval(intervalId);
		intervalId = setInterval(() => {
			setCurrentCountdown((prevCountdown) => prevCountdown - 1);
		}, 1000);

		return () => clearInterval(intervalId);
	}, [expireTime]);

	useEffect(() => {
		const { minutes, seconds } = convertMilliseconds(currentCountdown);
		if (expireTime && currentCountdown <= 0) {
			showOtp && setShowOtpModal(false);
			clearInterval(intervalId);
			return;
		}
		setMinutes(numberFormatter(Math.floor(minutes)));
		setSeconds(numberFormatter(Math.floor(seconds)));
	}, [currentCountdown]);

	const sendOtp = (resend?: boolean) => {
		axios(`${API_ENDPOINTS.getOtpTransaction.replace('%transactionNumber%', transactionNumber)}`, {
			method: 'POST',
			headers: { Authorization: `Bearer ${token}` },
		}).then((res) => {
			setExpireTime(Math.floor(res.data.remainingExpiryTime / 1000));
			setCurrentCountdown(Math.floor(res.data.remainingExpiryTime / 1000));
			if (resend) {
				notification['success']({ message: '', description: t<string>('otpResendMessage') });
			}
		});
	};

	useEffect(() => {
		setOtp(['', '', '', '', '', '']);
		setIsChecked(false);
		if (showOtpModal && showOtp) {
			sendOtp();
		}
	}, [showOtpModal]);

	useEffect(() => {
		setMinutes(numberFormatter(Math.floor((currentCountdown % 3600) / 60)));
		setSeconds(numberFormatter(currentCountdown % 60));
	}, [currentCountdown]);

	return (
		<Modal
			onCancel={() => setShowOtpModal(false)}
			maskClosable={false}
			open={showOtpModal}
			footer={null}
			destroyOnClose
			title={
				<div className='flex align-baseline'>
					<ExclamationCircleFilled className='text-[#0f2147] mx-2' />
					<h4 className='m-0'>{type ? t('approveTransaction') : t<string>('rejectTransaction')}</h4>
				</div>
			}
		>
			<ul style={{ listStyleType: 'none', padding: '0px' }}>
				<li>
					<Checkbox
						checked={isChecked}
						onChange={(e) => setIsChecked(e.target.checked)}
						style={{ marginBottom: '20px' }}
						id='approveTransaction_option'
					>
						{t<string>('depositWithdrawRequest', { amount, type: type ? t('accept') : t('reject') })}
					</Checkbox>
				</li>
			</ul>
			{showOtp && (
				<>
					<p>{t<string>('enterYourOtp')}</p>
					<div className='mb-4 flex '>
						<div style={{ direction: 'ltr', marginBlockStart: '5px' }}>
							{otp.map((digit, index) => (
								<Input
									key={index}
									id={`otp-input-${index}`}
									value={digit}
									onChange={(e) => handleOtpChange(index, e.target.value)}
									maxLength={1}
									style={{ width: '2rem', marginRight: '1rem', textAlign: 'center' }}
									onPaste={(e) => {
										const copiedValue = e.clipboardData.getData('text/plain').split('');
										const otpValue =
											copiedValue.length > OTP_LENGTH
												? copiedValue.slice(0, OTP_LENGTH)
												: [...copiedValue, ...Array(OTP_LENGTH - copiedValue.length).fill('')];
										setOtp(otpValue);
									}}
								/>
							))}
						</div>
						<div className='m-auto'>
							{t<string>('expireIn')}:{' '}
							{getUserLanguageInCookie() === 'ar' ? (
								<>
									<span>{seconds}</span> : <span>{minutes}</span>{' '}
								</>
							) : (
								<>
									<span>{minutes}:</span> <span>{seconds}</span>
								</>
							)}
						</div>
					</div>
					<div>
						<p>
							{t<string>('didNotreceiveOtp')}
							<span className='text-[#0184ff] underline cursor-pointer' onClick={() => sendOtp(true)}>
								{t<string>('resend')}
							</span>
						</p>
					</div>
				</>
			)}
			<Button
				loading={isLoading}
				disabled={!isChecked || (showOtp && otp.some((value) => value === ''))}
				className='flex m-auto justify-center bg-[#0192ff] hover:!text-white text-white border-none outline-none'
				onClick={() => vervifyOtp(otp.join(''))}
				id='approveTransaction_confirm'
			>
				{t<string>('confirm')}
			</Button>
		</Modal>
	);
}
