import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageSubHeader } from '../../components/PageSubHeader';
import { WalletFilters } from './components/WalletFilters';
import { WalletTable } from './components/WalletTable';
import { themeConfig } from './config';
import { ConfigProvider } from 'antd';
import { TransactionReportModal } from './modals/TransactionReportModal';
import { MyAccount } from './types/MyAccount';
import { Wallet } from './types/Wallet';
import axios from 'axios';
import { WalletTransactionsResponse } from './types/walletTransactionResponse';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { getUserInfo } from '../../helpers/localStorageHandler';
import { useLocation } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import Header from '../../components/Header/Header';

export const WalletTransactions = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [numberOfEntries, setNumberOfEntries] = useState('10');
	const [data, setData] = useState<Wallet[]>([]);
	const [totalElements, setTotalElements] = useState(0);
	const [page, setPage] = useState(0);

	const [loading, setLoading] = useState<boolean>(false);

	const [search, setSearch] = useState('');
	const [status, setStatus] = useState<string | null>(null);
	const [type, setType] = useState<string | null>(null);
	const [dateRange, setDateRange] = useState<string[]>([]);
	const [isP2P, setIsP2P] = useState(false);
	const [orderNumber, setOrderNumber] = useState<string | null>(queryParams.get('id') ?? '');
	const [account, setAccount] = useState<MyAccount | null>(null);
	const [operation, setOperation] = useState<string | null>(queryParams.get('operation') ?? null);
	const [onRequest, setOnRequest] = useState(false);
	const walletsNumber = useMemo(() => queryParams.get('walletNumber') ?? '', [location.search]);
	const token = getUserInfo()?.token;

	const isValueInvalid = (value: unknown): boolean =>
		value !== undefined ||
		value !== null ||
		value !== '' ||
		value !== 'ALL' ||
		(Array.isArray(value) && (value as []).some(isValueInvalid));

	const refresh = useCallback(() => {
		fetchTransaction();
	}, []);

	const searchParameter = useMemo(
		() =>
			[
				['createdAt', dateRange],
				['state', status],
				['operation', operation],
				['type', type],
				['order%23orderNumber', orderNumber],
				['tradingAccount%23tpAccountNumber', account?.number],
				['wallet%23walletNumber', walletsNumber],
			]
				.filter(([key, value]) => {
					if (key === 'createdAt') return Boolean(value?.length);
					return Boolean(value);
				})
				.map(([key, value]) =>
					key === 'createdAt'
						? `createdAt%3E${value?.[0]}%2CcreatedAt%3C${dayjs(value?.[1]).format('YYYY-MM-DD 23:59:00')}`
						: `${key}%3A${value}`,
				)
				.join('%2C'),
		[dateRange, orderNumber, status, account, type, operation, walletsNumber],
	);

	useEffect(() => {
		setPage(0);
	}, [dateRange, orderNumber, status, account, type, operation, walletsNumber]);
	const params = useMemo(
		() =>
			[
				['page', page],
				['pageSize', numberOfEntries],
				['sort', 'createdAt-desc'],
			]
				.filter(([, value]) => !!value)
				.reduce(
					(params, [key, value]) => ({
						...params,
						[key as string]: value,
					}),
					{},
				),
		[numberOfEntries, page],
	);
	useEffect(() => {
		fetchTransaction();
	}, [params, token, searchParameter, search, onRequest]);

	const fetchTransaction = () => {
		setLoading(true);
		let searchParams = '';
		if (searchParameter) {
			searchParams = `?search=${searchParameter}`;
		}
		if (search) {
			searchParams += searchParams ? `&wildSearch=${search}` : `?wildSearch=${search}`;
		}
		axios
			.get<WalletTransactionsResponse>(`${API_ENDPOINTS.walletTransactions}${searchParams}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					...params,
				},
			})
			.then((response) => {
				setData(response?.data?.data || []);
				setTotalElements(response?.data?.totalElements);
			})
			.catch(console.error)
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<ConfigProvider theme={themeConfig}>
			<Header title={t<string>('walletTransactions')} description={t<string>('walletTransactionsDescription')} />
			<div className='min-h-[84vh] mx-auto px-[10px] lg:px-[75px] py-5 bg-white'>
				<WalletFilters
					className='m-0'
					numberOfEntries={numberOfEntries}
					setNumberOfEntries={setNumberOfEntries}
					search={search}
					setDateRange={setDateRange}
					dateRange={dateRange}
					setSearch={setSearch}
					status={status}
					setStatus={setStatus}
					type={type}
					setType={setType}
					refresh={refresh}
					isP2P={isP2P}
					setIsP2P={setIsP2P}
					orderNumber={orderNumber as string}
					setOrderNumber={setOrderNumber}
					setAccount={setAccount}
					account={account}
					setOperation={setOperation}
					operation={operation}
				/>
				<WalletTable
					data={data}
					loading={loading}
					page={page}
					numberOfEntries={Number(numberOfEntries)}
					setPage={setPage}
					total={totalElements}
					setOnRequest={setOnRequest}
					onRequest={onRequest}
				/>
			</div>
		</ConfigProvider>
	);
};
