const DOMAIN =
	process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL;

export const API_ENDPOINTS = {
	baseUrl: DOMAIN,
	refreshToken: `${DOMAIN}/api/admin/refresh`,
	signin: `${DOMAIN}/api/admin/login`,
	logout: `${DOMAIN}/api/admin/logout`,
	merchants: `${DOMAIN}/api/admin/merchants`,
	clients: `${DOMAIN}/api/admin/clients`,
	orders: `${DOMAIN}/api/admin/order`,
	orderDetails: `${DOMAIN}/api/admin/order/orderDetail/%orderNumber%`,
	orderAction: `${DOMAIN}/api/admin/order/%orderNumber%/action/%action%`,
	countryAndPaymMethod: `${DOMAIN}/api/admin/country/paymentMethod`,
	accountGroupsLimits: `${DOMAIN}/api/admin/groups/limits`,
	accountGroups: `${DOMAIN}/api/admin/groups`,
	reassign: `${DOMAIN}/api/admin/appeal/%taskId%/assign/%adminId%`,
	dashboard: `${DOMAIN}/api/admin/stats/orders`,
	notifications: `${DOMAIN}/api/admin/notifications/list`,
	notificationsCount: `${DOMAIN}/api/admin/notifications/count/unread`,
	notification: `${DOMAIN}/ws/notification`,
	getPublicNotificationTopic: `/topic/notification/public`,
	getUserNotificationTopic: `/topic/notification/user/%userId%`,
	getRoleNotificationTopic: `/topic/notification/role/%role%`,
	chat: `${DOMAIN}/ws/order-chat`,
	getOrderChatMsgTopic: '/topic/order/%orderNumber%/chat',
	sendOrderChatMsgTopic: '/p2p/order-chat/%orderNumber%/sendMessage',
	getChats: `${DOMAIN}/api/admin/chat/%orderNumber%`,
	uploadFile: `${DOMAIN}/api/chat/file/upload/`,
	uploadImage: `${DOMAIN}/api/chat/image/upload/`,
	countries: `${DOMAIN}/api/admin/countries`,
	paymentMethods: `${DOMAIN}/api/admin/payment-method`,
	getOrder: `${DOMAIN}/api/get-order`,
	orderSupport: `${DOMAIN}/api/supportemail/order`,
	getAdminUsers: `${DOMAIN}/api/admin`,
	addAdminUser: `${DOMAIN}/api/admin`,
	getUserAdmin: `${DOMAIN}/api/admin/%userId%`,
	updatePassword: `${DOMAIN}/api/admin/change-password`,
	getAdminList: `${DOMAIN}/api/admin/list/assign-to-appeal`,
	getAppealTask: `${DOMAIN}/api/admin/appealTask`,
	getFileUrl: `${DOMAIN}/api/chat/file/download/%orderNumber%/%fileName%`,
	getMerchant: `${DOMAIN}/api/admin/merchant/%merchantId%`,
	getReviews: `${DOMAIN}/api/admin/merchant/%merchantId%/reviews`,
	performAdminMerchantAction: `${DOMAIN}/api/admin/merchant/%merchantId%/%action%`,
	performAdminClientAction: `${DOMAIN}/api/admin/client/%clientId%/%action%`,
	myOffers: `${DOMAIN}/api/admin/offers/%type%/%merchantId%`,
	livePirce: `${DOMAIN}/api/exchange-rates`,
	getOtp: `${DOMAIN}/api/country/otp/generate?countryCode=%code%`,
	validateOtp: `${DOMAIN}/api/country/otp/validate?countryCode=%code%&otp=%otpNumber%`,
	getOrderOtp: `${DOMAIN}/api/order/otp/generate?orderNumber=%orderNumber%`,
	performAdminUserAction: `${DOMAIN}/api/admin/%userId%/%action%`,
	changeAdminPassword: `${DOMAIN}/api/admin/change-password/%userId%`,
	getAccountGroupOTP: `${DOMAIN}/api/accountgroup/otp/generate`,
	validateAccountGroupOtp: `${DOMAIN}/api/accountgroup/otp/validate?otp=%otpNumber%`,
	handleAppeal: `${DOMAIN}/api/admin/canHandleAppeal/%canHandleAppeal%`,
	downloadFilePaymentProof: `${DOMAIN}/api/download/paymentproof/%orderNumber%/%fileName%`,
	getClient: `${DOMAIN}/api/admin/clients/%clientId%`,
	annoncement: `${DOMAIN}/api/admin/announcements`,
	getAnnoncement: `${DOMAIN}/api/admin/announcements?enabled=true`,
	currency: `${DOMAIN}/api/admin/currency`,
	getCurrency: `${DOMAIN}/api/admin/currency/%id%`,
	getCurrencyOtp: `${DOMAIN}/api/currency/otp/generate?currencyCode=%code%`,
	validateCurrencyOtp: `${DOMAIN}/api/currency/otp/validate?otp=%otpNumber%&currencyCode=%code%`,
	walletTransactions: `${DOMAIN}/api/admin/wallet-transactions`,
	accounts: `${DOMAIN}/api/admin/wallet-transactions/accounts`,
	operationReport: `${DOMAIN}/api/admin/wallet/transaction/%operation%/%transactionNumber%`,
	reassignRequest: `${DOMAIN}/api/admin/request/%taskId%/assign/%adminId%`,
	getAdminUser: `${DOMAIN}/api/admin/request/admins`,
	sendRequestAction: `${DOMAIN}/api/admin/wallet-transaction/action`,
	sendEmail: `${DOMAIN}/api/supportemail/transaction-info`,
	withdrawRequest: `${DOMAIN}/api/admin/requests`,
	adminList: `${DOMAIN}/api/admin/list`,
	getOtpTransaction: `${DOMAIN}/api/wallet-transaction/otp/generate?transactionNumber=%transactionNumber%`,
	verifyTransactionOtp: `${DOMAIN}/api/wallet-transaction/otp/validate?transactionNumber=%transactionNumber%&otp=%otp% `,
	handleRequest: `${DOMAIN}/api/admin/canHandleRequest/%canHandleRequest%`,
	getGeneralConfig: `${DOMAIN}/api/configuration/public`,
	walletTransaction: `${DOMAIN}/api/admin/wallet-transactions`,
	merchnatsDashBoard: `${DOMAIN}/api/admin/stats/dashboards`,
	systemUsers: `${DOMAIN}/api/admin/stats/usersbyterm`,
	getWalletTransactionOTP: `${DOMAIN}/api/admin/wallet-transaction/otp/generate`,
	validateWalletTransactionOTP: `${DOMAIN}/api/admin/wallet-transaction/otp/validate?otp=%otp%`,
	getTransactionImage: `${DOMAIN}/api/admin/wallet-transactions/download/payment-proof/%transactionNumber%/%fileName%`,
	getRecaptchaConfig: `${DOMAIN}/api/recaptcha-config`,
	changeLanguage: `${DOMAIN}/api/users/language`,
	totalBalancesStats: `${DOMAIN}/api/admin/stats/total-balances`,
	generateReport: `${DOMAIN}/api/admin/wallet-transactions/report`,
	getHolidayList: `${DOMAIN}/api/admin/holidays`,
	createHoliday: `${DOMAIN}/api/admin/holidays`,
	getHoliday: `${DOMAIN}/api/admin/holidays/%id%`,
};
