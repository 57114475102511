import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, DatePicker, Spin, Switch, Input, Select, Card } from 'antd';
import axios from 'axios';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const HolidayForm = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const id = queryParams.get('id');
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [submitDisabled, setIsSubmitDisabled] = useState(true);
	const [isError, setIsError] = useState(false);
	const [holiday, setHoliday] = useState<any>();
	const type = useWatch('type', form);
	const name = useWatch('name', form);
	const dateRange = useWatch('dateRange', form);
	const weekDays = useWatch('weekDays', form);
	const recurring = useWatch('recurring', form);
	const active = useWatch('active', form);
	const startDateVal = useWatch('startDate', form);

	const startDate = useMemo(() => {
		if (type === 'W' && recurring) {
			return dayjs(startDateVal).format('YYYY-MM-DD');
		} else if (dateRange && dateRange[0]) {
			return dayjs(dateRange[0]).format('YYYY-MM-DD');
		} else {
			return undefined;
		}
	}, [startDateVal, type, dateRange, recurring]);

	const submitRecord = () => {
		setLoading(true);
		const data = {
			name,
			type,
			startDate,
			endDate: dateRange ? dayjs(dateRange[1]).format('YYYY-MM-DD') : undefined,
			weekDays,
			recurring: recurring ? recurring : false,
			active: active ? active : false,
		};
		axios(`${API_ENDPOINTS.createHoliday}${id ? `/${id}` : ''}`, {
			method: id ? 'PUT' : 'POST',
			data: { ...data },
		})
			.then((_) => navigate('/holidays'))
			.catch((err) => console.error(err))
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		if (id) {
			axios.get(API_ENDPOINTS.getHoliday.replace('%id%', id)).then(({ data }) => {
				setHoliday(data);
				form.setFieldsValue({
					...data,
					startDate: data.type === 'W' ? dayjs(data.startDate) : null,
					dateRange: data.endDate ? [dayjs(data.startDate), dayjs(data.endDate)] : [null, null],
				});
			});
		}
	}, []);

	useEffect(() => {
		if (
			name === holiday?.name &&
			type === holiday?.type &&
			active === holiday?.active &&
			recurring === holiday?.recurring &&
			((areEqual(holiday?.weekDays, weekDays) && holiday?.startDate === startDate) ||
				(dateRange &&
					dateRange[0].format('YYYY-MM-DD') === holiday?.startDate &&
					dateRange[1].format('YYYY-MM-DD') === holiday?.endDate))
		)
			return setIsSubmitDisabled(true);
		return setIsSubmitDisabled(false);
	}, [type, name, dateRange, weekDays, active, recurring, holiday, startDateVal]);

	useEffect(() => {
		if (type === 'W') {
			form.setFieldValue('recurring', true);
		}
	}, [type]);
	return (
		<div>
			<Header title={t<string>('Holiday')} description={id ? t<string>('Edit holiday') : t<string>('Add holiday')} />
			<Card className=' max-w-[600px] mx-auto my-20 p-10'>
				<Spin spinning={loading}>
					<Form
						layout='vertical'
						form={form}
						onFinish={submitRecord}
						onFieldsChange={(_, allFields) => {
							const anyErrors = allFields.some((field: any) => field?.errors?.length > 0);
							if (anyErrors) {
								setIsError(anyErrors);
							} else {
								setIsError(false);
							}
						}}
					>
						<div className='flex flex-col items-center justify-center w-full'>
							<div className='w-full flex justify-center'>
								<Form.Item
									label={t<string>('name')}
									name='name'
									className='w-[300px]'
									rules={[{ required: true, message: t<string>('requiredField') }]}
									validateTrigger={['onChange']}
								>
									<Input />
								</Form.Item>
							</div>
							<div className='w-full flex justify-center'>
								<Form.Item
									label={t('type')}
									name='type'
									className='w-[300px]'
									rules={[{ required: true, message: t<string>('requiredField') }]}
									validateTrigger={['onChange']}
								>
									<Select
										placeholder={t('type')}
										options={[
											{
												label: t<string>('weekdays'),
												value: 'W',
											},
											{
												label: t<string>('dateRange'),
												value: 'D',
											},
										]}
									/>
								</Form.Item>
							</div>
							<div className='w-full flex justify-center'>
								{type === 'D' && (
									<Form.Item
										label={t('dateRange')}
										name='dateRange'
										className='w-[300px]'
										rules={[{ required: true, message: t<string>('requiredField') }]}
										validateTrigger={['onChange']}
									>
										<RangePicker />
									</Form.Item>
								)}
								{type === 'W' && (
									<Form.Item
										label={t('weekdays')}
										name='weekDays'
										className='w-[300px]'
										rules={[{ required: true, message: t<string>('requiredField') }]}
										validateTrigger={['onChange']}
									>
										<Select
											placeholder={t('weekdays')}
											options={[
												{
													label: t('MONDAY'),
													value: 'MONDAY',
												},
												{
													label: t('TUESDAY'),
													value: 'TUESDAY',
												},
												{
													label: t('WEDNESDAY'),
													value: 'WEDNESDAY',
												},
												{
													label: t('THURSDAY'),
													value: 'THURSDAY',
												},
												{
													label: t('FRIDAY'),
													value: 'FRIDAY',
												},
												{
													label: t('SATURDAY'),
													value: 'SATURDAY',
												},
												{
													label: t('SUNDAY'),
													value: 'SUNDAY',
												},
											]}
											className='w-full'
											mode='multiple'
										/>
									</Form.Item>
								)}
							</div>
							{type === 'W' && recurring && (
								<div className='w-full flex justify-center'>
									<Form.Item
										label={t<string>('startDate')}
										name='startDate'
										className='w-[300px]'
										rules={[{ required: true, message: t<string>('requiredField') }]}
										validateTrigger={['onChange']}
									>
										<DatePicker className='w-full' />
									</Form.Item>
								</div>
							)}
							<div className='w-[300px] flex justify-between'>
								<Form.Item label={t<string>('recurring')} name='recurring' valuePropName='checked'>
									<Switch
										checkedChildren={t<string>('yes')}
										unCheckedChildren={t<string>('no')}
										disabled={type === 'W'}
									/>
								</Form.Item>
								<Form.Item label={t<string>('active')} name='active' valuePropName='checked'>
									<Switch checkedChildren={t<string>('yes')} unCheckedChildren={t<string>('no')} />
								</Form.Item>
							</div>
						</div>
					</Form>
					<div className='flex justify-center'>
						<Button
							onClick={submitRecord}
							className='px-10 bg-[#01A2FF] font-bold'
							style={{ boxShadow: 'none' }}
							type='primary'
							disabled={
								submitDisabled ||
								isError ||
								!name ||
								!type ||
								!(dateRange?.length || (weekDays?.length && startDateVal))
							}
						>
							{t<string>('submit')}
						</Button>
					</div>
				</Spin>
			</Card>
		</div>
	);
};

export default HolidayForm;

const areEqual = (arr1: [] | undefined, arr2: [] | undefined) =>
	arr1?.length === arr2?.length && arr1?.sort().toString() === arr2?.sort().toString();
