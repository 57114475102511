import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { Order, OrderType } from '../../models/Orders';
import {
	Button,
	Dropdown,
	Form,
	Input,
	MenuProps,
	Select,
	Table,
	DatePicker,
	ConfigProvider,
	Grid,
	Collapse,
} from 'antd';

import dayjs, { Dayjs } from 'dayjs';
import { getOrderColumns } from '../../helpers/ordersTableColumns';
import OrderHeader from '../../components/Orders/OrdersHeader';
import axios from 'axios';
import { getUserInfo } from '../../helpers/localStorageHandler';
import { Row, Col } from 'antd';
import './Orders.scss';
import { DownOutlined, ReloadOutlined, SearchOutlined, SyncOutlined } from '@ant-design/icons';
import { useSearchParams, useLocation, useNavigation, useNavigate } from 'react-router-dom';
import { Country } from '../../models/Country';
import { PaymentMethod } from '../../models/PaymentMethod';
import { useCountriesContext } from '../../store/CountriesContext';
import { themeConfig } from '../WalletTransactions/config';
import { StyledPagination } from '../../components/table.style';
import { useConfigurationContext } from '../../store/configurationContext';
import { NumberOfRowsControl } from '../WalletTransactions/components/WalletFilters';
import Header from '../../components/Header/Header';

const DATE_FORMAT = 'YYYY-MM-DD';

function Orders() {
	const { countriesState } = useCountriesContext();
	const navigate = useNavigate();
	const { lg } = Grid.useBreakpoint();
	const [searchParams, setSearchParam] = useSearchParams();
	const [pagesize, setPageSize] = useState('10');
	const [orderStatusFilter, setOrderStatusFilter] = useState<any>(searchParams.get('orderStatusFilter'));
	const [startDateFilter, setStartDateFilter] = useState<any>(searchParams.get('startDateFilter'));
	const [endDateFilter, setEndDateFilter] = useState<any>(searchParams.get('endDateFilter'));
	const { t } = useTranslation();
	const [orders, setOrders] = useState([]);
	const [ordersLoading, setOrdersLoading] = useState(false);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [sortBy, setSortBy] = useState<string>('');
	const [order, setOrder] = useState<Order | null>(null);
	const [totalElements, setTotalElements] = useState(0);
	const [page, setPage] = useState(0);
	const [offerTypeFilter, setOfferTypeFilter] = useState(searchParams.get('offerTypeFilter'));
	const [searchValue, setSearchValue] = useState(searchParams.get('searchValue') || '');
	const [countries, setCountries] = useState<Country[]>([]);
	const [countriesLoading, setCountriesLoading] = useState<boolean>(false);
	const [countryFilter, setCountryFilter] = useState<any>(searchParams.get('country'));
	const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
	const [paymentMethodsLoading, setPaymentMethodsLoading] = useState<boolean>(false);
	const [paymentMethodFilter, setPaymentMethodFilter] = useState<any>(
		Number(searchParams.get('payment-method')) ? Number(searchParams.get('payment-method')) : null,
	);
	const [activeKey, setActiveKey] = useState<string[] | string>(lg ? ['1'] : []);
	const [isComplained, setIsComplained] = useState();
	const { configurationState } = useConfigurationContext();
	const location = useLocation();
	const today = new Date();
	const lastWeek = new Date(today);
	lastWeek.setDate(today.getDate() - 7);

	const isDev = window.location.hostname.includes('devb');

	const { RangePicker } = DatePicker;

	const token = getUserInfo()?.token;
	useEffect(() => {
		setCountriesLoading(true);
		setPaymentMethodsLoading(true);
		axios
			.get(`${API_ENDPOINTS.countries}`, {
				headers: { Authorization: `Bearer ${token}` },
			})
			.then((res: any) => {
				let paymentMethods: any[] = [{ methodName: t<string>('all'), methodId: '' }];
				const countriesData = res.data?.map((el: any) => {
					paymentMethods = [...paymentMethods, ...el.paymentMethods];
					return {
						name: el.countryName,
						code: el.countryCode,
					};
				});
				setPaymentMethods(paymentMethods);
				setCountries([{ name: t<string>('all'), code: '' }, ...countriesData]);
				setCountriesLoading(false);
				setPaymentMethodsLoading(false);
			})
			.catch((err) => {
				setCountriesLoading(false);
				console.error(err);
			});
	}, [t]);

	const searchParameter = useMemo(() => {
		let paymentMethod = paymentMethodFilter
			? paymentMethods.find((item) => item.methodId === paymentMethodFilter)?.methodName
			: '';
		return [
			['type%3A', offerTypeFilter],
			['status%3A', orderStatusFilter],
			['paymentMethod%23methodName%3B', paymentMethod],
			['paymentMethod%23country%23countryCode%3B', countryFilter],
			['orderInfo%23isComplained%3A', isComplained],
		]
			.filter(([key, value]) => {
				if (key === 'createdAt') return Boolean(value?.length);
				return Boolean(value);
			})
			.map(([key, value]) => `${key}${value}`)
			.join('%2C');
	}, [offerTypeFilter, orderStatusFilter, countryFilter, paymentMethodFilter, isComplained]);
	useEffect(() => {
		setOrdersLoading(true);
		let startDate =
			startDateFilter && !endDateFilter
				? `&search=createdAt%3E${dayjs(startDateFilter).toISOString().replace(/T/, ' ').replace(/\..+/, '')}`
				: '';
		let endDate =
			!startDateFilter && endDateFilter
				? `&search=createdAt%3C${dayjs(endDateFilter).toISOString().replace(/T/, ' ').replace(/\..+/, '')}`
				: '';
		let dateRange =
			startDateFilter && endDateFilter
				? `&search=createdAt%3E${dayjs(startDateFilter)
						.toISOString()
						.replace(/T/, ' ')
						.replace(/\..+/, '')}%2CcreatedAt%3C${dayjs(endDateFilter)
						.toISOString()
						.replace(/T/, ' ')
						.replace(/\..+/, '')}`
				: '';
		let name = '';
		let sort = '&sort=createdAt-desc';
		if (sortBy.startsWith('client')) {
			sort = `&sort=client%23user%23fullName-${sortBy.split('-')[1]}`;
		} else if (sortBy.startsWith('merchants')) {
			sort = `&sort=merchant%23user%23fullName-${sortBy.split('-')[1]}`;
		} else if (sortBy) {
			sort = `&sort=${sortBy}`;
		}
		if (searchParams.get('clientId')) {
			name = `&search=client%23clientId%3A${searchParams.get('clientId')}`;
		} else if (searchParams.get('merchantId')) {
			name = `&search=merchant%23merchantId%3A${searchParams.get('merchantId')}`;
		}

		let search = searchTerm ? `&wildSearch=${searchTerm}` : '';

		let filter = searchParameter ? `&search=${searchParameter}` : '';
		axios
			.get(
				`${API_ENDPOINTS.orders}?page=${page}&pageSize=${pagesize}${dateRange}${startDate}${endDate}${sort}${search}${name}${filter}`,
				{ headers: { Authorization: `Bearer ${token}` } },
			)
			.then((result) => {
				setOrders(result.data.data);
				setTotalElements(result.data.totalElements);
				setOrdersLoading(false);
			})
			.catch(() => {
				setOrdersLoading(false);
			});
	}, [pagesize, searchValue, sortBy, page, location, searchParameter, startDateFilter, endDateFilter, isComplained]);

	const resetFilters = () => {
		setOrderStatusFilter('');
		setOfferTypeFilter('');
		setStartDateFilter('');
		setEndDateFilter('');
		setSearchTerm('');
		setSortBy('');
		setStartDateFilter('');
		setEndDateFilter('');
		setSearchParam('');
	};

	useEffect(() => {
		const params = new URLSearchParams(searchParams);
		orderStatusFilter ? params.set('orderStatusFilter', orderStatusFilter) : params.delete('orderStatusFilter');
		paymentMethodFilter ? params.set('payment-method', paymentMethodFilter) : params.delete('payment-method');

		countryFilter ? params.set('country', countryFilter) : params.delete('country');

		startDateFilter ? params.set('startDateFilter', startDateFilter) : params.delete('startDateFilter');

		endDateFilter ? params.set('endDateFilter', endDateFilter) : params.delete('endDateFilter');

		searchValue ? params.set('searchValue', searchValue) : params.delete('search');

		offerTypeFilter ? params.set('offerTypeFilter', offerTypeFilter) : params.delete('offerTypeFilter');
		if (params.size === 0 || (params.size === 1 && (params.get('merchantId') || params.get('clientId')))) return;
		setSearchParam(params);
	}, [
		orderStatusFilter,
		startDateFilter,
		searchValue,
		endDateFilter,
		searchValue,
		offerTypeFilter,
		countryFilter,
		paymentMethodFilter,
		countries,
	]);
	const getColor = (type: string) => {
		if (type === OrderType.buy) {
			return 'green';
		} else if (type === OrderType.sell) {
			return 'red';
		} else {
			return 'black';
		}
	};

	useEffect(() => {
		const delaySearch = setTimeout(() => {
			if (searchValue !== searchTerm) setSearchValue(searchTerm);
		}, 1000);
		return () => clearTimeout(delaySearch);
	}, [searchTerm]);

	useEffect(() => {
		setPage(0);
	}, [orderStatusFilter, endDateFilter, startDateFilter, searchValue, offerTypeFilter]);

	useEffect(() => {
		const country: any = countriesState.countries.find((item) => item.countryCode === countryFilter);
		const methods = country?.paymentMethods
			? country.paymentMethods
			: [{ methodName: t<string>('all'), methodId: '' }, ...countriesState.paymentMethods];
		setPaymentMethods(methods);
	}, [countryFilter, countries, countriesState.paymentMethods, t]);

	useEffect(() => {
		// Open panel by default if large screen (`lg`) is active, otherwise close
		if (lg) {
			setActiveKey(['1']);
		} else {
			setActiveKey([]);
		}
	}, [lg]);
	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value || '';
	const secondary_color = configurationState.find((item) => item.configKey === 'secondary_color')?.value || '';
	return (
		<div>
			<Header title={t<string>('orderHeading')} description={t<string>('orderSubtitle')} />
			<div className='min-h-[84vh] mx-auto xss:px-[20px] sm:px-[75px] py-5 bg-white'>
				<Collapse activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
					<Collapse.Panel header={t('Filter Options')} key='1'>
						<div className='border-[#000d1d]/10 rounded-md shadow-md py-3 pb-0 px-4 border border-solid border-gray-200'>
							<Form layout='vertical'>
								<Row gutter={16}>
									<Col xs={24} md={12} lg={8}>
										<Form.Item label={t<string>('type')}>
											<Select
												placeholder={t('all')}
												value={offerTypeFilter}
												onChange={(e: string) => setOfferTypeFilter(e)}
											>
												{[
													{
														TypeName: t('all'),
														value: '',
													},
													{
														TypeName: isDev ? t<string>('buy') : t<string>('deposit'),
														value: 'BUY',
													},
													{
														TypeName: isDev ? t<string>('Sell') : t<string>('withdrawal'),
														value: 'SELL',
													},
												].map((item, index) => (
													<Select.Option key={index} value={item.value} style={{ color: getColor(item.TypeName) }}>
														{item.TypeName}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>
									<Col xs={24} md={12} lg={8}>
										<Form.Item label={t<string>('countries')}>
											<Select
												placeholder={t('all')}
												value={countryFilter}
												loading={countriesLoading}
												onChange={(e: string) => {
													setCountryFilter(e);
												}}
												showSearch
												filterOption={(inputValue, option: any) =>
													option?.children ? option.children[1].toLowerCase().includes(inputValue.toLowerCase()) : false
												}
											>
												{countries.map((item, index) => (
													<Select.Option key={index} value={item.code}>
														{item.name !== t('all') && (
															<img
																src={`https://flagsapi.com/${item.code}/shiny/64.png`}
																alt='country'
																className='country-flag'
															/>
														)}
														{item.name}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>
									<Col xs={24} md={12} lg={8}>
										<Form.Item label={t<string>('paymentMethods')}>
											<Select
												placeholder={t('all')}
												value={paymentMethodFilter}
												loading={paymentMethodsLoading}
												onChange={(e: string) => {
													setPaymentMethodFilter(e);
												}}
												showSearch
												filterOption={(inputValue, option: any) =>
													option?.children ? option.children.toLowerCase().includes(inputValue.toLowerCase()) : false
												}
											>
												{paymentMethods.map((item, index) => (
													<Select.Option key={index} value={item.methodId}>
														{item.methodName}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>
									<Col xs={24} md={24} lg={8}>
										<Form.Item label={t<string>('dateRange')}>
											<RangePicker
												className='w-full'
												onChange={(values) => {
													if (values && values.length === 2) {
														const startDate = dayjs(values[0]).format('YYYY-MM-DD 00:00:00');
														const endDate = dayjs(values[1]).format('YYYY-MM-DD 23:59:00');
														setStartDateFilter(startDate);
														setEndDateFilter(endDate);
													} else {
														setStartDateFilter('');
														setEndDateFilter('');
													}
												}}
											/>
										</Form.Item>
									</Col>
									<Col xs={24} md={12} lg={8}>
										<Form.Item label={t<string>('status')}>
											<Select
												placeholder={t('all')}
												value={orderStatusFilter}
												onChange={(e: string) => {
													setIsComplained(undefined);
													setOrderStatusFilter(e);
												}}
											>
												{[
													{ id: 0, status: t('all'), key: '' },
													{ id: 1, status: t('placed'), key: 'PLACED' },
													{ id: 2, status: t('opened'), key: 'OPENED' },
													{ id: 3, status: t('transferred'), key: 'TRANSFERRED' },
													{ id: 4, status: t('completed'), key: 'COMPLETED' },
													{ id: 5, status: t('cancelled'), key: 'CANCELLED' },
													{ id: 6, status: t('expired'), key: 'EXPIRED' },
													{ id: 7, status: t('appealed'), key: 'APPEAL' },
													{ id: 8, status: t('rejected'), key: 'REJECTED' },
												].map((item, index) => (
													<Select.Option key={index} value={item.key}>
														{item.status}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>
									{orderStatusFilter === 'EXPIRED' ? (
										<Col xs={24} md={12} lg={8}>
											<Form.Item label={t<string>('complained')}>
												<Select placeholder={t('all')} value={isComplained} onChange={setIsComplained}>
													{[
														{ id: 0, status: t('all'), key: '' },
														{ id: 1, status: t('yes'), key: 'true' },
														{ id: 2, status: t('no'), key: 'false' },
													].map((item, index) => (
														<Select.Option key={index} value={item.key}>
															{item.status}
														</Select.Option>
													))}
												</Select>
											</Form.Item>
										</Col>
									) : null}
								</Row>
							</Form>
						</div>
					</Collapse.Panel>
				</Collapse>
				<Row gutter={[16, 16]} align='middle' className='my-2'>
					<Col xs={12} lg={12}>
						<NumberOfRowsControl numberOfEntries={pagesize} setNumberOfEntries={setPageSize} />
					</Col>
					<Col xs={12} lg={12} className='flex gap-2 items-center justify-end'>
						<Button
							onClick={resetFilters}
							className='bg-[#EAECEF] text-[#1E2329] border-none shadow-none'
							htmlType='button'
							type='primary'
							icon={<SyncOutlined />}
						></Button>
						<Input
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
							className='xss:w-36  md:w-64 border-none h-9 bg-[#F5F5F5] search-input '
							placeholder={t<string>('searchOrder')}
							prefix={<SearchOutlined />}
						/>
					</Col>
				</Row>
				<ConfigProvider theme={themeConfig}>
					<StyledPagination bgColor={themeColor} color={secondary_color}>
						<Table
							columns={getOrderColumns(t, sortBy, setSortBy, !lg)}
							dataSource={orders}
							loading={ordersLoading}
							pagination={{
								pageSize: Number(pagesize),
								total: totalElements,
								showSizeChanger: false,
								onChange: (pageIndex) => setPage(pageIndex - 1),
								current: page + 1,
							}}
							onRow={(record: Order) => ({
								onClick: (event: any) => {
									navigate(`/order-life-cycle/?id=${record.orderNumber}`);
								},
							})}
							tableLayout='fixed'
						/>
					</StyledPagination>
				</ConfigProvider>
			</div>
		</div>
	);
}

export default Orders;
