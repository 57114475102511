import React, { useEffect, useState } from 'react';
import './Navbar.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Popover, Switch } from 'antd';
import { UserOutlined, DownOutlined } from '@ant-design/icons';
import { getUserLanguageInCookie } from '../../helpers/cookiesHandler';
import Languages from '../Languages/Languages';
import { useMainContext } from '../../store/MainContext';
import { deleteUserInfo, getUserInfo, setUserInfo } from '../../helpers/localStorageHandler';
import axios from 'axios';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { getInitials } from '../../helpers/getStringInitials';
import Notification from '../Notification/Notification';
import { useConfigurationContext } from '../../store/configurationContext';
import { resetMainState } from '../../assets/config/initial-main-state';

export const authChannel = new BroadcastChannel('auth');
const Navbar = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { mainState, setMainState } = useMainContext();
	const isLoggedIn = React.useMemo(() => mainState.isLoggedIn, [mainState]);
	const [canHandleAppeal, setHandleAppeal] = React.useState(mainState.canHandleAppeal);
	const [canHandleRequest, setCanHandleRequest] = useState(mainState.canHandleRequests);
	const userInfo = React.useMemo(() => getUserInfo(), [mainState.email]);

	const { configurationState } = useConfigurationContext();
	const userMenuDetails = React.useMemo(
		() => ({
			email: mainState?.email ?? '',
			userName: mainState?.userName ?? '',
			countryCode: mainState?.countryCode ?? '',
		}),
		[mainState.email, mainState.userName, mainState.isLoggedIn],
	);
	const hanelLogOut = React.useCallback(() => {
		const token = userInfo?.token;
		if (token) {
			axios.post(API_ENDPOINTS.logout, { token }, { headers: { Authorization: `Bearer ${token}` } }).then((_) => {
				authChannel.postMessage({ type: 'LOGOUT' });
				deleteUserInfo();
				setMainState(resetMainState);
				navigate(`/sign-in`);
			});
		}
	}, [getUserInfo, navigate]);

	useEffect(() => {
		setHandleAppeal(mainState.canHandleAppeal);
		setCanHandleRequest(mainState.canHandleRequests);
	}, [mainState.canHandleAppeal, mainState.canHandleRequests]);

	const handleAppeal = (value: boolean) => {
		setHandleAppeal(value);
		axios(API_ENDPOINTS.handleAppeal.replace('%canHandleAppeal%', `${value}`), {
			method: 'PUT',
			headers: { Authorization: `Bearer ${getUserInfo()?.token}` },
		}).then(({ data }) => {
			setHandleAppeal(data.canHandleAppeal);
			setMainState({
				...mainState,
				canHandleAppeal: data.canHandleAppeal,
			});
			let loggedInUser = getUserInfo() ?? {};
			loggedInUser = { ...loggedInUser, canHandleAppeal: data.canHandleAppeal };
			setUserInfo(loggedInUser);
		});
	};
	const handleRequest = (value: boolean) => {
		setCanHandleRequest(value);
		axios(API_ENDPOINTS.handleRequest.replace('%canHandleRequest%', `${value}`), {
			method: 'PUT',
			headers: { Authorization: `Bearer ${getUserInfo()?.token}` },
		}).then(({ data }) => {
			setCanHandleRequest(data.canHandleRequest);
			setMainState({
				...mainState,
				canHandleRequests: data.canHandleRequest,
			});
			let loggedInUser = getUserInfo() ?? {};
			loggedInUser = { ...loggedInUser, canHandleRequests: data.canHandleRequest };
			setUserInfo(loggedInUser);
		});
	};
	const companyLogo = configurationState.find((item) => item.configKey === 'company_logo')?.value || '';
	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value || '';
	const secondary_color = configurationState.find((item) => item.configKey === 'secondary_color')?.value || '';
	return (
		<div style={{ backgroundColor: themeColor, color: secondary_color }}>
			<div className='max-w-[1440px] mx-auto px-[15px] md:px-[75px] py-2 flex flex-col md:flex-row items-center justify-between'>
				{/* Logo and Menu Items */}
				{companyLogo ? (
					<div className='flex flex-col md:flex-row items-center justify-between w-full md:w-auto h-[45px]'>
						<Link to='/' className='flex'>
							<img src={companyLogo} alt='logo' />
						</Link>
					</div>
				) : (
					<div className='flex flex-col md:flex-row items-center justify-between w-full md:w-auto h-[45px]' />
				)}
				<div className='flex items-center mt-2 md:mt-0'>
					<Languages />
					{isLoggedIn && <Notification />}
					{isLoggedIn && (
						<Popover
							placement={getUserLanguageInCookie() === 'ar' ? 'topLeft' : 'topRight'}
							trigger='click'
							content={
								<div className='navbar-container flex flex-col'>
									<div className='user-menu-details'>
										<span className='user-menu-details-initials'>{getInitials(userMenuDetails.userName)}</span>
										<div className='user-menu-details-content'>
											<div className='flex items-center user-menu-name-container'>
												<p id='navbar-user-name' className='name'>
													{userMenuDetails.userName}
												</p>
												{
													<img
														id='navbar-user-image'
														src={`https://flagsapi.com/${userMenuDetails.countryCode}/shiny/64.png`}
														alt='country'
														className='country-flag'
													/>
												}
											</div>
											<p className='email'>{userMenuDetails.email}</p>
										</div>
									</div>
									<div className='w-[110%] h-[1px] bg-[#eaecef] mt-2 ml-[-10px]'></div>
									<div className='actionBtns'>
										<div className='flex align-middle'>
											<p className='my-1 mr-1'>{t<string>('handleAppeal')}</p>
											<Switch
												onChange={handleAppeal}
												size='small'
												className='m-2'
												checked={canHandleAppeal}
												style={{ backgroundColor: canHandleAppeal ? '#0077ff' : 'grey' }}
											/>
										</div>
										<div className='flex align-middle'>
											<p className='my-1 mr-1'>{t<string>('handleRequest')}</p>
											<Switch
												onChange={handleRequest}
												size='small'
												className='m-2'
												checked={canHandleRequest}
												style={{ backgroundColor: canHandleRequest ? '#0077ff' : 'grey' }}
											/>
										</div>
										<Button
											className='flex items-center text-sm justify-between bg-none border-none p-0 w-24 shadow-none'
											onClick={() => navigate('/update-password')}
										>
											{t<string>('changePassword')}
										</Button>
										<Button
											onClick={hanelLogOut}
											className='flex items-center text-sm justify-between bg-none border-none p-0 w-24 shadow-none'
										>
											{t<string>('logout')}
										</Button>
									</div>
								</div>
							}
						>
							<Button className='p-0 m-0 border-none uppercase text-white text-xs font-medium flex items-center hover:text-white bg-transparent'>
								<UserOutlined style={{ fontSize: 16, color: secondary_color }} />
								<DownOutlined className='w-2 ' style={{ fontSize: 8, color: secondary_color }} />
							</Button>
						</Popover>
					)}
				</div>
			</div>
		</div>
	);
};

export default Navbar;
