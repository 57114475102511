import { useMemo, useState } from 'react';
import { useCountriesContext } from '../../../../../../store/CountriesContext';
import { useTranslation } from 'react-i18next';
import { OrderType } from '../../../../types/OrderType';
import { Skeleton, notification } from 'antd';
import { CopyFilled, DownloadOutlined } from '@ant-design/icons';
import '../BodyDetails.scss';
import axios from 'axios';
import { API_ENDPOINTS } from '../../../../../../assets/api/endpoints';
import { getUserInfo } from '../../../../../../helpers/localStorageHandler';
import { PhotoPreview } from './PhotoPreview';

type PaymentMethodDetailsProps = {
	paymentMehodId: number;
	accountNNumber: string;
	amount: string;
	quantity: string;
	orderType: OrderType;
	paymentProof: boolean;
	paymentProofFileId: string;
	paymentProofFileLabel: string;
	orderNumber: string;
	accountGroup: string;
};

const decodeBase64 = (base64String: string) => {
	const binaryString = window.atob(base64String);
	const bytes = new Uint8Array(binaryString.length);

	for (let i = 0; i < binaryString.length; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}

	return bytes;
};
const CHAR_SIZE = 5;
function PaymentMethodDetails({
	paymentMehodId,
	accountNNumber,
	amount,
	quantity,
	orderType,
	paymentProof,
	paymentProofFileId,
	paymentProofFileLabel,
	orderNumber,
	accountGroup,
}: PaymentMethodDetailsProps) {
	const { t } = useTranslation();
	const { countriesState } = useCountriesContext();
	const token = getUserInfo()?.token;
	const [showPreviewPhoto, setShowPreviewPhoto] = useState(false);
	const [photo, setPhoto] = useState('');
	const [pdfUrl, setPdfUrl] = useState('');
	const paymentMethod = useMemo(
		() => countriesState.allPaymentMethods.find((method) => method.methodId === paymentMehodId),
		[paymentMehodId, countriesState.paymentMethods],
	);

	const copyMyText = (value: string) => {
		let textToCopy = document.getElementById(value) as HTMLInputElement;
		if (textToCopy) {
			navigator.clipboard.writeText(textToCopy.innerHTML);
			notification['success']({ message: '', description: t<string>('copiedSuccessfully') });
		}
	};

	let tremName = paymentProofFileId.split('.');
	let FileName =
		tremName[0].length > CHAR_SIZE
			? tremName[0].slice(0, CHAR_SIZE) + '..' + tremName[tremName.length - 1]
			: paymentProofFileId;

	const downloadFile = () => {
		axios
			.get(
				API_ENDPOINTS.downloadFilePaymentProof
					.replace('%orderNumber%', orderNumber)
					.replace('%fileName%', paymentProofFileLabel),
				{
					responseType: 'arraybuffer',
					headers: { Authorization: `Bearer ${token}` },
				},
			)
			.then((response) => {
				const base64 = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
				if (paymentProofFileLabel.includes('pdf')) {
					fetchPdf(base64);
				} else {
					setPhoto(base64);
				}
			})
			.catch((err) => console.error(err));
	};

	const fetchPdf = (base64Pdf: string) => {
		// Decode the base64 string to Uint8Array
		const uint8Array = decodeBase64(base64Pdf);

		// Create a blob from Uint8Array
		const blob = new Blob([uint8Array], { type: 'application/pdf' });

		// Create an object URL for the blob
		const objectURL = URL.createObjectURL(blob);

		// Set the PDF URL for the viewer
		setPdfUrl(objectURL);
	};
	return (
		<div className='order-details-wrapper'>
			<div className='details-row'>
				<h3 className='order-details-title'>{t<string>('paymentDetails')}</h3>
				{paymentProof && (
					<div
						className='uploadedFile rounded-2xl flex items-center'
						onClick={() => {
							setShowPreviewPhoto(true);
							downloadFile();
						}}
					>
						<DownloadOutlined style={{ color: '#018aff', fontSize: '18px', margin: '5px 8px' }} />
						{FileName}
					</div>
				)}
			</div>
			{amount !== 'undefined' ? (
				<>
					<div className='details-row'>
						<p>{orderType === 'BUY' ? t<string>('clientPay') : t<string>('merchantPay')}</p>
						<div className='flex'>
							<p className='value whitespace-nowrap' id='amount'>
								{amount}
							</p>
							<CopyFilled className='mx-2 cursor-pointer xss:w-[20px]' onClick={() => copyMyText('amount')} />
						</div>
					</div>
					<div className='details-row'>
						<p>{t<string>('paymentMethod')}</p>
						<p className='value'>
							<span
								style={{ color: paymentMethod?.color, background: paymentMethod?.bgColor }}
								className={`text-[14px] py-1 px-1 rounded-sm`}
							>
								{paymentMethod?.methodName}
							</span>
						</p>
					</div>
					<div className='details-row'>
						<p>{t<string>('merchantReceive')}</p>
						<div className='flex'>
							<p className='value whitespace-nowrap' id='quantity'>
								{quantity}
							</p>
							<CopyFilled className='mx-2 cursor-pointer' onClick={() => copyMyText('quantity')} />
						</div>
					</div>
					<PhotoPreview
						showModal={showPreviewPhoto}
						setShowModal={setShowPreviewPhoto}
						photo={photo}
						paymentProofFileId={paymentProofFileId}
						pdfUrl={pdfUrl}
					/>
				</>
			) : (
				<Skeleton active paragraph={{ rows: 1 }} />
			)}
		</div>
	);
}

export default PaymentMethodDetails;
