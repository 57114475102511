import {
	Button,
	Col,
	ConfigProvider,
	DatePicker,
	Form,
	Grid,
	Modal,
	Row,
	Select,
	TimeRangePickerProps,
	TreeSelect,
	Typography,
} from 'antd';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../../assets/api/endpoints';

import dayjs from 'dayjs';
import { useConfigurationContext } from '../../../store/configurationContext';
import { useWatch } from 'antd/es/form/Form';

const { RangePicker } = DatePicker;

type TList = {
	label: JSX.Element;
	value: string;
};
type ExportReportProps = {
	transactionsStatusList: TList[];
	transactionsTypeList: TList[];
	setShowOtpModal: (value: boolean) => void;
	showOtpModal: boolean;
	defaultValues: {
		states: string[];
		types: string[];
		operations: string[];
		dateRange: string[];
	};
};
const operationMap: any = {
	P2P: 'P2P',
	ADMIN: 'ADMIN_OPERATION',
	TRADING: 'TRADING_ACCOUNT',
};

export const ExportReport = ({
	transactionsStatusList,
	transactionsTypeList,
	setShowOtpModal,
	showOtpModal,
	defaultValues = {
		// Set default values here
		states: [],
		types: [],
		operations: [],
		dateRange: [],
	},
}: ExportReportProps) => {
	const { t } = useTranslation();
	const { lg } = Grid.useBreakpoint();
	const [form] = Form.useForm();
	const { configurationState } = useConfigurationContext();
	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value || '';
	const secondary_color = configurationState.find((item) => item.configKey === 'secondary_color')?.value || '';
	const [isLoading, setIsLoading] = useState(false);
	const [states, setStates] = useState([]);
	const [operations, setOperations] = useState([]);
	const [types, setTypes] = useState([]);
	const [fromDate, setFromDate] = useState<string | null>(null);
	const [toDate, setToDate] = useState<string | null>(null);

	useEffect(() => {
		const operationList: any = Object.keys(operationMap)
			.filter((key) => types.some((val: string) => val.startsWith(key)))
			.map((key) => operationMap[key]);

		setOperations(operationList);
	}, [types]);

	const onDateRangeChange = useCallback((values: TimeRangePickerProps['value']) => {
		const range = (values || [])?.map((value) => dayjs(value).toISOString());
		setFromDate(range[0] ?? null);
		setToDate(range[1] ?? null);
	}, []);

	useEffect(() => {
		// default values for states
		if (defaultValues.states.includes('')) {
			const values: any = transactionsStatusList.map((value) => value.value);
			setStates(values);
			form.setFieldValue('states', values);
		}
		if (defaultValues.states.length > 0) {
			form.setFieldValue('states', defaultValues.states);
			setStates(defaultValues.states as any);
		}

		// default values for date range
		form.setFieldValue('dateRange', [
			defaultValues.dateRange[0] ? dayjs(defaultValues.dateRange[0]) : null,
			defaultValues.dateRange[1] ? dayjs(defaultValues.dateRange[1]) : null,
		]);
		const range = (defaultValues.dateRange || [])?.map((value) => dayjs(value).toISOString());
		setFromDate(range[0] ?? null);
		setToDate(range[1] ?? null);

		// default values for types
		if (defaultValues.types.includes('')) {
			const values: any = transactionsTypeList.map((value) => value);
			const seletedType = values
				.map((item: any) => item.children && item.children.map((value: any) => value.value))
				.flat()
				.filter((value: any) => value);
			setTypes(seletedType);
			form.setFieldValue('types', values);
		}
		if (defaultValues.types.length > 0) {
			form.setFieldValue('types', defaultValues.types);
			setTypes(defaultValues.types as any);
		}

		//default values for operations
		if (defaultValues.operations.length > 0) {
			setOperations(defaultValues.operations as any);
			const result = transactionsTypeList.find((item) => item.value === defaultValues.operations[0]);
			const values = (result as any)?.children?.map((item: any) => item.value) ?? [];
			form.setFieldValue('types', values);
			setTypes(values as any);
		}
	}, [defaultValues]);

	const generateReport = () => {
		setIsLoading(true);
		axios
			.post(
				API_ENDPOINTS.generateReport,
				{
					fromDate,
					toDate,
					operations,
					types: types.filter((value) => value),
					states: states.filter((value) => value),
				},
				{
					responseType: 'blob', // Receive binary data
				},
			)
			.then((res) => {
				const blob = new Blob([res.data], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				});
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				const date =
					fromDate && toDate ? dayjs(fromDate).format('YYYY-MM-DD') + '-' + dayjs(toDate).format('YYYY-MM-DD') : '';
				link.setAttribute('download', `Transaction Report${date}.xlsx`); // Set file name
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link); // Cleanup
				window.URL.revokeObjectURL(url); // Free up memory
			})
			.catch((error) => {
				console.error('Error downloading the file', error);
			})
			.finally(() => setIsLoading(false));
	};

	useEffect(() => {
		if (!showOtpModal) {
			// Reset form fields
			form.resetFields();

			// Reset component state
			setStates([]);
			setOperations([]);
			setTypes([]);
			setFromDate('');
			setToDate('');
		}
	}, [showOtpModal]);

	return (
		<Modal onCancel={() => setShowOtpModal(false)} maskClosable={false} open={showOtpModal} footer={null}>
			<ConfigProvider
				theme={{
					inherit: true,
					components: {
						TreeSelect: {
							nodeSelectedBg: '#fff',
							colorBorder: '#fff',
							fontSize: lg ? 14 : 10,
						},
					},
				}}
			>
				<Form form={form} layout='vertical'>
					<Row gutter={[16, 0]}>
						<Col xs={24} className='w-full'>
							<Typography style={{ color: themeColor, fontWeight: 700, fontSize: '17px', marginBottom: '20px' }}>
								{t('report_filters')}
							</Typography>
						</Col>
						<Col xs={24} className='w-full'>
							<Form.Item label={t('dateRange')} name='dateRange'>
								<RangePicker onChange={(values) => onDateRangeChange(values)} className='w-full' />
							</Form.Item>
						</Col>
						<Col xs={24} className='w-full'>
							<Form.Item label={t('status')} name='states' className='min-w-64'>
								<Select
									value={states}
									placeholder={t('all')}
									onChange={(selectedValues: any) => {
										if (selectedValues.includes('')) {
											const values: any = transactionsStatusList.map((value) => value.value);
											setStates(values);
											form.setFieldValue('states', values);
										} else {
											setStates(selectedValues);
										}
									}}
									options={transactionsStatusList}
									className='w-full'
									mode='multiple'
								/>
							</Form.Item>
						</Col>
						<Col xs={24} className='w-full'>
							<Form.Item label={t('type')} name='types' className='min-w-64'>
								<TreeSelect
									className='w-full'
									onChange={(selectedValues: any) => {
										if (selectedValues.includes('')) {
											const values: any = transactionsTypeList.map((value) => value);
											const seletedType = values
												.map((item: any) => item.children && item.children.map((value: any) => value.value))
												.flat()
												.filter((value: any) => value);
											setTypes(seletedType);
											form.setFieldValue('types', values);
										} else {
											setTypes(selectedValues);
										}
										// Filter out selected values that match operation prefixes
									}}
									value={types}
									placeholder={t('all')}
									suffixIcon={null}
									treeDefaultExpandAll
									treeData={transactionsTypeList}
									treeCheckable={true} // Enables multiple selections
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Button
							className='m-auto h-10'
							style={{ backgroundColor: themeColor, color: secondary_color }}
							loading={isLoading}
							onClick={generateReport}
						>
							{t('generate_report')}
						</Button>
					</Row>
				</Form>
			</ConfigProvider>
		</Modal>
	);
};
