import { CaretDownFilled, SearchOutlined, SyncOutlined, ExportOutlined } from '@ant-design/icons';
import {
	Button,
	Col,
	Collapse,
	ConfigProvider,
	DatePicker,
	Dropdown,
	Form,
	Grid,
	Input,
	InputNumber,
	Row,
	Select,
	TimeRangePickerProps,
	TreeSelect,
} from 'antd';
import axios from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../../assets/api/endpoints';
import { getUserInfo } from '../../../helpers/localStorageHandler';
import { WalletStatus } from '../types/WalletStatus';
import { TransactionStatus } from './TransactionStatus';
import { WalletType } from '../types/WalletType';
import { TransactionType } from './TransactionType';
import { MyAccount } from '../types/MyAccount';

import dayjs from 'dayjs';
import { ExportReport } from '../modals/ExportReportModal';

const { RangePicker } = DatePicker;

export const NumberOfRowsControl = ({
	numberOfEntries,
	setNumberOfEntries,
}: {
	numberOfEntries: string;
	setNumberOfEntries: (numberOfEntries: string) => void;
}) => {
	const { t } = useTranslation();

	const numberOfEntriesOptions = useMemo(
		() => [
			{ label: '10', key: '10' },
			{ label: '20', key: '20' },
			{ label: '50', key: '50' },
			{ label: '100', key: '100' },
		],
		[],
	);

	const onClick = useCallback(
		({ key }: { key: string }) => {
			setNumberOfEntries(key);
		},
		[setNumberOfEntries],
	);

	return (
		<div className='flex items-center gap-2'>
			<span>{t('show')}</span>
			<Dropdown menu={{ items: numberOfEntriesOptions, onClick }}>
				<span className='bg-[#F5F5F5] text-[#1E2329] xss:h-8 md:h-10 xss:w-15 md:w-24  rounded h-10 w-24 flex items-center justify-between px-4 cursor-pointer'>
					<span>{numberOfEntries}</span>
					<CaretDownFilled />
				</span>
			</Dropdown>
			<span>{t('entries')}</span>
		</div>
	);
};

type WalletFiltersProps = {
	className?: string;
	numberOfEntries: string;
	setNumberOfEntries: (numberOfEntries: string) => void;
	search: string;
	setDateRange: (range: string[]) => void;
	dateRange: string[];
	setSearch: (search: string) => void;
	status: string | null;
	setStatus: (status: string | null) => void;
	type: string | null;
	setType: (type: string | null) => void;
	refresh: () => void;
	isP2P: boolean;
	setIsP2P: (isP2P: boolean) => void;
	orderNumber: string;
	setOrderNumber: (orderNumber: string | null) => void;
	setAccount: (account: MyAccount | null) => void;
	account: MyAccount | null;
	setOperation: (operation: string | null) => void;
	operation: string | null;
};

export const WalletFilters = (props: WalletFiltersProps) => {
	const { t } = useTranslation();
	const { lg } = Grid.useBreakpoint();
	const [form] = Form.useForm();
	const token = getUserInfo()?.token;
	const [isLoading, setIsLoading] = useState(false);
	const [showOtpModal, setShowOtpModal] = useState(false);
	const [transactionStatusList, setTransactionStatusList] = useState([]);
	const [transactionTypeList, setTransactionTypeList] = useState<{ [K: string]: string[] }>({});
	const [accounts, setAccounts] = useState<MyAccount[]>([]);
	const [activeKey, setActiveKey] = useState<string[] | string>(lg ? ['1'] : []);
	const transactionsStatusList = useMemo(
		() =>
			[
				{
					label: <>{t('all')}</>,
					value: '',
				},
			].concat(
				transactionStatusList.map((status: any) => ({
					label: <TransactionStatus status={status.value as WalletStatus} />,
					value: status.value,
				})),
			),
		[isLoading],
	);

	const transactionsTypeList = useMemo(
		() =>
			[
				{
					label: <>{t('all')}</>,
					value: '',
				},
			].concat(
				// @ts-ignore
				Object.keys(transactionTypeList).map((key) => ({
					title: t(key),
					value: key,
					children: transactionTypeList[key].map((type) => ({
						title: <TransactionType type={type as WalletType} />,
						value: type,
					})),
				})),
			),
		[isLoading],
	);

	const requestAccountsList = useMemo(
		() =>
			[
				{
					label: <>{t('all')}</>,
					value: '',
				},
			].concat(
				accounts.map((account: any) => ({
					label: (
						<>
							{account.accountGroup} | {account.number} | {account.tradingPlatform}
						</>
					),
					value: account.number,
				})),
			),
		[isLoading],
	);
	useEffect(() => {
		setIsLoading(true);
		Promise.all([
			axios.get(`${API_ENDPOINTS.walletTransactions}/status`, { headers: { Authorization: `Bearer ${token}` } }),
			axios.get(`${API_ENDPOINTS.walletTransactions}/types`, { headers: { Authorization: `Bearer ${token}` } }),
			axios.get(API_ENDPOINTS.accounts, { headers: { Authorization: `Bearer ${token}` } }),
		])
			.then(([statuses, types, accounts]) => [statuses?.data, types?.data, accounts.data])
			.then(([statuses, types, accounts]) => {
				setTransactionStatusList(statuses.map((status: string) => ({ label: t(status), value: status })));
				setTransactionTypeList(types);
				setAccounts(accounts);
			})
			.finally(() => setIsLoading(false));
	}, [t, token]);

	const onDateRangeChange = useCallback((values: TimeRangePickerProps['value']) => {
		const range = (values || [])?.map((value) => dayjs(value).format('YYYY-MM-DD 00:00:00'));
		props.setDateRange(range);
	}, []);

	useEffect(() => {
		// Open panel by default if large screen (`lg`) is active, otherwise close
		if (lg) {
			setActiveKey(['1']);
		} else {
			setActiveKey([]);
		}
	}, [lg]);
	return (
		<ConfigProvider
			theme={{
				inherit: true,
				components: {
					TreeSelect: {
						nodeSelectedBg: '#fff',
						colorBorder: '#fff',
						fontSize: lg ? 14 : 10,
					},
				},
			}}
		>
			<div className={props.className}>
				<Collapse activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
					<Collapse.Panel header={t('Filter Options')} key='1'>
						<div className='border-[#000d1d]/10 rounded-md shadow-md py-3 pb-0 px-4 border border-solid border-gray-200'>
							<Form form={form} layout='vertical'>
								<Row gutter={[16, 0]}>
									<Col xs={24} lg={6} className='w-full'>
										<Form.Item label={t('dateRange')} name='dateRange'>
											<RangePicker onChange={(values) => onDateRangeChange(values)} className='w-full' />
										</Form.Item>
									</Col>
									<Col xs={12} lg={6} className='w-full'>
										<Form.Item label={t('status')} name='status' className='min-w-64'>
											<Select
												value={props.status}
												placeholder={t('all')}
												onChange={(e) => props.setStatus(e)}
												options={transactionsStatusList}
												className='w-full'
											/>
										</Form.Item>
									</Col>
									<Col xs={12} lg={6} className='w-full'>
										<Form.Item label={t('type')} name='type' className='min-w-64'>
											<TreeSelect
												className='w-full'
												onChange={(e) => {
													props.setOrderNumber('');
													props.setAccount(null);
													props.setIsP2P(
														[WalletType.P2P_BUY, WalletType.P2P_REFUND, WalletType.P2P_SELL].includes(e as WalletType),
													);

													if (['P2P', 'TRADING_ACCOUNT', 'ADMIN_OPERATION'].includes(e as string)) {
														props.setType(null);
														props.setOperation(e as string);
													} else {
														props.setType(e);
														props.setOperation(null);
													}
												}}
												value={props.type || props.operation}
												placeholder={t('all')}
												suffixIcon={null}
												treeDefaultExpandAll
												treeData={transactionsTypeList}
											/>
										</Form.Item>
									</Col>
									{(props.operation === 'P2P' || props.type?.includes('P2P')) && (
										<Col xs={24} lg={6} className='w-full'>
											<Form.Item label={t('orderNumber')} name='orderNumber' className='min-w-64'>
												<InputNumber
													defaultValue={props.orderNumber}
													value={props.orderNumber}
													onChange={(e) => props.setOrderNumber(e)}
													className='w-full'
												/>
											</Form.Item>
										</Col>
									)}
									{(props.operation === 'TRADING_ACCOUNT' || props.type?.includes('TRADING')) && (
										<Col xs={24} lg={6} className='w-full'>
											<Form.Item name='account' label={t('account')} className='min-w-64'>
												<Select
													defaultValue={t('all')}
													value={props.account?.number}
													onChange={(e) => props.setAccount(accounts.find((acc) => acc.number === e) || null)}
													options={requestAccountsList}
													showSearch
													filterOption={(inputValue, option: any) =>
														option?.label?.props?.children
															? option?.label?.props?.children?.toString().includes(inputValue.toLowerCase())
															: false
													}
												/>
											</Form.Item>
										</Col>
									)}
								</Row>
							</Form>
						</div>
					</Collapse.Panel>
				</Collapse>
				<Row gutter={[16, 16]} align='middle' className='my-2'>
					<Col xs={12} lg={12}>
						<NumberOfRowsControl
							numberOfEntries={props.numberOfEntries}
							setNumberOfEntries={props.setNumberOfEntries}
						/>
					</Col>
					<Col xs={12} lg={12} className='flex gap-2 items-center justify-end'>
						{getUserInfo()?.role === 'SUPER_USER' && (
							<Button
								onClick={() => setShowOtpModal(true)}
								className='bg-[#EAECEF] text-[#1E2329] border-none shadow-none'
								htmlType='button'
								type='primary'
								icon={<ExportOutlined />}
							>
								{t('Export')}
							</Button>
						)}
						<Button
							onClick={props.refresh}
							className='bg-[#EAECEF] text-[#1E2329] border-none shadow-none'
							htmlType='button'
							type='primary'
							icon={<SyncOutlined />}
						></Button>
						<Input
							value={props.search}
							onChange={(e) => props.setSearch(e.target.value)}
							className='xss:w-36 h-9 md:w-64 border-none'
							placeholder={t<string>('searchWallet')}
							prefix={<SearchOutlined />}
						/>
					</Col>
				</Row>
				<ExportReport
					transactionsStatusList={transactionsStatusList}
					transactionsTypeList={transactionsTypeList}
					setShowOtpModal={setShowOtpModal}
					showOtpModal={showOtpModal}
					defaultValues={{
						states: props.status ? [props.status] : [],
						types: props.type ? [props.type] : [],
						operations: props.operation ? [props.operation] : [],
						dateRange: props.dateRange || [],
					}}
				/>
			</div>
		</ConfigProvider>
	);
};
