import {
	AimOutlined,
	ControlOutlined,
	HomeOutlined,
	SettingOutlined,
	TeamOutlined,
	WalletOutlined,
} from '@ant-design/icons';
import { Withdraw } from './withdraw';
import { getUserInfo } from './localStorageHandler';
export function getSideSuperUserPanelItems(t: (value: string) => string, showMenu: boolean): any[] {
	const Email = getUserInfo()?.email ?? '';
	return [
		{
			key: 'home',
			label: t('home'),
			icon: <HomeOutlined />,
			style: { fontSize: 12 },
			id: 'side_panel_home',
			show: true, // Always show
		},
		{
			key: 'merchants',
			label: t('merchants'),
			icon: <TeamOutlined />,
			style: { fontSize: 12 },
			id: 'side_panel_merchants',
			show: true,
		},
		{
			key: 'clients',
			label: t('clients'),
			icon: <AimOutlined />,
			style: { fontSize: 12 },
			id: 'side_panel_clients',
			show: true,
		},
		{
			key: 'orders',
			label: t('orders'),
			icon: <ControlOutlined />,
			style: { fontSize: 12 },
			id: 'side_panel_orders',
			show: true, // Always show
		},
		{
			key: 'wallet',
			label: t('walletTransactions'),
			icon: <WalletOutlined />,
			style: { fontSize: 12 },
			id: 'side_panel_walletTransactions',
			show: true,
		},
		{
			key: 'withdraw-request',
			label: t('withdrawRequests'),
			icon: <Withdraw />,
			style: { fontSize: 12 },
			id: 'side_panel_withdrawRequest',
			show: true,
		},
		{
			key: 'appeal-task',
			label: t('appealTasks'),
			icon: <ControlOutlined />,
			style: { fontSize: 12 },
			id: 'side_panel_appealTasks',
			show: true,
		},
		{
			key: 'administration',
			label: t('administration'),
			icon: <SettingOutlined />,
			style: { fontSize: 12 },
			id: 'side_panel_administration',
			show: getUserInfo()?.role === 'SUPER_USER',
			children: [
				{
					label: t('countries'),
					key: 'countries',
					style: { fontSize: 12 },
					id: 'side_panel_countries',
					show: true, // Always show
				},
				{
					label: t('accountGroups'),
					key: 'account-groups',
					style: { fontSize: 12 },
					id: 'side_panel_adminusers',
					show: showMenu,
				},
				{
					label: t('adminusers'),
					key: 'admin-users',
					style: { fontSize: 12 },
					id: 'side_panel_adminusers',
					show: showMenu,
				},
				{
					label: t('maintenance'),
					key: 'maintenance',
					style: { fontSize: 12 },
					id: 'side_panel_maintenance',
					show: showMenu,
				},
				{
					label: t('currency'),
					key: 'currency',
					style: { fontSize: 12 },
					id: 'side_panel_currency',
					show: showMenu,
				},
				{
					label: t('holidays'),
					key: 'holidays',
					style: { fontSize: 12 },
					id: 'side_panel_holidays',
					show: showMenu,
				},
			].filter((item) => item.show !== false), // Filter child items
		},
	].filter((item) => item.show !== false); // Filter parent items
}

export function getSideAminPanelItems(t: (value: string) => void, color: string): any[] {
	return [
		{ key: 'home', label: t('home'), icon: <HomeOutlined />, style: { fontSize: 12 }, id: 'side_panel_home' },
		{
			key: 'merchants',
			label: t('merchants'),
			icon: <TeamOutlined />,
			style: { fontSize: 12 },
			id: 'side_panel_merchants',
		},
		{ key: 'clients', label: t('clients'), icon: <AimOutlined />, style: { fontSize: 12 }, id: 'side_panel_clients' },
		{ key: 'orders', label: t('orders'), icon: <ControlOutlined />, style: { fontSize: 12 }, id: 'side_panel_orders' },
		{
			key: 'wallet',
			label: t('walletTransactions'),
			icon: <WalletOutlined />,
			style: { fontSize: 12 },
			id: 'side_panel_walletTransactions',
		},
		{
			key: 'withdraw-request',
			label: t('withdrawRequests'),
			icon: <Withdraw />,
			style: { fontSize: 12 },
			id: 'side_panel_withdrawRequest',
		},
		{
			key: 'appeal-task',
			label: t('appealTasks'),
			icon: <ControlOutlined />,
			style: { fontSize: 12 },
			id: 'side_panel_appealTasks',
		},
	];
}
