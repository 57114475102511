import { CreditCardTwoTone, MailFilled, MailOutlined, PhoneFilled, FundViewOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Form, Modal, Row, Tooltip, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { CopyFilled } from '@ant-design/icons';
import { useEffect, useMemo, useState } from 'react';
import { getUserInfo } from '../../../helpers/localStorageHandler';
import { TransactionStatus } from '../../WalletTransactions/components/TransactionStatus';
import { WalletStatus } from '../../WalletTransactions/types/WalletStatus';
import { Link } from 'react-router-dom';
import { OTPTransactionModal } from '../../WalletTransactions/modals/OtpTransactionModal';
import axios from 'axios';
import { API_ENDPOINTS } from '../../../assets/api/endpoints';
import { PreviewPhoto } from '../../../components/previewPhoto/PreviewPhoto';
import { useConfigurationContext } from '../../../store/configurationContext';
import CaptchaButton from '../../../components/Button/CaptchaButton';
import TextArea from 'antd/es/input/TextArea';

type Account = {
	group: string;
	number: number;
	platform: string;
	balance: string;
};
type Request = {
	requestId: number;
	status: string;
	resolutionTime: string;
	assignedOn: string;
	assigneeName: string;
	assigneeId: number;
	actionBy: string;
};
type TransactionReportModalProps = {
	open?: boolean;
	payload?: {
		status: string;
		request: Request;
		date?: string;
		account?: string;
		withdrawCommission?: string;
		wallet?: string;
		amount?: string;
		transactionNumber: string;
		role: string;
		userName: string;
		phone: string;
		email: string;
		type: string;
		quantity: string;
		createdAt: string;
		orderNumber: string;
		currency: string;
		walletNumber: number;
		tradingAccount: Account;
		depositBonus: string;
		withdrawComission: string;
		id: number;
		fileId: string;
		fileLabel: string;
		comment: string | null;
	};
	isRequestLoading: boolean;
	setOpen: (open: boolean) => void;
	setShowUserListPopup?: (open: boolean) => void;
	handelAssign?: (userId: number) => void;
	onRequestAction?: (value: string, token?: string, isEnabled?: boolean, comments?: string, otp?: string) => void;
	showMailModal: (open: boolean) => void;
	showOtpModal: boolean;
	setShowOtpModal: (open: boolean) => void;
};

export const TransactionReportModal = (props: TransactionReportModalProps) => {
	const { t } = useTranslation();
	const userInfo = getUserInfo();

	const [showPreviewPhoto, setShowPreviewPhoto] = useState(false);
	const [aggree, setAggree] = useState(false);
	const [photo, setPhoto] = useState('');
	const [recaptcha, setRecaptcha] = useState('');
	const [isEnabled, setIsEnabled] = useState(false);
	const [version, setRecapchaV] = useState('');
	const [comments, setComments] = useState('');

	const { configurationState } = useConfigurationContext();
	const withdraw_required_live_picture =
		configurationState.find((item) => item.configKey === 'withdraw_required_live_picture')?.value || false;
	const request_action_otp_enabled = configurationState.find((item) => item.configKey === 'request_action_otp_enabled')
		?.value
		? configurationState.find((item) => item.configKey === 'request_action_otp_enabled')?.value === 'true'
		: true;

	const fields = useMemo(
		() => [
			{ label: t('status'), value: <TransactionStatus status={props.payload?.status as WalletStatus} /> },
			{
				label: t('transactionId'),
				value: (
					<>
						<div id='transactionId'> {props.payload?.transactionNumber}</div>{' '}
						<CopyFilled className='mx-1 cursor-pointer' onClick={() => copyMyText('transactionId')} />
					</>
				),
			},
			{ label: t('date'), value: dayjs(props.payload?.createdAt).format('DD/MM/YYYY hh:mm:ss') },
			{
				label: t('account'),
				value: (
					<>
						<div id='account'>
							{props.payload?.tradingAccount?.group}| {props.payload?.tradingAccount?.number} |{' '}
							{props.payload?.tradingAccount?.platform} | {props.payload?.tradingAccount?.balance}
						</div>
						<CopyFilled className='mx-1 cursor-pointer' onClick={() => copyMyText('account')} />
					</>
				),
			},
			{
				label: t('withdrawCommission'),
				value: props.payload?.withdrawComission,
			},
			{
				label: t('Currency/Wallet'),
				value: (
					<>
						<div>
							{props.payload?.currency} |
							<Link
								to={`/${props.payload?.role === 'MERCHANT' ? 'merchant' : 'client'}/?id=${props.payload
									?.id}&action=wallets`}
								className='hover:text-black text-black underline'
							>
								<span id='wallet'>{props.payload?.walletNumber}</span>
							</Link>
						</div>
						<CopyFilled className='mx-1 cursor-pointer' onClick={() => copyMyText('wallet')} />
					</>
				),
			},
			{ label: t('amount'), value: props.payload?.quantity },
			...(props.payload?.comment
				? [
						{
							label: t('comment/note'),
							value: props.payload?.comment,
						},
				  ]
				: []),
		],
		[props.payload],
	);

	const nameInitials = String(props?.payload?.userName)
		?.split(' ')
		.map((name) => String(name?.[0])?.toUpperCase())
		.join('');

	const copyMyText = (value: string) => {
		const textToCopy = document.getElementById(value) as HTMLInputElement;
		if (textToCopy) {
			navigator.clipboard.writeText(textToCopy.innerHTML);
			notification['success']({ message: '', description: t<string>('copiedSuccessfully') });
		}
	};

	const vervifyOtp = (otp: string) => {
		onRequest(aggree, otp);
	};
	const downloadFile = () => {
		axios
			.get(
				API_ENDPOINTS.getTransactionImage
					.replace('%transactionNumber%', props?.payload?.transactionNumber ?? '')
					.replace('%fileName%', props?.payload?.fileId ?? ''),
				{
					responseType: 'arraybuffer',
				},
			)
			.then((response) => {
				const base64 = btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
				setPhoto(base64);
			})
			.catch(console.error);
	};

	useEffect(() => {
		if (props.open && props?.payload?.fileId && props.payload?.transactionNumber) {
			downloadFile();
		} else {
			setPhoto('');
		}
	}, [props.open]);

	const onRequest = (isAggree: boolean, otp?: string) => {
		if (isAggree) {
			props.onRequestAction?.('ACCEPT', recaptcha, isEnabled, comments, otp);
		} else {
			props.onRequestAction?.('REJECT', recaptcha, isEnabled, comments, otp);
		}
	};
	return (
		<>
			<Modal width={640} open={props?.open} footer={null} onCancel={() => props.setOpen(false)}>
				<div className='flex flex-col gap-0'>
					<div className='mx-auto'>
						<div className='rounded-full w-16 h-16 bg-[#eee] flex items-center justify-center'>
							<CreditCardTwoTone className='text-2xl' />
						</div>
					</div>
					<div className='font-bold xss:text-sm md:text-lg text-center flex justify-center mb-2'>
						{t('withdrawFromTradingAccount')}
					</div>
					<Row>
						{/** Left */}
						<Col span={props.payload?.type === 'TRADING_WITHDRAW' ? 12 : 24} style={{ padding: 'unset' }}>
							<div>
								<div
									className='flex items-center gap-2 my-0.5 '
									style={
										props.payload?.type === 'TRADING_WITHDRAW' ? {} : { display: 'flex', justifyContent: 'center' }
									}
								>
									<Avatar className='bg-transparent border border-black text-black xss:text-[12px]'>
										{nameInitials}
									</Avatar>
									<Link
										to={`/${props.payload?.role === 'MERCHANT' ? 'merchant' : 'client'}/?id=${props.payload
											?.id}&action=wallets`}
										className='hover:text-black text-black'
									>
										<div className='font-bold  xss:text-sm md:text-lg '>{props?.payload?.userName}</div>
									</Link>
								</div>
								<div
									className='flex  xss:text-[7px] md:text-[10px] my-0.5 xss:flex-col md:flex-row'
									style={{ justifyContent: props.payload?.type === 'TRADING_WITHDRAW' ? 'start' : 'center' }}
								>
									<div className='mr-2'>
										<PhoneFilled />
										<span className='mx-1'>{props?.payload?.phone}</span>
									</div>
									<div>
										<MailFilled />
										<span className='mx-1'>{props?.payload?.email}</span>
									</div>
								</div>
								<div
									className='my-1'
									style={
										props.payload?.type === 'TRADING_WITHDRAW' ? {} : { display: 'flex', justifyContent: 'center' }
									}
								>
									<Button
										icon={<MailOutlined />}
										className='shadow-none border-none bg-[#00AFFF] text-white hover:!text-white xss:!text-[8px] md:!text-[12px]'
										htmlType='button'
										size='small'
										onClick={() => props.showMailModal(true)}
									>
										{t('contactUser')}
									</Button>
								</div>
							</div>
						</Col>
						{/** Right */}
						{props.payload?.type === 'TRADING_WITHDRAW' && (
							<Col span={12} style={{ padding: 'unset', height: '100%' }}>
								<div className='border-dashed p-2 border-black/20 bg-yellow-100 justify-between text-black rounded-md flex flex-col h-full'>
									<div className='flex flex-col gap-2 xss:text-[8px] md:text-[12px] mb-2'>
										<div className='flex items-center justify-between'>
											<strong>{t('assignee')}:</strong>
											<span>{props?.payload.request?.assigneeName || '-'}</span>
										</div>
										<div className='flex items-center justify-between'>
											<strong>{t('assignedOn')}:</strong>
											<span>{dayjs(props?.payload?.request.assignedOn).format('MMM D, YYYY h:mm A') || '-'}</span>
										</div>
										<div className='flex items-center justify-between'>
											<strong>{t('status')}:</strong>
											<span>{t(props?.payload?.request.status) || '-'}</span>
										</div>
										{props?.payload?.request.status !== 'PENDING_APPROVAL' && (
											<div className='flex items-center justify-between'>
												<strong>{t('actionBy')}:</strong>
												<span>{t(props?.payload?.request.actionBy) || '-'}</span>
											</div>
										)}
									</div>
									{props?.payload?.request.status == 'PENDING_APPROVAL' && (
										<div className='text-center'>
											<Button
												className='shadow-none  !px-4 border-none text-white hover:!text-white xss:!text-[10px] md:!text-[12px]'
												htmlType='button'
												disabled={userInfo?.role === 'ADMIN' && props.payload.request.assigneeId === userInfo?.id}
												style={
													userInfo?.role === 'ADMIN' && props.payload.request.assigneeId === userInfo?.id
														? { backgroundColor: 'lightgray' }
														: { backgroundColor: '#008dff' }
												}
												size='small'
												onClick={() =>
													userInfo?.role === 'ADMIN'
														? props.handelAssign?.(userInfo?.id ?? 1)
														: props.setShowUserListPopup?.(true)
												}
											>
												{userInfo?.role === 'ADMIN' ? t<string>('assigneToMe') : t<string>('Reassign')}
											</Button>
										</div>
									)}
								</div>
							</Col>
						)}
					</Row>
					<div className='my-0'>
						<div className='flex mb-2 text-black justify-between'>
							<p className='underline font-bold m-0'>{t('transactionReport')}</p>
							{props.payload?.fileLabel && withdraw_required_live_picture === 'true' && (
								<Tooltip title='User Live Picture'>
									<div className='text-[#1677ff]'>
										<FundViewOutlined
											className='text-[25px]'
											onClick={() => setShowPreviewPhoto(true)}
											id='transactionReport_view_image'
										/>
									</div>
								</Tooltip>
							)}
						</div>
						<ul className='p-0 list-none flex flex-col gap-2 xss:text-[9px] md:text-[13px]'>
							{fields.map((field, i) => (
								<li key={field.label} className='flex items-center justify-between' id={`transactionReport_${i}`}>
									<span>{field.label}</span>
									<span className='font-bold flex'>{field?.value} </span>
								</li>
							))}
						</ul>
					</div>
					{props?.payload?.status == 'PENDING_APPROVAL' ? (
						<div>
							<div className='font-bold mb-2'>
								{t('note')}
								<span className='font-thin text-xs'>{t('optional')}</span>
							</div>
							<Form.Item name='message'>
								<TextArea
									onChange={(e) => setComments(e.target.value)}
									placeholder={`${t<string>('typeNoteHere')}...`}
									autoSize={{ minRows: 3, maxRows: 3 }}
									className='rounded-lg'
								/>
							</Form.Item>
						</div>
					) : null}
					{props?.payload?.status == 'PENDING_APPROVAL' ? (
						<div className='flex flex-col gap-4'>
							<div className='flex gap-4'>
								<Button
									onClick={() => {
										setAggree(true);
										props.setShowOtpModal(true);
									}}
									disabled={
										(getUserInfo()?.role === 'ADMIN' && props.payload.request.assigneeId !== getUserInfo()?.id) ||
										(version === 'v2' && isEnabled && !recaptcha) ||
										props.isRequestLoading
									}
									className='w-full text-xs border-none shadow-none text-white bg-green-500'
									htmlType='button'
									type='primary'
									id='transactionReport_accept'
								>
									{t('approve')}
								</Button>
								<Button
									onClick={() => {
										setAggree(false);
										props.setShowOtpModal(true);
									}}
									disabled={
										(getUserInfo()?.role === 'ADMIN' && props.payload.request.assigneeId !== getUserInfo()?.id) ||
										(version === 'v2' && isEnabled && !recaptcha) ||
										props.isRequestLoading
									}
									className='w-full text-xs border-none shadow-none text-white bg-red-500 hover:!bg-red-900'
									htmlType='button'
									type='primary'
									id='transactionReport_reject'
								>
									{t('reject')}
								</Button>
							</div>
							<div className='w-full flex justify-center'>
								<CaptchaButton
									setRecaptchaToken={setRecaptcha}
									setIsRecaptchaEnabled={setIsEnabled}
									action='WALLET_TRADING_ACTION'
									setRecapchaV={setRecapchaV}
								/>
							</div>
						</div>
					) : (
						<Button
							onClick={() => props.setOpen(false)}
							className='w-full border-none shadow-none text-white'
							style={{ background: 'linear-gradient(#00AFFF, #0077FF)' }}
							htmlType='button'
							size='large'
							type='primary'
							id='transactionReport_ok'
						>
							{t('OK')}
						</Button>
					)}
				</div>
			</Modal>
			{props.showOtpModal && (
				<OTPTransactionModal
					showOtpModal={props.showOtpModal}
					setShowOtpModal={props.setShowOtpModal}
					amount={`${props.payload?.quantity?.toLocaleString()}`}
					transactionNumber={`${props.payload?.transactionNumber}`}
					vervifyOtp={vervifyOtp}
					type={aggree}
					isLoading={false}
					showOtp={request_action_otp_enabled}
				/>
			)}
			{showPreviewPhoto && (
				<PreviewPhoto showModal={showPreviewPhoto} setShowModal={setShowPreviewPhoto} photo={photo} />
			)}
		</>
	);
};
