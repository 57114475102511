import { Button, Input, Modal, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Country.scss';
import axios from 'axios';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { getUserInfo } from '../../helpers/localStorageHandler';
import { convertMilliseconds } from '../../helpers/getRemaingTime';
import { getUserLanguageInCookie } from '../../helpers/cookiesHandler';

const numberFormatter = (num: number) => {
	return `${num > 9 ? '' : '0'}${num}`;
};
const OTP_LENGTH = 6;
export function OTPModal({
	showOTPModal,
	setOTPModal,
	handelAccept,
	code,
	getOtpUrl,
	validateOtpUrl,
	accountGroup,
}: {
	showOTPModal: boolean;
	setOTPModal: (value: boolean) => void;
	handelAccept: (otp: string) => void;
	code: string;
	getOtpUrl: string;
	validateOtpUrl: string;
	accountGroup: string;
}) {
	const { t } = useTranslation();
	const [minutes, setMinutes] = useState('0');
	const [seconds, setSeconds] = useState('0');
	const [expireTime, setExpireTime] = useState(0);
	const [currentCountdown, setCurrentCountdown] = useState(0);
	const [otp, setOtp] = useState(['', '', '', '', '', '']);

	const token = getUserInfo()?.token;
	let intervalId: any;
	const handleOtpChange = (index: number, value: string) => {
		const newOtp = [...otp];
		newOtp[index] = value;
		// Move to the next input field if a digit is entered
		if (value !== '' && index < 6) {
			const nextInput = document.getElementById(`otp-input-${index + 1}`) as HTMLInputElement | null;
			if (nextInput) {
				nextInput.focus();
			}
		}
		if (value == '') {
			const nextInput = document.getElementById(`otp-input-${index - 1}`) as HTMLInputElement | null;
			if (nextInput) {
				nextInput.focus();
			}
		}

		setOtp(newOtp);
	};

	const sendOtp = (resend?: boolean) => {
		if (code) {
			axios(getOtpUrl.replace('%code%', code), {
				method: 'POST',
				headers: { Authorization: `Bearer ${token}` },
			})
				.then((res) => {
					setExpireTime(res.data.remainingExpiryTime);
					setCurrentCountdown(res.data.remainingExpiryTime);
					if (resend) {
						notification['success']({ message: '', description: t<string>('otpResendMessage') });
					}
				})
				.catch((error) => console.error(error));
		} else {
			axios(API_ENDPOINTS.getAccountGroupOTP, {
				data: { group: accountGroup },
				method: 'POST',
				headers: { Authorization: `Bearer ${token}` },
			}).then((res) => {
				setExpireTime(res.data.remainingExpiryTime);
				setCurrentCountdown(res.data.remainingExpiryTime);
				if (resend) {
					notification['success']({ message: '', description: t<string>('otpResendMessage') });
				}
			});
		}
	};
	useEffect(() => {
		setOtp(['', '', '', '', '', '']);
		if (showOTPModal) {
			sendOtp();
		}
	}, [showOTPModal]);

	useEffect(() => {
		clearInterval(intervalId);
		intervalId = setInterval(() => {
			setCurrentCountdown((prevCountdown) => prevCountdown - 1000);
		}, 1000);

		return () => clearInterval(intervalId);
	}, [expireTime]);

	useEffect(() => {
		const { minutes, seconds } = convertMilliseconds(currentCountdown);
		if (currentCountdown <= 0) {
			setOTPModal(false);
			clearInterval(intervalId);
			return;
		}
		setMinutes(numberFormatter(Math.floor(minutes)));
		setSeconds(numberFormatter(seconds));
	}, [currentCountdown]);

	const handleSubmit = () => {
		const otpNumber = otp.join('');
		handelAccept(otpNumber);
	};

	return (
		<Modal onCancel={() => setOTPModal(false)} open={showOTPModal} footer={null} maskClosable={false} destroyOnClose>
			<div className='flex justify-center flex-col items-center w-full'>
				<p className='text-[16px] font-bold'>{t<string>('enterOtp')}</p>
				<div className='timer-container mb-4'>
					{t<string>('expireIn')}:
					{getUserLanguageInCookie() === 'ar' ? (
						<>
							<span>{seconds}</span> : <span>{minutes}</span>{' '}
						</>
					) : (
						<>
							<span>{minutes}:</span> <span>{seconds}</span>
						</>
					)}
				</div>
				<div className='mb-4 flex '>
					<div style={{ direction: 'ltr', marginBlockStart: '5px' }}>
						{otp.map((digit, index) => (
							<Input
								key={index}
								id={`otp-input-${index}`}
								value={digit}
								onChange={(e) => handleOtpChange(index, e.target.value)}
								maxLength={1}
								style={{ width: '2rem', marginRight: '1rem', textAlign: 'center' }}
								onPaste={(e) => {
									const copiedValue = e.clipboardData.getData('text/plain').split('');
									const otpValue =
										copiedValue.length > OTP_LENGTH
											? copiedValue.slice(0, OTP_LENGTH)
											: [...copiedValue, ...Array(OTP_LENGTH - copiedValue.length).fill('')];
									setOtp(otpValue);
								}}
							/>
						))}
					</div>
				</div>
				<div>
					<p>
						{t<string>('didNotreceiveOtp')}
						<span className='text-[#0184ff] underline cursor-pointer' onClick={() => sendOtp(true)}>
							{t<string>('resend')}
						</span>
					</p>
				</div>
				<div className='flex justify-center my-4 w-[100%] gap-4'>
					<Button
						disabled={otp.some((value) => value === '')}
						className='bg-[#0192ff] text-white hover:!text-white w-[120px] '
						style={{ cursor: 'pointer', fontWeight: 400 }}
						onClick={handleSubmit}
					>
						{t<string>('submit')}
					</Button>
					<button
						className='border-solid rounded border-[1px] w-[120px] bg-transparent'
						style={{ cursor: 'pointer', fontWeight: 400 }}
						onClick={() => {
							setOTPModal(false);
						}}
					>
						{t<string>('cancel')}
					</button>
				</div>
			</div>
		</Modal>
	);
}
