import { Button, Collapse, ConfigProvider, Dropdown, Form, Grid, Input, MenuProps, Select, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import axios from 'axios';
import './Clients.scss';
import { Country } from '../../models/Country';
import { DownOutlined, ReloadOutlined, SearchOutlined, AreaChartOutlined } from '@ant-design/icons';

import { getUserInfo } from '../../helpers/localStorageHandler';
import { getClientsColumns } from '../../helpers/clientsTableColumns';
import { UserStatusModal } from '../../components/UserStatusModal/UserStatusModal';
import { UserState } from '../../models/UserState';
import { Client } from '../../models/Clients';
import { useConfigurationContext } from '../../store/configurationContext';
import { headerImage as defaultheaderImage } from '../../constant/fallback-config';
import { Link } from 'react-router-dom';
import { themeConfig } from '../WalletTransactions/config';
import { StyledPagination } from '../../components/table.style';
import Header from '../../components/Header/Header';
const Clients = () => {
	const { t } = useTranslation();
	const { lg } = Grid.useBreakpoint();
	const [clientsLoading, setClientsLoading] = useState(false);
	const [clients, setClients] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [countries, setCountries] = useState<Country[]>([]);
	const [countriesLoading, setCountriesLoading] = useState(false);
	const [countryFilter, setCountryFilter] = useState<any>();
	const [pageSize, setPageSize] = useState(10);
	const [sortBy, setSortBy] = useState('');
	const [page, setPage] = useState(0);
	const [totalElements, setTotalElements] = useState(0);
	const [client, setClient] = useState<Client>();
	const [showSuspended, setShowSuspended] = useState(false);
	const [showBlock, setShowBlock] = useState(false);
	const [showData, setShowData] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [activeKey, setActiveKey] = useState<string[] | string>(lg ? ['1'] : []);
	const token = getUserInfo()?.token;
	const { configurationState } = useConfigurationContext();
	const headerImage = configurationState.find((item) => item.configKey === 'header_image')?.value || '';
	const getClientsData = (
		page?: number,
		pageSize?: number,
		sortBy?: string,
		searchTerm?: string,
		countryFilter?: string,
	) => {
		if (sortBy?.startsWith('createdAt')) {
			sortBy = `user%23${sortBy}`;
		}
		if (sortBy?.startsWith('availableBalance') || sortBy?.startsWith('frozenBalance')) {
			sortBy = `user%23defaultWallet%23${sortBy}`;
		}
		if (sortBy?.startsWith('countryCode')) {
			sortBy = `user%23country%23${sortBy}`;
		}
		let searchQuery = '';

		if (countryFilter && !countryFilter.startsWith('All')) {
			if (searchQuery.length) {
				searchQuery += `%2Cuser%23country%23countryCode%3B${countryFilter}`;
			} else {
				searchQuery = `user%23country%23countryCode%3B${countryFilter}`;
			}
		}
		return axios.get(
			`${API_ENDPOINTS.clients}?page=${page}&pageSize=${pageSize}${sortBy ? `&sort=${sortBy}` : ''}${
				searchQuery ? `&search=${searchQuery}` : ''
			}${searchTerm ? `&wildSearch=${searchTerm.replace(/\+/g, '%2B')}` : ''}`,
			{
				headers: { Authorization: `Bearer ${token}` },
			},
		);
	};
	const getCountriesData = () => {
		return axios.get(`${API_ENDPOINTS.countries}`, {
			headers: { Authorization: `Bearer ${token}` },
		});
	};

	useEffect(() => {
		setCountriesLoading(true);
		getCountriesData()
			.then((res: any) => {
				const countriesData = res.data?.map((el: any) => {
					return {
						name: el.countryName,
						code: el.countryCode,
					};
				});

				setCountries([{ name: t('all'), code: '' }, ...countriesData]);
			})
			.catch((err) => {
				setCountriesLoading(false);
				console.error(err);
			});
	}, []);

	useEffect(() => {
		setClientsLoading(true);
		getClientsData(page, pageSize, sortBy, searchTerm, countryFilter)
			.then((res: any) => {
				const clientsData = res?.data?.data?.map((el: any) => {
					return {
						...el,
						createdAt: el.createDate ? new Date(el.createDate).toLocaleDateString('en-GB') : t('notAvailable'),
						currency: el.currencyCode,
						country: {
							name: '',
							code: el.countryCode,
						},
					};
				});
				setClients(clientsData);
				setTotalElements(res.data.totalElements);
			})
			.catch((err) => {
				console.error(err);
				setClientsLoading(false);
			})
			.finally(() => {
				setClientsLoading(false);
				setCountriesLoading(false);
			});
	}, [countryFilter, pageSize, searchValue, sortBy, page, showData]);
	useEffect(() => {
		setPage(0);
		setPageSize(10);
	}, [countryFilter, searchValue]);
	const resetFilters = () => {};
	const pageSizeItems: MenuProps['items'] = [
		{
			label: '10',
			key: '1',
			onClick: () => {
				setPage(0);
				setPageSize(10);
			},
		},
		{
			label: '20',
			key: '2',
			onClick: () => {
				setPage(0);
				setPageSize(20);
			},
		},
		{
			label: '50',
			key: '3',
			onClick: () => {
				setPage(0);
				setPageSize(50);
			},
		},
		{
			label: '100',
			key: '4',
			onClick: () => {
				setPage(0);
				setPageSize(100);
			},
		},
	];
	const handleSuspendState = () => {
		let state: UserState = client?.status.startsWith('SUSPEND') ? 'UNSUSPEND' : 'SUSPEND';
		let clientId = `${client?.clientId ?? ''}`;
		axios(`${API_ENDPOINTS.performAdminClientAction.replace('%clientId%', clientId).replace('%action%', state)}`, {
			method: 'POST',
			headers: { Authorization: `Bearer ${token}` },
		})
			.then((_) => setShowData(!showData))
			.catch((err) => console.error(err));
	};
	const handleBlockState = () => {
		let state: UserState = client?.status.startsWith('BLOCK') ? 'UNBLOCK' : 'BLOCK';
		let clientId = `${client?.clientId ?? ''}`;
		axios(`${API_ENDPOINTS.performAdminClientAction.replace('%clientId%', clientId).replace('%action%', state)}`, {
			method: 'POST',
			headers: { Authorization: `Bearer ${token}` },
		})
			.then((_) => setShowData(!showData))
			.catch((err) => console.error(err));
	};
	useEffect(() => {
		const delaySearch = setTimeout(() => {
			setSearchValue(searchTerm);
		}, 1000);
		return () => clearTimeout(delaySearch);
	}, [searchTerm]);

	useEffect(() => {
		// Open panel by default if large screen (`lg`) is active, otherwise close
		if (lg) {
			setActiveKey(['1']);
		} else {
			setActiveKey([]);
		}
	}, [lg]);
	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value || '';
	const secondary_color = configurationState.find((item) => item.configKey === 'secondary_color')?.value || '';
	return (
		<div>
			<Header title={t<string>('clients')} description={t<string>('clientsPageHeading')} />
			<div className='min-h-[84vh] mx-auto xss:px-[20px] sm:px-[75px] py-5 bg-white'>
				<Collapse activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
					<Collapse.Panel header={t('Filter Options')} key='1'>
						<div className='border-[#000d1d]/10 rounded-md shadow-md pt-3 px-4  border border-solid border-gray-200 '>
							<Form layout='vertical'>
								<div className='flex'>
									<Form.Item label={t<string>('countries')} className='w-[300px]'>
										<Select
											placeholder={t('all')}
											value={countryFilter}
											loading={countriesLoading}
											onChange={(e: string) => {
												setCountryFilter(e);
											}}
											showSearch
											filterOption={(inputValue, option: any) =>
												option?.children ? option.children[1].toLowerCase().includes(inputValue.toLowerCase()) : false
											}
										>
											{countries.map((item, index) => (
												<Select.Option key={index} value={item.code}>
													{item.name !== t('all') && (
														<img
															src={`https://flagsapi.com/${item.code}/shiny/64.png`}
															alt='country'
															className='country-flag'
														/>
													)}
													{item.name}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</div>
							</Form>
						</div>
					</Collapse.Panel>
				</Collapse>
				<div className='xss:mb-0 md:my-2 flex items-center justify-between w-full xss:flex-col-reverse md:flex-row md:flex-wrap '>
					<div className='flex items-center'>
						<span className='text-[#000D1D99] text-sm '>{t<string>('show')}</span>
						<Dropdown className='mx-[10px]' trigger={['click']} menu={{ items: pageSizeItems }}>
							<span className='bg-[#F5F5F5] h-10 w-24 flex items-center justify-between px-4 rounded-md text-[#1E2329] text-sm cursor-pointer'>
								{pageSize}
								<DownOutlined className='w-2 ' style={{ fontSize: 8 }} />
							</span>
						</Dropdown>
						<span className='text-[#000D1D99] text-sm '>{t<string>('entries')}</span>
					</div>
					<div className='flex items-center xss:my-4 lg:my-0 gap-1'>
						<Link
							to={'/dashboard?role=CLIENT'}
							className='h-9 w-9 border-none flex items-center justify-center bg-[#EAECEF] rounded-[4px] mx-2'
						>
							<AreaChartOutlined className='text-black text-[25px] px-2' />
						</Link>
						<Button
							onClick={resetFilters}
							className='h-9 w-9 border-none flex items-center justify-center bg-[#EAECEF] rounded-[4px]'
						>
							<ReloadOutlined className='h-[14px] w-[14px]' />
						</Button>
						<Input
							className='border-none h-10 bg-[#F5F5F5] search-input mx-2'
							placeholder={t<string>('searchInClientsTable')}
							value={searchTerm}
							onChange={(e) => {
								setSearchTerm(e.target.value);
							}}
							size='large'
							prefix={<SearchOutlined className='mr-2' />}
						/>
					</div>
				</div>
				<ConfigProvider theme={themeConfig}>
					<StyledPagination bgColor={themeColor} color={secondary_color}>
						<Table
							columns={getClientsColumns(t, sortBy, setSortBy, setShowSuspended, setShowBlock, setClient, !lg)}
							dataSource={clients}
							loading={clientsLoading}
							pagination={{
								pageSize,
								total: totalElements,
								onChange: (pageIndex) => setPage(pageIndex - 1),
								current: page + 1,
								showSizeChanger: false,
							}}
							tableLayout='fixed'
						/>
					</StyledPagination>
				</ConfigProvider>
			</div>
			{showSuspended && (
				<UserStatusModal
					showModal={showSuspended}
					setShowModal={setShowSuspended}
					handleUserState={handleSuspendState}
					state={client?.status.startsWith('SUSPEND') ? 'UNSUSPEND' : 'SUSPEND'}
					user={t<string>('client')}
				/>
			)}
			{showBlock && (
				<UserStatusModal
					showModal={showBlock}
					setShowModal={setShowBlock}
					handleUserState={handleBlockState}
					state={client?.status.startsWith('BLOCK') ? 'UNBLOCK' : 'BLOCK'}
					user={t<string>('client')}
				/>
			)}
		</div>
	);
};

export default Clients;
