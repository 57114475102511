import { ColumnsType } from 'antd/es/table/interface';
import { TFunction } from 'i18next';
import dayjs from 'dayjs';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Order, OrderType } from '../models/Orders';
import { handleSortClick } from './sorterColumn';
import { ColumnTitle } from '../components/ColumnTitle/ColumnTitle';
import { TableColumnType } from 'antd';

const DATE_TIME_FORMAT = 'MMM D, YYYY h:mm A';

export const getOrderColumns = (
	t: TFunction<'translation', undefined, 'translation'>,
	sortBy: string,
	setSortBy: (sortValue: string) => void,
	isMobile: boolean,
) => {
	const getStatusColor = (status: String) => {
		switch (status) {
			case 'OPENED':
				return { label: t<string>('opened'), color: '#40af0c', bgColor: '#c6e7b6' };
			case 'TRANSFERRED':
				return { label: t<string>('transferred'), color: '#09bea8', bgColor: '#e6f6f4' };
			case 'COMPLETED':
				return { label: t<string>('completed'), color: '#33a867', bgColor: '#e9ffe5' };
			case 'APPEAL':
				return { label: t<string>('appeal'), color: '#b1982c', bgColor: '#fef3c2' };
			case 'REJECTED':
				return { label: t<string>('rejected'), color: '#f6465d', bgColor: '#ffdde1' };
			case 'EXPIRED':
				return { label: t<string>('expired'), color: '#e88b00', bgColor: '#fadfb7' };
			case 'COMPLAINED':
				return { label: t<string>('complained'), color: '#f7db00', bgColor: '#f7f4a8' };
			case 'CANCELLED':
				return { label: t<string>('cancelled'), color: '#707070', bgColor: '#e8e8e8' };
			case 'PLACED':
				return { label: t<string>('placed'), color: '#2da8e8', bgColor: '#b3e5ff' };
			default:
				return { label: t<string>('unknown'), color: '#575757', bgColor: '#FFE7E7' };
		}
	};
	const isDev = window.location.hostname.includes('devb');

	const columns: ColumnsType<Order> = [
		{
			title: (
				<div className=' text-[#000D1D99] flex items-center cursor-pointer'>
					<ColumnTitle title={t<string>('orderId')} />
					<div className='flex flex-col mx-2 '>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'orderId-asc'))}
							style={{ color: sortBy === 'orderId-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'orderId-desc'))}
							style={{ color: sortBy === 'orderId-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'orderId',
			key: 'orderId',
			responsive: ['lg'],
			render: (value, record) => {
				return (
					<Link to={`/order-life-cycle/?id=${record.orderNumber}`} className='cursor-pointer underline text-[#0ea5e9]'>
						{record.orderNumber}
					</Link>
				);
			},
		},
		{
			title: (
				<div className=' text-[#000D1D99]  flex items-center cursor-pointer'>
					<ColumnTitle title={t<string>('client')} />
					<div className='flex flex-col mx-2'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'client-asc'))}
							style={{ color: sortBy === 'client-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'client-desc'))}
							style={{ color: sortBy === 'client-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'client',
			responsive: ['lg'],
			key: 'client',
			render: (value, record) => {
				return (
					<div className='word-break-column  flex items-center text-lightBlue text-[14px] font-medium'>
						{record.clientFullName}
					</div>
				);
			},
		},
		{
			title: (
				<div className=' text-[#000D1D99] flex items-center cursor-pointer'>
					<ColumnTitle title={t<string>('merchants')} />
					<div className='flex flex-col mx-2 '>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'merchant-asc'))}
							style={{ color: sortBy === 'merchant-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'merchant-desc'))}
							style={{ color: sortBy === 'merchant-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'merchants',
			key: 'merchants',
			responsive: ['lg'],
			render: (value, record) => (
				<div className='word-break-column  flex items-center  text-lightBlue text-[14px] font-medium'>
					{record.merchantFullName}
				</div>
			),
		},
		{
			title: (
				<div className=' text-[#000D1D99] flex items-center cursor-pointer'>
					<ColumnTitle title={t<string>('type')} />
					<div className='flex flex-col mx-2'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'type-asc'))}
							style={{ color: sortBy === 'type-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'type-desc'))}
							style={{ color: sortBy === 'type-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'orderType',
			key: 'orderType',
			responsive: ['lg'],
			render: (value, record) => {
				return (
					<>
						{record.type.toLocaleLowerCase() === OrderType.buy.toLocaleLowerCase() ? (
							<div className='font-medium text-[green] flex items-center'>
								{isDev ? t<string>('buy') : t<string>('deposit')}
							</div>
						) : (
							<div className='font-medium text-[red] flex items-center'>
								{isDev ? t<string>('sell') : t<string>('withdrawal')}
							</div>
						)}
					</>
				);
			},
		},
		{
			title: (
				<div className=' text-[#000D1D99]  flex items-center cursor-pointer'>
					<ColumnTitle title={t<string>('amount')} />
					<div className='flex flex-col mx-2 '>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'amount-asc'))}
							style={{ color: sortBy === 'amount-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'amount-desc'))}
							style={{ color: sortBy === 'amount-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'amount',
			key: 'amount',
			responsive: ['lg'],
			render: (value, record) => (
				<div className='font-medium text-lightBlue flex items-center'>{record.amount?.toLocaleString()}</div>
			),
			ellipsis: true,
		},
		{
			title: (
				<div className=' text-[#000D1D99] flex items-center cursor-pointer'>
					<ColumnTitle title={t<string>('price')} />
					<div className='flex flex-col mx-2'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'price-asc'))}
							style={{ color: sortBy === 'price-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'price-desc'))}
							style={{ color: sortBy === 'price-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'price',
			key: 'price',
			responsive: ['lg'],
			render: (value, record) => {
				return <div className='font-medium text-lightBlue flex items-center'>{record.price}</div>;
			},
		},
		{
			title: (
				<div className=' text-[#000D1D99]  flex items-center cursor-pointer'>
					<ColumnTitle title={t<string>('status')} />
					<div className='flex flex-col mx-2 '>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'status-asc'))}
							style={{ color: sortBy === 'status-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'status-desc'))}
							style={{ color: sortBy === 'status-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'status',
			key: 'status',
			responsive: ['lg'],
			render: (value, record) => {
				const status = record.complained && record.status === 'EXPIRED' ? 'COMPLAINED' : record.status;
				return (
					<div
						className=' items-center flex flex-wrap rounded-sm  font-medium text-[#000]'
						style={{ color: getStatusColor(status).color, flexWrap: 'nowrap' }}
					>
						<div
							style={{
								width: '5px',
								height: '5px',
								borderRadius: '100px',
								marginInlineEnd: '5px',
								backgroundColor: getStatusColor(status).color,
							}}
						/>
						<span className='mx-1'>
							{t<string>(status.toLocaleLowerCase() === 'appeal' ? 'appealed' : status.toLocaleLowerCase())}
						</span>
					</div>
				);
			},
		},
		{
			title: (
				<div className=' text-[#000D1D99] flex items-center cursor-pointer'>
					<ColumnTitle title={t<string>('creationDate')} />
					<div className='flex flex-col mx-2'>
						<CaretUpOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'createdAt-asc'))}
							style={{ color: sortBy === 'createdAt-asc' ? 'black' : 'gray' }}
						/>
						<CaretDownOutlined
							onClick={() => setSortBy(handleSortClick(sortBy, 'createdAt-desc'))}
							style={{ color: sortBy === 'createdAt-desc' ? 'black' : 'gray' }}
						/>
					</div>
				</div>
			),
			dataIndex: 'dateAndTime',
			key: 'dateAndTime',
			responsive: ['lg'],
			render: (value, record) => {
				return (
					<div className=' text-[#01081E99] flex items-center'>{dayjs(record.createdAt).format(DATE_TIME_FORMAT)}</div>
				);
			},
		},
		...(isMobile
			? [
					{
						dataIndex: 'record',
						key: 'record',
						className: 'small-screen-row',
						render: (value, record) => {
							const status = record.complained && record.status === 'EXPIRED' ? 'COMPLAINED' : record.status;
							return (
								<div className='text-xs text-[#01081E99] flex flex-row xs:flex-col mb-1 w-100 justify-between items-center '>
									<div className='w-full'>
										<div className='flex flex-row py-2 justify-between'>
											<div> {t<string>('orderId')}:</div>
											<div>
												<Link
													to={`/order-life-cycle/?id=${record.orderNumber}`}
													className='text-xs text-[#01081E99] flex items-center mb-1 text-[#0ea5e9] underline'
												>
													{record.orderNumber}
												</Link>
											</div>
										</div>
										<div className='flex flex-row py-2 justify-between'>
											<div> {t<string>('client')}:</div>
											<div className='text-lightBlue text-[15px] font-medium'>{record.clientFullName}</div>
										</div>
										<div className='flex flex-row py-2 justify-between'>
											<div> {t<string>('merchant')}:</div>
											<div className='text-lightBlue text-[15px] font-medium'>
												<div className='word-break-column  flex items-center'>{record.merchantFullName}</div>
											</div>
										</div>

										{/* TODO: change the curency if needed to local currency */}
										<div className='flex flex-row py-2 justify-between'>
											<div>{t<string>('type')}:</div>
											<div className='mx-1 text-[15px] font-medium text-lightBlue flex items-center'>
												<>
													{record.type.toLocaleLowerCase() === OrderType.buy.toLocaleLowerCase() ? (
														<div className='text-[15px] font-medium text-[green] flex items-center'>
															{isDev ? t<string>('buy') : t<string>('deposit')}
														</div>
													) : (
														<div className='text-[15px] font-medium text-[red] flex items-center'>
															{isDev ? t<string>('sell') : t<string>('withdrawal')}
														</div>
													)}
												</>
											</div>
										</div>
										<div className='flex flex-row py-2 justify-between'>
											<div>{t<string>('orderAmount')}:</div>
											<div className='text-[15px] font-medium text-lightBlue flex items-center'>
												{record.amount?.toLocaleString()}
											</div>
										</div>
										<div className='flex flex-row py-2 justify-between'>
											<div>{t<string>('price')}:</div>
											<div className='text-[15px] font-medium text-lightBlue flex items-center'>
												{record.price?.toLocaleString()}
											</div>
										</div>
										<div className='flex flex-row py-2 justify-between'>
											<div>{t<string>('status')}:</div>
											<div
												className='flex items-center flex flex-wrap rounded-sm text-[14px] font-medium text-[#000] py-1'
												style={{ color: getStatusColor(status).color }}
											>
												<div
													style={{
														width: '8px',
														height: '8px',
														borderRadius: '100px',
														marginInline: '5px',
														backgroundColor: getStatusColor(status).color,
													}}
												/>
												{t<string>(status.toLocaleLowerCase() === 'appeal' ? 'appealed' : status.toLocaleLowerCase())}
											</div>
										</div>
										<div className='flex flex-row py-2 justify-between'>
											<div>{t<string>('Date&Time')}:</div>
											<div className='text-xs text-[15px] font-medium text-lightBlue  flex items-center '>
												{dayjs(record.createdAt).format(DATE_TIME_FORMAT)}
											</div>
										</div>
									</div>
								</div>
							);
						},
					} as TableColumnType<Order>,
			  ]
			: []),
	];

	return columns;
};
