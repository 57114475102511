import { useState } from 'react';
import { OrderStateType } from '../../types/OrderStateType';

import StateTimer from './StateTimer/StateTimer';

import './OrderLifeCycleState.scss';
import { OrderType } from '../../types/OrderType';
import { Skeleton, notification, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

import { LikeFilled, PauseCircleOutlined, HistoryOutlined, CopyFilled } from '@ant-design/icons';
import axios from 'axios';
import { API_ENDPOINTS } from '../../../../assets/api/endpoints';
import { getUserInfo } from '../../../../helpers/localStorageHandler';
import { OrderDetailsType } from '../../types/OrderDetailsType';
import AdminList from './AdminList/AdminList';
import { Link } from 'react-router-dom';
import { UserStatusModal } from '../../../../components/UserStatusModal/UserStatusModal';
import { ActionTypeAdmin } from '../../types/ActionType';
import { OrderSource } from '../../types/OrderSource';

type OrderLifeCycleStateProps = {
	orderType: OrderType;
	orderState: OrderStateType;
	isMerchant: boolean;
	expireRemainingDuration: number;
	client: string;
	merchant: string;
	orderNo: string;
	clientTotalOrder: number;
	merchantTotalOrder: number;
	merchantReviews: number;
	merchantRating: number;
	merchantId: number;
	appealTask: OrderDetailsType['appealTask'];
	getOrderDetails: () => void;
	merchantData: OrderDetailsType['merchant'];
	clientData: OrderDetailsType['clientDetails'];
	triggerAction: (action: ActionTypeAdmin, otp?: string) => void;
	source: OrderSource;
	merchantCompletedOrder: number;
	clientCompletedOrder: number;
	complained: boolean;
};

const DATE_TIME_FORMAT = 'MMM D, YYYY h:mm:ss';

function OrderLifeCycleState({
	orderState,
	isMerchant,
	orderType,
	expireRemainingDuration,
	client,
	merchant,
	orderNo,
	clientTotalOrder,
	merchantTotalOrder,
	merchantReviews,
	merchantRating,
	appealTask,
	merchantId,
	getOrderDetails,
	merchantData,
	clientData,
	triggerAction,
	source,
	merchantCompletedOrder,
	clientCompletedOrder,
	complained,
}: OrderLifeCycleStateProps) {
	const [showClosePopup, setShowClosePopup] = useState(false);
	const [showSuspended, setShowSuspended] = useState(false);
	const [user, setUser] = useState<any>();
	const { t } = useTranslation();
	const userInfo = getUserInfo();

	const handelAssign = (userId: number) => {
		axios
			.post(
				API_ENDPOINTS.reassign.replace('%taskId%', `${appealTask?.taskId}`).replace('%adminId%', `${userId}`),
				{},
				{
					headers: { Authorization: `Bearer ${userInfo?.token}` },
				},
			)
			.then(() => {
				notification['success']({ message: '', description: t<string>('assignedSuccessfully') });
				getOrderDetails();
			})
			.catch((err: any) => console.error(err))
			.finally(() => setShowClosePopup(false));
	};
	const handleSuspendState = () => {
		if (user && user.clientId) {
			let userAction: ActionTypeAdmin = user?.status.startsWith('SUSPEND') ? 'UNSUSPEND_CLIENT' : 'SUSPEND_CLIENT';
			triggerAction(userAction);
		}
		if (user && user.merchantId) {
			let userAction: ActionTypeAdmin = user?.status.startsWith('SUSPEND') ? 'UNSUSPEND_MERCHANT' : 'SUSPEND_MERCHANT';
			triggerAction(userAction);
		}
	};

	const copyMyText = (value: string) => {
		const textToCopy = document.getElementById(value) as HTMLInputElement;
		if (textToCopy) {
			navigator.clipboard.writeText(textToCopy.innerHTML);
			notification['success']({ message: '', description: t<string>('copiedSuccessfully') });
		}
	};

	const suspendedMerchant = merchantData?.status === 'SUSPENDED';
	const suspendedTextMerchant = suspendedMerchant ? t('unsuspend') : t('suspend');
	const suspendedClient = clientData?.status === 'SUSPENDED';
	const suspendedTextClient = suspendedClient ? t('unsuspend') : t('suspend');
	return (
		<div className='order-life-cycle-state-main-wrapper'>
			<div className='order-life-cycle-state-wrapper xss:flex-col lg:flex-row'>
				{orderState ? (
					<>
						<div className='details-left-side xss:w-full lg:w-[50%] xss:mb-4 md:mp-0'>
							<div>
								<p className='font-bold xss:text-[15] md:text-[20px]'>{t<string>('clientDetails')}</p>
								<div className='flex items-center mt-[10px]'>
									<Tooltip title={suspendedTextClient}>
										<PauseCircleOutlined
											className=''
											style={{ color: suspendedClient ? '#f00' : '#000' }}
											onClick={() => {
												setShowSuspended(true);
												setUser(clientData);
											}}
										/>
									</Tooltip>
									<Link
										to={`/orders/?clientId=${clientData?.clientId}`}
										className='text-black flex items-center font-bold underline'
									>
										<HistoryOutlined
											className='mx-2'
											style={{ fontSize: '13px', textAlign: 'center', display: 'block' }}
										/>
									</Link>
									<Link
										to={`/client/?id=${clientData?.clientId}`}
										className='text-black flex items-center font-bold underline xss:text-[12] md:text-[15px]'
										id='olc_clientName'
									>
										{client}
									</Link>
								</div>
								<div className='my-1 xss:text-[9px] md:text-[10px] text-gray'>
									{clientCompletedOrder} {t<string>('orders')}
									<span className='vertical-seperator' />
									{(
										(Number(clientData?.ordersCompleted ?? 0) /
											(clientData?.totalOrders === null || clientData?.totalOrders === 0
												? 1
												: Number(clientData?.totalOrders))) *
										100
									).toFixed()}
									% {t<string>('completionRate')}
								</div>
								<p className='mt-[5px] xss:text-[10px] md:text-[12px] font-medium'>
									{source === OrderSource.TRADING_ACCOUNT ? t<string>('account') : t<string>('WALLET')}:
									{source === OrderSource.TRADING_ACCOUNT ? (
										<Link
											to={`/client/?id=${clientData?.clientId}&action=tradingAccount`}
											className='hover:text-black text-black'
											id='olc_client_account'
										>
											<span className='xss:text-[10px] md:text-[12px] font-bold underline mx-1' id='client'>
												{clientData?.accountDetails?.accountGroup} | {clientData?.accountDetails?.number} |{' '}
												{clientData?.accountDetails?.tradingPlatform}
											</span>
										</Link>
									) : (
										<span className='xss:text-[10px] md:text-[12px] font-bold underline mx-1 cursor-pointer'>
											{clientData?.walletDetails?.currency} |{' '}
											<Link
												to={`/client/?id=${clientData?.clientId}&action=wallets`}
												className='hover:text-black text-black'
												id='olc_client_wallets'
											>
												<span id='client'>{clientData?.walletDetails?.number}</span>
											</Link>
										</span>
									)}
									<CopyFilled className='cursor-pointer' onClick={() => copyMyText('client')} />
								</p>
							</div>

							<div>
								<p className='font-bold xss:text-[15] md:text-[20px] '>{t<string>('merchantDetails')}</p>
								<div className='flex items-center mt-[10px]'>
									<Tooltip title={suspendedTextMerchant}>
										<PauseCircleOutlined
											style={{ color: suspendedMerchant ? '#f00' : '#000' }}
											onClick={() => {
												setShowSuspended(true);
												setUser(merchantData);
											}}
										/>
									</Tooltip>
									<Link
										to={`/orders/?merchantId=${merchantId}`}
										className='text-black flex items-center font-bold underline'
									>
										<HistoryOutlined
											className='mx-2'
											style={{ fontSize: '13px', textAlign: 'center', display: 'block' }}
										/>
									</Link>
									<Link
										to={`/merchant/?id=${merchantId}`}
										className='text-black flex items-center font-bold underline'
										id='olc_merchantName'
									>
										{merchant}
									</Link>
									<LikeFilled className='h-[20px] mx-1 text-[#33A867]' />
									<span className='text-[green]'>{merchantRating}%</span>
								</div>
								<div className='my-1 xss:text-[9px] md:text-[10px] text-gray'>
									{merchantReviews} {t<string>('reviews')}
									<span className='vertical-seperator' />
									{merchantCompletedOrder} {t<string>('orders')}
									<span className='vertical-seperator' />
									{(
										(Number(merchantData?.ordersCompleted ?? 0) /
											(merchantData?.totalOrders === null || merchantData?.totalOrders === 0
												? 1
												: Number(merchantData?.totalOrders))) *
										100
									).toFixed()}
									% {t<string>('completionRate')}
								</div>
								<p className='mt-[5px] text-[12px] font-medium xss:text-[10px] md:text-[12px]'>
									{source === OrderSource.TRADING_ACCOUNT ? t<string>('account') : t<string>('WALLET')}:
									{source === OrderSource.TRADING_ACCOUNT ? (
										<Link
											to={`/merchant/?id=${merchantData?.merchantId}&action=offers`}
											className='text-black hover:text-black'
											id='olc_merchant_account'
										>
											<span className='xss:text-[10px] md:text-[12px] font-bold underline mx-1' id='client'>
												{merchantData?.accountDetails?.accountGroup} | {merchantData?.accountDetails?.number} |{' '}
												{merchantData?.accountDetails?.tradingPlatform}
											</span>
										</Link>
									) : (
										<span className='xss:text-[10px] md:text-[12px] font-bold underline mx-1 cursor-pointer'>
											{merchantData?.walletDetails?.currency} |{' '}
											<Link
												to={`/merchant/?id=${merchantData?.merchantId}&action=wallets`}
												className='text-black hover:text-black'
												id='olc_merchant_wallets'
											>
												<span id='merchant'>{merchantData?.walletDetails?.number}</span>
											</Link>
										</span>
									)}
									<CopyFilled className='cursor-pointer' onClick={() => copyMyText('merchant')} />
								</p>
							</div>
						</div>

						<div className='h-fit sm:flex'>
							{appealTask && (
								<div className='assigne p-2 xss:mb-2 sm:mb-0 '>
									<div className='p-1 font-bold leading-4'>
										{t<string>('Assignee')}: <span className='font-[400]'>{appealTask.assigneeName}</span>{' '}
									</div>
									<div className='p-1 font-bold leading-4'>
										{t<string>('AssignedOn')}:{' '}
										<span className='font-[400]'>{dayjs(appealTask.assignedOn).format(DATE_TIME_FORMAT)}</span>
									</div>
									<div className='p-1 font-bold leading-4'>
										{t<string>('Resolved')}:{' '}
										<span className='font-[400]'>{appealTask.resolved ? t<string>('yes') : t<string>('no')}</span>{' '}
									</div>
									{appealTask.resolved && (
										<div className='p-1 font-bold leading-4'>
											{t<string>('resolvedBy')}: <span className='font-[400]'>{appealTask.resolvedBy}</span>{' '}
										</div>
									)}
									{appealTask.resolved === false && orderState === 'APPEAL' && (
										<div className='flex justify-center'>
											<button
												className='reassignBtn'
												disabled={userInfo?.role === 'ADMIN' && appealTask.assigneeId === userInfo?.id}
												onClick={() =>
													userInfo?.role === 'ADMIN' ? handelAssign(userInfo?.id) : setShowClosePopup(true)
												}
											>
												{userInfo?.role === 'ADMIN' ? t<string>('assigneToMe') : t<string>('Reassign')}
											</button>
										</div>
									)}
								</div>
							)}
							<StateTimer
								orderState={orderState as OrderStateType}
								expireRemainingDuration={expireRemainingDuration}
								getOrderDetails={getOrderDetails}
								complained={complained}
							/>
						</div>
					</>
				) : (
					<Skeleton active paragraph={{ rows: 1 }} />
				)}
			</div>
			<AdminList showClosePopup={showClosePopup} setShowClosePopup={setShowClosePopup} handelAssign={handelAssign} />
			{showSuspended && (
				<UserStatusModal
					showModal={showSuspended}
					setShowModal={setShowSuspended}
					handleUserState={handleSuspendState}
					state={user?.status.startsWith('SUSPEND') ? 'UNSUSPEND' : 'SUSPEND'}
					user={user?.clientId ? t<string>('client') : t<string>('merchant')}
				/>
			)}
		</div>
	);
}

export default OrderLifeCycleState;
