import React, { useLayoutEffect } from 'react';
import { Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom';
import SignIn from '../pages/SignIn/SignIn';
import ProtectedRoutes from './ProtectedRoutes';
import NotFound from '../pages/NotFound/NotFound';

import Navbar, { authChannel } from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import { getUserInfo } from '../helpers/localStorageHandler';
import AdminRoute from './AdminRoute';
import SuperAdminRoute from './SuperAdminRoute';
import { useMainContext } from '../store/MainContext';
import { RouteProvider } from '../components/CurrentRouteProvider';
import { NotificationProvider } from '../components/NotificationContextProvider';
import AnnoncemetBanner from '../components/AnnoncemetBanner/AnnoncemetBanner';
import { Helmet } from 'react-helmet';
import { useConfigurationContext } from '../store/configurationContext';
import { Grid } from 'antd';

function Views() {
	const { mainState } = useMainContext();
	const { configurationState } = useConfigurationContext();
	const userRole = React.useMemo(() => getUserInfo()?.role || 'ADMIN', [mainState.isLoggedIn]);
	const location = useLocation();
	const user = getUserInfo();

	useLayoutEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	const faviconUrl = configurationState.find((item) => item.configKey === 'tab_logo')?.value || '';
	const title = configurationState.find((item) => item.configKey === 'Company_Name')?.value || '';
	const { lg } = Grid.useBreakpoint();

	// Listen for login/logout events from other tabs
	authChannel.onmessage = (event) => {
		if (event.data.type === 'LOGOUT' || event.data.type === 'LOGIN') {
			window.location.reload();
		}
	};

	return (
		<>
			<Helmet>
				{/* General favicon */}
				<link rel='icon' type='image/png' href={faviconUrl} sizes={lg ? '32x32' : '16x16'} />

				{/* Microsoft tiles */}
				<meta name='msapplication-TileImage' content={faviconUrl} />
				<meta name='msapplication-TileColor' content='#ffffff' />
				<title>{title ? `${title} P2P Admin` : ''}</title>
			</Helmet>
			<NotificationProvider>
				<AnnoncemetBanner />
				<Navbar />
				<RouteProvider initialValue={'/'}>
					<Routes>
						{/* Visible for all users */}
						<Route path='/sign-in' element={user?.email ? <Navigate to='/merchants' replace /> : <SignIn />} />
						{/* Visible for all authorized users */}
						<Route element={<ProtectedRoutes />}>
							{userRole === 'SUPER_USER' ? SuperAdminRoute : AdminRoute}
							<Route path='*' element={<NotFound />} />
						</Route>
					</Routes>
				</RouteProvider>
			</NotificationProvider>
			<Footer />
		</>
	);
}

export default Views;
