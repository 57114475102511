import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ChangeAdminPassword.scss';
import { getUserInfo } from '../../helpers/localStorageHandler';
import { Button, Form, Input, Spin, notification } from 'antd';
import axios from 'axios';
import { API_ENDPOINTS } from '../../assets/api/endpoints';

import { PASSWORD_CHAR_LENGHT, PASSWORD_VALIDATION } from '../../assets/config/validation-regex';
import { useLocation } from 'react-router-dom';
import Header from '../../components/Header/Header';
type Password = {
	oldPassword: string;
	newPassword: string;
	confirmNewPassword: string;
};

const ChangeAdminPassword = () => {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);
	const location = useLocation();
	const userData = getUserInfo();
	const queryParams = new URLSearchParams(location.search);
	const userId = queryParams.get('userAdmin') || '';

	const onSubmit = (passwordValues: Password) => {
		console.log(passwordValues);
		setLoading(true);
		axios(`${API_ENDPOINTS.changeAdminPassword}`.replace('%userId%', userId), {
			method: 'POST',
			headers: { Authorization: `Bearer ${userData?.token}` },
			data: {
				newPassword: passwordValues.newPassword,
				confirmNewPassword: passwordValues.confirmNewPassword,
			},
		})
			.then((_) => notification['success']({ message: '', description: t<string>('UpdatedSuccessfully') }))
			.catch((err) => console.error(err))
			.finally(() => setLoading(false));
	};

	const handleValuesChange = (changedValues: Partial<Password>, allValues: Password) => {
		const isFormComplete = Object.values(allValues).every((value) => value);
		return setIsButtonDisabled(!isFormComplete);
	};
	return (
		<div>
			<Header title={t<string>('passwordChange')} />
			<div className='border border-solid border-[#E6E6E6] rounded-md p-4 max-w-[900px] mx-auto px-[75px] pt-[20px] pb-20 mt-16 bg-white'>
				<Spin spinning={loading}>
					<div style={{ margin: 'auto', maxWidth: '50%' }}>
						<Form layout='vertical' onFinish={onSubmit} onValuesChange={handleValuesChange}>
							<div className='flex flex-wrap'>
								<div className='w-full'>
									<Form.Item
										label={<b>{t<string>('newpassword')}</b>}
										name='newPassword'
										rules={[
											{ required: true, message: t<string>('passwordRequired') },
											{
												pattern: PASSWORD_VALIDATION,
												message: t<string>('passwordMsg', { value: PASSWORD_CHAR_LENGHT }),
											},
											({ getFieldValue }) => ({
												validator(_, value) {
													if (getFieldValue('confirmNewPassword') && getFieldValue('confirmNewPassword') !== value) {
														setIsButtonDisabled(true);
														return Promise.reject(new Error(t<string>('matchedPassword')));
													}
													return Promise.resolve();
												},
											}),
										]}
									>
										<Input.Password />
									</Form.Item>
								</div>
								<div className='w-full'>
									<Form.Item
										label={<b>{t<string>('confirmPassword')}</b>}
										name='confirmNewPassword'
										rules={[
											{ required: true, message: t<string>('passwordRequired') },
											{
												pattern: PASSWORD_VALIDATION,
												message: t<string>('passwordMsg', { value: PASSWORD_CHAR_LENGHT }),
											},
											({ getFieldValue }) => ({
												validator(_, value) {
													if (!value || getFieldValue('newPassword') === value) {
														return Promise.resolve();
													}
													setIsButtonDisabled(true);
													return Promise.reject(new Error(t<string>('matchedPassword')));
												},
											}),
										]}
									>
										<Input.Password />
									</Form.Item>
								</div>
								<div className='flex justify-center w-full'>
									<Form.Item className=''>
										<Button
											type='primary'
											htmlType='submit'
											loading={loading}
											disabled={isButtonDisabled}
											className='bg-[#01a2ff] hover:!bg-[#01a2ff]  border-none shadow-none text-white hover:!text-white mt-2 mb-8'
										>
											{t<string>('update')}
										</Button>
									</Form.Item>
								</div>
							</div>
						</Form>
					</div>
				</Spin>
			</div>
		</div>
	);
};

export default ChangeAdminPassword;
