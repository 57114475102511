import { useEffect, useState } from 'react';
import axios from 'axios';
import './Annoncements.scss';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import dayjs from 'dayjs';
import { useConfigurationContext } from '../../store/configurationContext';
const DATE_TIME_FORMAT = 'MMM D, YYYY h:mm A';
export default function AnnoncemetBanner() {
	const { configurationState } = useConfigurationContext();

	const [annoncements, setAnnocements] = useState<string[]>([]);
	const location = useLocation();
	const { t } = useTranslation();
	useEffect(() => {
		if (!location.pathname.includes('sign-in')) {
			axios.get(API_ENDPOINTS.getAnnoncement).then((result) => {
				const annocementsArr = result.data.map((item: any) =>
					t<string>('annoncement', {
						from: dayjs(item.startDate).format(DATE_TIME_FORMAT),
						to: dayjs(item.endDate).format(DATE_TIME_FORMAT),
					}),
				);
				setAnnocements(annocementsArr);
			});
		}
		if (location.pathname.includes('sign-in')) setAnnocements([]);
	}, [location.pathname, t]);

	if (!annoncements.length) {
		return null;
	}
	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value || '';
	const secondary_color = configurationState.find((item) => item.configKey === 'secondary_color')?.value || '';
	return (
		<div
			dir='ltr'
			id='annoncement-banner'
			style={{
				zIndex: 1000,
				width: '100%',
				height: '35px',
				boxShadow: 'white 0px 0px 5px',
				backgroundColor: themeColor,
				opacity: '.8',
				color: secondary_color,
			}}
		>
			<div className={annoncements.length === 1 ? 'no-marquee' : 'marquee'}>
				<p>
					{annoncements.map((announcement, index) => (
						<>
							<span key={index} className='marquee-item mx-5'>
								{announcement}
							</span>
							{index !== annoncements.length - 1 && <span>|</span>}
						</>
					))}
				</p>
			</div>
		</div>
	);
}
