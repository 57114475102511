import { Button, Input, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './OTPModal.scss';
import { convertMilliseconds } from '../../helpers/getRemaingTime';
import { getUserLanguageInCookie } from '../../helpers/cookiesHandler';

const numberFormatter = (num: number) => {
	return `${num > 9 ? '' : '0'}${num}`;
};
const OTP_LENGTH = 6;
function OTPModal({
	showOTPModal,
	setOTPModal,
	handelAccept,
	sendOtp,
	expireTime,
	isLoading,
}: {
	showOTPModal: boolean;
	setOTPModal: (value: boolean) => void;
	handelAccept: (otp: string) => void;
	sendOtp: (resend?: boolean) => void;
	expireTime: number;
	isLoading: boolean;
}) {
	const { t } = useTranslation();
	const [minutes, setMinutes] = useState('0');
	const [seconds, setSeconds] = useState('0');
	const [currentCountdown, setCurrentCountdown] = useState(expireTime || 0);
	const [countdownWorker, setCountdownWorker] = useState<Worker | null>(null);
	const [otp, setOtp] = useState(['', '', '', '', '', '']);

	useEffect(() => {
		// Create a new worker on each mount
		const worker = new Worker(`${process.env.PUBLIC_URL}/countdownWorker.js`);

		// Send a message to the worker to set the initial countdown
		worker.postMessage({ type: 'init', countdown: expireTime | 0 });

		// Listen for messages from the worker
		worker.onmessage = function (e) {
			if (e.data.type === 'countdown') {
				setCurrentCountdown(e.data.countdown);
			} else if (e.data.type === 'completed') {
				// Handle countdown completion
				setOTPModal(false);
			}
		};

		// Set the worker in state for cleanup
		setCountdownWorker(worker);

		// Clean up the worker when the component unmounts
		return () => {
			worker.terminate();
		};
	}, [expireTime]);

	const handleOtpChange = (index: number, value: string) => {
		const newOtp = [...otp];
		newOtp[index] = value;
		// Move to the next input field if a digit is entered
		if (value !== '' && index < 6) {
			const nextInput = document.getElementById(`otp-input-${index + 1}`) as HTMLInputElement | null;
			if (nextInput) {
				nextInput.focus();
			}
		}
		if (value == '') {
			const nextInput = document.getElementById(`otp-input-${index - 1}`) as HTMLInputElement | null;
			if (nextInput) {
				nextInput.focus();
			}
		}

		setOtp(newOtp);
	};

	useEffect(() => {
		const { hours, minutes, seconds } = convertMilliseconds(currentCountdown);
		if (hours <= 0 && minutes <= 0 && seconds <= 0) {
			setOTPModal(false);
		}
		setMinutes(numberFormatter(Math.floor(minutes)));
		setSeconds(numberFormatter(seconds));
	}, [currentCountdown]);

	const handleSubmit = () => {
		const otpNumber = otp.join('');
		handelAccept(otpNumber);
	};
	return (
		<Modal onCancel={() => setOTPModal(false)} open={showOTPModal} footer={null} maskClosable={false} destroyOnClose>
			<div className='flex justify-center flex-col items-center w-full'>
				<p className='text-[16px] font-bold'>{t<string>('enterOtp')}</p>
				<div className='timer-container mb-4'>
					{t<string>('expireIn')}:
					{getUserLanguageInCookie() === 'ar' ? (
						<>
							<span>{seconds}</span> : <span>{minutes}</span>{' '}
						</>
					) : (
						<>
							<span>{minutes}:</span> <span>{seconds}</span>
						</>
					)}
				</div>
				<div className='mb-4 flex'>
					<div style={{ direction: 'ltr', marginBlockStart: '5px' }}>
						{otp.map((digit, index) => (
							<Input
								key={index}
								id={`otp-input-${index}`}
								value={digit}
								onChange={(e) => handleOtpChange(index, e.target.value)}
								maxLength={1}
								style={{ width: '2rem', marginRight: '1rem', textAlign: 'center' }}
								onPaste={(e) => {
									const copiedValue = e.clipboardData.getData('text/plain').split('');
									const otpValue =
										copiedValue.length > OTP_LENGTH
											? copiedValue.slice(0, OTP_LENGTH)
											: [...copiedValue, ...Array(OTP_LENGTH - copiedValue.length).fill('')];
									setOtp(otpValue);
								}}
							/>
						))}
					</div>
				</div>
				<div>
					<p>
						{t<string>('didNotreceiveOtp')}
						<span className='text-[#0184ff] underline cursor-pointer' onClick={() => sendOtp(true)}>
							{t<string>('resend')}
						</span>
					</p>
				</div>
				<div className='flex justify-center my-4 w-[100%] gap-4'>
					<Button
						loading={isLoading}
						disabled={otp.some((value) => value === '')}
						className='bg-[#0192ff] text-white hover:!text-white w-[120px] '
						style={{ cursor: 'pointer', fontWeight: 400 }}
						onClick={handleSubmit}
					>
						{t<string>('submit')}
					</Button>
					<button
						className='border-solid rounded border-[1px] w-[120px] bg-transparent'
						style={{ cursor: 'pointer', fontWeight: 400 }}
						onClick={() => {
							setOTPModal(false);
						}}
					>
						{t<string>('cancel')}
					</button>
				</div>
			</div>
		</Modal>
	);
}

export default OTPModal;
