import { notification, Button, Card, Form, Input, Select, Switch, Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { isMyBuyOfferFormInValid, isMySellOfferFormInValid } from '../../helpers/isMyOfferFormValid';
import TextArea from 'antd/es/input/TextArea';
import axios from 'axios';
import { getUserInfo } from '../../helpers/localStorageHandler';
import { getCountryByCountryCode } from '../../helpers/countriesMap';
import { MyCountry } from '../../models/Country';
import { PaymentMethod as PaymentMethodItem } from '../../models/PaymentMethod';
import './MyOffers.scss';
import { useSearchParams } from 'react-router-dom';
import Header from '../../components/Header/Header';
import { Offer } from '../../models/merchant';

const MAX_VALUE_USD = 50000;
const MyOffers = () => {
	const { t } = useTranslation();
	const [sellOfferForm] = Form.useForm();
	const [buyOfferForm] = Form.useForm();
	const [myCountry, setMyCountry] = React.useState<MyCountry>({
		name: '',
		code: '',
		currencyCode: '',
	});
	const [myPaymentMethods, setMyPaymentMethods] = React.useState<PaymentMethodItem[]>([]);
	const [sellerFormSubmitDisabled, setSellerFormSubmitDisabled] = React.useState(true);
	const [buyerFormSubmitDisabled, setBuyerFormSubmitDisabled] = React.useState(true);
	const [isBuyerPublicChange, setIsBuyerPublicChange] = useState(false);
	const [isSellerPublicChange, setIsSellerPublicChange] = useState(false);
	const [sellCalculatedPrice, setSellCalculatedPrice] = useState(0);
	const [buyCalculatedPrice, setBuyCalculatedPrice] = useState(0);
	const [exchangeBaseCurrency, setExchangeBaseCurrency] = useState('');
	const [maxProfitRatio, setMaxProfitRatio] = useState(0);
	const [livePrice, setLivePrice] = useState(0);
	const [dataLoading, setDataLoading] = useState(false);
	const token = getUserInfo()?.token;
	const [buyerOfferValues, setBuyerOfferValues] = useState<Offer>();
	const [sellerOfferValues, setSellerOfferValues] = useState<Offer>();
	const [buyMax, setBuyMax] = useState<number>();
	const [buyMin, setBuyMin] = useState<number>();
	const [sellMax, setSellMax] = useState<number>();
	const [sellMin, setSellMin] = useState<number>();

	const [searchParams] = useSearchParams();
	const merchantId = searchParams.get('merchantId') || '';
	const status = searchParams.get('status');
	const isSuspended = status === 'SUSPENDED';
	const isDev = window.location.hostname.includes('devb');
	React.useEffect(() => {
		setDataLoading(true);

		Promise.all([
			axios.get(API_ENDPOINTS.myOffers.replace('%type%', 'SELL').replace('%merchantId%', merchantId), {
				headers: { Authorization: `Bearer ${token}` },
			}),
			axios.get(API_ENDPOINTS.myOffers.replace('%type%', 'BUY').replace('%merchantId%', merchantId), {
				headers: { Authorization: `Bearer ${token}` },
			}),
			axios.get(API_ENDPOINTS.livePirce, {
				headers: { Authorization: `Bearer ${token}` },
			}),
			axios.get(API_ENDPOINTS.countries, {
				headers: { Authorization: `Bearer ${token}` },
			}),
			axios.get(API_ENDPOINTS.getMerchant.replace('%merchantId%', merchantId), {
				headers: { Authorization: `Bearer ${token}` },
			}),
		])
			.then(([sellOffer, buyOffer, livePrices, countries, merchant]: any) => {
				const merchantData = merchant.data;
				const myCountry = countries.data.find((country: any) => country.countryCode === merchantData.countryCode);
				const sellerData = sellOffer.data;
				const buyerData = buyOffer.data;
				const livePriceValue = livePrices.data.find(
					(livePrice: any) => livePrice.currencyCode === merchantData.currencyCode,
				)?.rate;
				setDataLoading(false);
				setExchangeBaseCurrency(sellerData.exchangeBaseCurrency);
				setMyCountry(myCountry);
				setLivePrice(livePriceValue);
				const sellCalculatedPrice =
					sellerData.profitRatio === null ? 0 : livePriceValue - livePriceValue * sellerData.profitRatio;
				const buyCalculatedPrice =
					buyerData.profitRatio === null ? 0 : livePriceValue - livePriceValue * buyerData.profitRatio;
				setSellCalculatedPrice(sellCalculatedPrice);
				setBuyCalculatedPrice(buyCalculatedPrice);
				setMaxProfitRatio(myCountry?.maxProfitRatio * 100);
				setMyPaymentMethods(
					myCountry.paymentMethods?.map((paymentMethod: any) => ({
						key: paymentMethod.methodId.toString(),
						value: paymentMethod.methodId,
						label: paymentMethod.methodName,
					})),
				);
				sellOfferForm.setFieldsValue({
					...sellerData,
					profitRatio: sellerData.profitRatio === null ? null : sellerData.profitRatio * 100,
					currency: `${myCountry.countryName}/${myCountry.currencyCode}`,
					methods: sellerData.methods,
				});
				buyOfferForm.setFieldsValue({
					...buyerData,
					profitRatio: buyerData.profitRatio === null ? null : buyerData.profitRatio * 100,
					currency: `${myCountry.countryName}/${myCountry.currencyCode}`,
					methods: buyerData.methods,
				});
				setBuyerOfferValues({
					...buyerData,
					profitRatio: buyerData.profitRatio === null ? null : buyerData.profitRatio * 100,
					currency: `${myCountry.countryName}/${myCountry.currencyCode}`,
				});
				setSellerOfferValues({
					...sellerData,
					profitRatio: sellerData.profitRatio === null ? null : sellerData.profitRatio * 100,
					currency: `${myCountry.countryName}/${myCountry.currencyCode}`,
				});
				setBuyMin(merchantData.wallets[0].buyMin);
				setBuyMax(merchantData.wallets[0].buyMax);
				setSellMin(merchantData.wallets[0].sellMin);
				setSellMax(merchantData.wallets[0].sellMax);
			})
			.catch((_) => setDataLoading(false));

		return () => {
			sellOfferForm.resetFields();
			buyOfferForm.resetFields();
		};
	}, [buyOfferForm, sellOfferForm]);

	const isBuyerOfferPublic = useMemo(() => {
		return buyOfferForm.getFieldValue('isPublic');
	}, [buyOfferForm.getFieldValue('isPublic')]);

	const isSellerOfferPublic = useMemo(() => {
		return sellOfferForm.getFieldValue('isPublic');
	}, [sellOfferForm.getFieldValue('isPublic')]);

	useEffect(() => {
		if (isBuyerOfferPublic) {
			buyOfferForm.validateFields();
		} else {
			buyOfferForm.setFields(
				Object.keys(buyOfferForm.getFieldsValue()).map((field) => ({
					name: field,
					errors: [],
				})),
			);
		}
	}, [isBuyerPublicChange]);
	useEffect(() => {
		if (isSellerOfferPublic) {
			sellOfferForm.validateFields();
		} else {
			sellOfferForm.setFields(
				Object.keys(sellOfferForm.getFieldsValue()).map((field) => ({
					name: field,
					errors: [],
				})),
			);
		}
	}, [isSellerPublicChange]);

	const onSellerFormChange = () => {
		const sellOfferValues = sellOfferForm.getFieldsValue();
		setSellerOfferValues(sellOfferValues);

		if (sellOfferValues.profitRatio !== undefined && sellOfferValues.profitRatio !== null) {
			const profitRatio = sellOfferValues.profitRatio;
			const value = livePrice - livePrice * (profitRatio / 100);
			setSellCalculatedPrice(Number(value));
			sellOfferForm.validateFields(['max', 'min']);
		}
		setSellerFormSubmitDisabled(isSellerOfferPublic ? isMySellOfferFormInValid(sellOfferValues) : false);
	};

	const onBuyerFormChange = () => {
		const buyOfferValues = buyOfferForm.getFieldsValue();
		setBuyerOfferValues(buyOfferValues);
		if (buyOfferValues.profitRatio !== undefined && buyOfferValues.profitRatio !== null) {
			const profitRatio = buyOfferValues.profitRatio;
			const value = livePrice + livePrice * (profitRatio / 100);
			setBuyCalculatedPrice(Number(value));
			buyOfferForm.validateFields(['max', 'min']);
		}
		setBuyerFormSubmitDisabled(isBuyerOfferPublic ? isMyBuyOfferFormInValid(buyOfferValues) : false);
	};

	const onSellFormSubmit = React.useCallback((values: any) => {
		const { isPublic, max, min, termsNote, profitRatio, methods, available } = values;
		setDataLoading(true);
		axios
			.put(
				API_ENDPOINTS.myOffers.replace('%type%', 'SELL').replace('%merchantId%', merchantId),
				{
					isPublic,
					max: Number(max),
					min: Number(min),
					termsNote,
					methods,
					available,
					profitRatio: Number(profitRatio) / 100,
				},
				{ headers: { Authorization: `Bearer ${token}` } },
			)
			.then(() => {
				setDataLoading(false);
				setSellerFormSubmitDisabled(true);

				notification['success']({
					message: '',
					description: t<string>('yourSellerFormGotUpdated'),
				});
			})
			.catch((error) => {
				console.error(error);
				setDataLoading(false);
				setSellerFormSubmitDisabled(true);
			});
	}, []);

	const onBuyFormSubmit = React.useCallback((values: any) => {
		setDataLoading(true);
		axios
			.put(
				API_ENDPOINTS.myOffers.replace('%type%', 'BUY').replace('%merchantId%', merchantId),
				{ ...values, profitRatio: Number(values.profitRatio) / 100 },
				{ headers: { Authorization: `Bearer ${token}` } },
			)
			.then(() => {
				setDataLoading(false);
				setBuyerFormSubmitDisabled(true);

				notification['success']({
					message: '',
					description: t<string>('yourBuyerFormGotUpdated'),
				});
			})
			.catch((error) => {
				console.error(error);
				setDataLoading(false);
				setBuyerFormSubmitDisabled(true);
			});
	}, []);

	return (
		<section className='my-offers-container bg-white'>
			<Header title={t<string>('myOffers')} description={t<string>('myOffersSubtitle')} />
			<div className='my-offers-forms-continer gap-8'>
				<div>
					<Card title={isDev ? t<string>('buyOffer') : t<string>('depositOffer')}>
						<Form
							name='basic'
							form={buyOfferForm}
							layout='vertical'
							onValuesChange={onBuyerFormChange}
							autoComplete='off'
							disabled={dataLoading}
							onFinish={onBuyFormSubmit}
							onFieldsChange={(_, allFields) => {
								if (isBuyerOfferPublic) {
									const anyErrors = allFields.some((field: any) => field?.errors?.length > 0);
									setBuyerFormSubmitDisabled(anyErrors);
								} else {
									setBuyerFormSubmitDisabled(false);
								}
							}}
						>
							<div className='my-offers-form-content'>
								<div>
									<Form.Item label={t<string>('countryCurrency')} name='currency'>
										<Input disabled />
									</Form.Item>
									<Form.Item
										label={t<string>('profitRatio')}
										name='profitRatio'
										rules={
											isBuyerOfferPublic
												? [
														{ required: true, message: t<string>('profitRatioIsRequired') },
														() => ({
															validator(_, value) {
																if (value > maxProfitRatio) {
																	setBuyerFormSubmitDisabled(true);
																	return Promise.reject(`${t<string>('profitRatioInRangeError')}  ${maxProfitRatio}%`);
																}
																if (value < -10) {
																	setBuyerFormSubmitDisabled(true);
																	return Promise.reject(`${t<string>('profitRatioInRangeError')} -10`);
																}
																setBuyerFormSubmitDisabled(false);
																return Promise.resolve();
															},
														}),
												  ]
												: []
										}
									>
										<Input type='number' suffix='%' />
									</Form.Item>
									<Form.Item label={t<string>('calculatedPrice')}>
										<Input disabled value={buyCalculatedPrice?.toFixed(3)} suffix={myCountry.currencyCode} />
									</Form.Item>
									<Form.Item
										label={t<string>('paymentMethods')}
										name='methods'
										rules={isBuyerOfferPublic ? [{ required: true, message: t<string>('atleastSelectOneMethod') }] : []}
									>
										<Select mode='multiple' allowClear style={{ width: '100%' }} options={myPaymentMethods} />
									</Form.Item>
									<Form.Item label={t<string>('availableDeposit')} name='available'>
										<Input disabled suffix={exchangeBaseCurrency} />
									</Form.Item>
									<div className='flex  justify-between gap-2 items-start '>
										<Form.Item
											label={t<string>('minimumAmountDeposit')}
											name='min'
											rules={
												isBuyerOfferPublic
													? [
															() => ({
																validator(_, value) {
																	if (value === '') {
																		return Promise.reject(`${t<string>('amountIsRequired')}`);
																	} else if (
																		Number(Number(parseFloat(value) / buyCalculatedPrice).toFixed(3)) >
																		Number(Number(buyOfferForm.getFieldValue('available')).toFixed(3))
																	) {
																		return Promise.reject(
																			`${t<string>('amountmustbelessThanavailabel', {
																				amount: (+buyOfferForm.getFieldValue('available') * buyCalculatedPrice).toFixed(
																					3,
																				),
																				currency: myCountry.currencyCode,
																			})}`,
																		);
																	} else if (
																		buyMin &&
																		+Number(value).toFixed(3) < +Number(buyMin * buyCalculatedPrice).toFixed(3)
																	) {
																		return Promise.reject(
																			`${t<string>('minAllowedValue', {
																				amount: (buyMin * buyCalculatedPrice).toFixed(3),
																				currency: myCountry.currencyCode,
																			})}`,
																		);
																	}
																	return value < 0
																		? Promise.reject(`${t<string>('valueMustBePositive')}`)
																		: parseFloat(buyOfferForm.getFieldValue('min')) >=
																		  parseFloat(buyOfferForm.getFieldValue('max'))
																		? Promise.reject(
																				`${t<string>('mustBeLessThanMaxValue')} ${buyOfferForm.getFieldValue('max')}`,
																		  )
																		: Promise.resolve();
																},
															}),
													  ]
													: []
											}
										>
											<Input type='number' min={0} step={'any'} suffix={myCountry.currencyCode} />
										</Form.Item>
										<Form.Item className='pt-[30px]'>
											<Input
												disabled
												value={(Number(buyerOfferValues?.min || 0) / buyCalculatedPrice || 1)?.toFixed(3)}
												suffix={exchangeBaseCurrency}
											/>
										</Form.Item>
									</div>
									<div className='flex items-start justify-between gap-2 '>
										<Form.Item
											label={t<string>('maximumAmountDeposit')}
											name='max'
											rules={
												isBuyerOfferPublic
													? [
															() => ({
																validator(_, value) {
																	if (value === '') {
																		return Promise.reject(`${t<string>('amountIsRequired')}`);
																	}
																	if (
																		+(parseFloat(value) / buyCalculatedPrice).toFixed(3) >
																		+Number(buyOfferForm.getFieldValue('available')).toFixed(3)
																	) {
																		return Promise.reject(
																			`${t<string>('amountmustbelessThanavailabel', {
																				amount: (
																					Number(buyOfferForm.getFieldValue('available')) * buyCalculatedPrice
																				).toFixed(3),
																				currency: myCountry.currencyCode,
																			})}`,
																		);
																	}
																	if (parseFloat(value) / livePrice > MAX_VALUE_USD) {
																		return Promise.reject(
																			`${t<string>('maxValueRange', {
																				amount: Math.floor(MAX_VALUE_USD * livePrice),
																			})}`,
																		);
																	} else if (
																		buyMax &&
																		Number(Number(value).toFixed(3)) >
																			Number(Number(buyMax * buyCalculatedPrice).toFixed(3))
																	) {
																		return Promise.reject(
																			`${t<string>('maxAllowedValue', {
																				amount: Number(buyMax * buyCalculatedPrice).toFixed(3),
																				currency: myCountry.currencyCode,
																			})}`,
																		);
																	}
																	return value < 0
																		? Promise.reject(`${t<string>('valueMustBePositive')}`)
																		: parseFloat(buyOfferForm.getFieldValue('max')) <=
																		  parseFloat(buyOfferForm.getFieldValue('min'))
																		? Promise.reject(
																				`${t<string>('mustBeGreaterThanMinValue')} ${buyOfferForm.getFieldValue(
																					'min',
																				)}`,
																		  )
																		: Promise.resolve();
																},
															}),
													  ]
													: []
											}
										>
											<Input type='number' min={0} step={'any'} suffix={myCountry.currencyCode} />
										</Form.Item>
										<Form.Item className='pt-[30px]'>
											<Input
												disabled
												value={((Number(buyerOfferValues?.max) || 0) / buyCalculatedPrice || 1)?.toFixed(3)}
												suffix={exchangeBaseCurrency}
											/>
										</Form.Item>
									</div>
									<Form.Item valuePropName='checked' label={t<string>('visibleForUsers')} name='isPublic'>
										<Switch
											checkedChildren={t<string>('yes')}
											unCheckedChildren={t<string>('no')}
											onChange={() => setIsBuyerPublicChange((prev) => !prev)}
										/>
									</Form.Item>
									<Form.Item
										label={t<string>('note')}
										name='termsNote'
										rules={
											isBuyerOfferPublic
												? [
														{
															validator(_, value) {
																if (!value) {
																	setBuyerFormSubmitDisabled(true);
																	return Promise.reject(`${t<string>('noteIsRequred')}`);
																} else if (value.length < 20 && value.length > 0) {
																	setBuyerFormSubmitDisabled(true);
																	return Promise.reject(`${t<string>('noteMinLength')}`);
																} else if (value.length > 1000) {
																	setBuyerFormSubmitDisabled(true);
																	return Promise.reject(`${t<string>('noteMaxLength')}`);
																} else {
																	setBuyerFormSubmitDisabled(false);
																	return Promise.resolve();
																}
															},
														},
												  ]
												: []
										}
									>
										<TextArea rows={4} />
									</Form.Item>
									<Form.Item className='flex justify-center'>
										<Tooltip placement='top' title={isSuspended ? t<string>('userSuspended') : ''}>
											<Button
												type='primary'
												htmlType='submit'
												disabled={isBuyerOfferPublic && (buyerFormSubmitDisabled || isSuspended)}
												loading={dataLoading}
											>
												{t<string>('update')}
											</Button>
										</Tooltip>
									</Form.Item>
								</div>
							</div>
						</Form>
					</Card>
				</div>
				<div>
					<Card title={isDev ? t<string>('sellOffer') : t<string>('withdrawalOffer')}>
						<Form
							name='basic'
							form={sellOfferForm}
							layout='vertical'
							onValuesChange={onSellerFormChange}
							autoComplete='off'
							disabled={dataLoading}
							onFinish={onSellFormSubmit}
							onFieldsChange={(_, allFields) => {
								if (isSellerOfferPublic) {
									const anyErrors = allFields.some((field: any) => field?.errors?.length > 0);
									setSellerFormSubmitDisabled(anyErrors);
								} else {
									setSellerFormSubmitDisabled(false);
								}
							}}
						>
							<div className='my-offers-form-content'>
								<div>
									<Form.Item label={t<string>('countryCurrency')} name='currency'>
										<Input disabled />
									</Form.Item>
									<Form.Item
										label={t<string>('profitRatio')}
										name='profitRatio'
										rules={
											isSellerOfferPublic
												? [
														{ required: true, message: t<string>('profitRatioIsRequired') },
														() => ({
															validator(_, value) {
																if (value > maxProfitRatio) {
																	setSellerFormSubmitDisabled(true);
																	return Promise.reject(`${t<string>('profitRatioInRangeError')}  ${maxProfitRatio}%`);
																}
																if (value < -10) {
																	setSellerFormSubmitDisabled(true);
																	return Promise.reject(`${t<string>('profitRatioInRangeError')} -10`);
																}
																setSellerFormSubmitDisabled(false);
																return Promise.resolve();
															},
														}),
												  ]
												: []
										}
									>
										<Input type='number' suffix='%' />
									</Form.Item>
									<Form.Item label={t<string>('calculatedPrice')}>
										<Input disabled value={sellCalculatedPrice?.toFixed(3)} suffix={myCountry.currencyCode} />
									</Form.Item>
									<Form.Item
										label={t<string>('paymentMethods')}
										name='methods'
										rules={
											isSellerOfferPublic ? [{ required: true, message: t<string>('atleastSelectOneMethod') }] : []
										}
									>
										<Select mode='multiple' allowClear style={{ width: '100%' }} options={myPaymentMethods} />
									</Form.Item>
									<Form.Item
										label={t<string>('availableWithdraw')}
										name='available'
										rules={
											isSellerOfferPublic
												? [
														{ required: true, message: t<string>('availableIsRequired') },
														() => ({
															validator(_, value) {
																if (
																	+(Number(sellerOfferValues?.min || 0) / sellCalculatedPrice).toFixed(3) >
																	+Number(value).toFixed(3)
																) {
																	return Promise.reject(
																		`${t<string>('amountmustbeBiggerThanMinmum', {
																			amount: Number(
																				+buyOfferForm.getFieldValue('available') * sellCalculatedPrice,
																			).toFixed(3),
																			currency: myCountry.currencyCode,
																		})}`,
																	);
																}
																return value < 0
																	? Promise.reject(`${t<string>('valueMustBePositive')}`)
																	: Promise.resolve();
															},
														}),
												  ]
												: []
										}
									>
										<Input type='number' min={0} step={'any'} suffix={exchangeBaseCurrency} />
									</Form.Item>
									<div className='flex items-start justify-between gap-2'>
										<Form.Item
											label={t<string>('minimumAmountWithdraw')}
											name='min'
											rules={
												isSellerOfferPublic
													? [
															{
																validator(_, value) {
																	if (value === '') {
																		return Promise.reject(`${t<string>('amountIsRequired')}`);
																	} else if (
																		+Number(parseFloat(value) / sellCalculatedPrice).toFixed(3) >
																		+Number(sellOfferForm.getFieldValue('available')).toFixed(3)
																	) {
																		return Promise.reject(
																			`${t<string>('amountmustbelessThanavailabel', {
																				amount: (
																					+sellOfferForm.getFieldValue('available') * sellCalculatedPrice
																				).toFixed(3),
																				currency: myCountry.currencyCode,
																			})}`,
																		);
																	} else if (
																		sellMin &&
																		+Number(value).toFixed(3) < +Number(sellMin * sellCalculatedPrice).toFixed(3)
																	) {
																		return Promise.reject(
																			`${t<string>('minAllowedValue', {
																				amount: Number(sellMin * sellCalculatedPrice).toFixed(3),
																				currency: myCountry.currencyCode,
																			})}`,
																		);
																	}
																	return value < 0
																		? Promise.reject(`${t<string>('valueMustBePositive')}`)
																		: parseInt(sellOfferForm.getFieldValue('min')) >=
																		  parseInt(sellOfferForm.getFieldValue('max'))
																		? Promise.reject(
																				`${t<string>('mustBeLessThanMaxValue')} - ${sellOfferForm.getFieldValue(
																					'max',
																				)}`,
																		  )
																		: Promise.resolve();
																},
															},
													  ]
													: []
											}
										>
											<Input type='number' step={'any'} min={0} suffix={myCountry.currencyCode} />
										</Form.Item>
										<Form.Item className='pt-[30px]'>
											<Input
												disabled
												value={((Number(sellerOfferValues?.min) || 0) / sellCalculatedPrice || 0)?.toFixed(3)}
												suffix={exchangeBaseCurrency}
											/>
										</Form.Item>
									</div>
									<div className='flex items-start justify-between gap-2 '>
										<Form.Item
											label={t<string>('maximumAmountWithdraw')}
											name='max'
											rules={
												isSellerOfferPublic
													? [
															{
																validator(_, value) {
																	if (value === '') {
																		return Promise.reject(`${t<string>('amountIsRequired')}`);
																	}
																	if (
																		Number(Number(parseFloat(value) / sellCalculatedPrice).toFixed(3)) >
																		Number(Number(sellOfferForm.getFieldValue('available')).toFixed(3))
																	) {
																		return Promise.reject(
																			`${t<string>('amountmustbelessThanavailabel', {
																				amount: (
																					+sellOfferForm.getFieldValue('available') * sellCalculatedPrice
																				).toFixed(3),
																				currency: myCountry.currencyCode,
																			})}`,
																		);
																	}
																	if (parseFloat(value) / livePrice > MAX_VALUE_USD) {
																		return Promise.reject(
																			`${t<string>('maxValueRange', {
																				amount: Math.floor(MAX_VALUE_USD * livePrice),
																			})}`,
																		);
																	} else if (
																		sellMax &&
																		+Number(value).toFixed(3) > +Number(sellMax * sellCalculatedPrice).toFixed(3)
																	) {
																		return Promise.reject(
																			`${t<string>('maxAllowedValue', {
																				amount: (sellMax * sellCalculatedPrice).toFixed(3),
																				currency: myCountry.currencyCode,
																			})}`,
																		);
																	}
																	return value < 0 && value !== ''
																		? Promise.reject(`${t<string>('valueMustBePositive')}`)
																		: parseFloat(sellOfferForm.getFieldValue('max')) <=
																		  parseFloat(sellOfferForm.getFieldValue('min'))
																		? Promise.reject(
																				`${t<string>('mustBeGreaterThanMinValue')} ${sellOfferForm.getFieldValue(
																					'min',
																				)}`,
																		  )
																		: Number.isNaN(sellOfferForm.getFieldValue('max'))
																		? Promise.reject(t<string>('amountIsRequired'))
																		: Promise.resolve();
																},
															},
													  ]
													: []
											}
										>
											<Input type='number' min={0} step={'any'} suffix={myCountry.currencyCode} />
										</Form.Item>
										<Form.Item className='pt-[30px]'>
											<Input
												disabled
												value={(Number(sellerOfferValues?.max || 0) / sellCalculatedPrice || 0).toFixed(3)}
												suffix={exchangeBaseCurrency}
											/>
										</Form.Item>
									</div>
									<Form.Item valuePropName='checked' label={t<string>('visibleForUsers')} name='isPublic'>
										<Switch
											checkedChildren={t<string>('yes')}
											unCheckedChildren={t<string>('no')}
											onChange={() => setIsSellerPublicChange((prev) => !prev)}
										/>
									</Form.Item>
									<Form.Item
										label={t<string>('note')}
										name='termsNote'
										rules={
											isSellerOfferPublic
												? [
														{
															validator(_, value) {
																if (!value) {
																	setSellerFormSubmitDisabled(true);
																	return Promise.reject(`${t<string>('noteIsRequred')}`);
																} else if (value.length < 20 && value.length > 0) {
																	setSellerFormSubmitDisabled(true);
																	return Promise.reject(`${t<string>('noteMinLength')}`);
																} else if (value.length > 1000) {
																	setSellerFormSubmitDisabled(true);
																	return Promise.reject(`${t<string>('noteMaxLength')}`);
																} else {
																	setSellerFormSubmitDisabled(false);
																	return Promise.resolve();
																}
															},
														},
												  ]
												: []
										}
									>
										<TextArea rows={4} />
									</Form.Item>
									<Form.Item className='flex justify-center'>
										<Tooltip placement='top' title={isSuspended ? t<string>('userSuspended') : ''}>
											<Button
												type='primary'
												htmlType='submit'
												disabled={isSellerOfferPublic && (sellerFormSubmitDisabled || isSuspended)}
												loading={dataLoading}
											>
												{t<string>('update')}
											</Button>
										</Tooltip>
									</Form.Item>
								</div>
							</div>
						</Form>
					</Card>
				</div>
			</div>
		</section>
	);
};

export default MyOffers;
