import { Modal, TableColumnType, TableColumnsType, Tag, Tooltip } from 'antd';
import { TFunction } from 'i18next';
import { DeleteFilled, PlusOutlined, EditFilled } from '@ant-design/icons';

import { Holiday, HolidayType } from './holiday';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_ENDPOINTS } from '../../assets/api/endpoints';

type ColumnTitleProps = {
	title: string;
};

const getDateValue = (type: HolidayType, record: Holiday, isLtr: boolean, translate: TFunction) => {
	switch (type) {
		case 'D': {
			if (record.startDate === record.endDate) return record.startDate;
			if (isLtr) return `${record.startDate || '-'} → ${record.endDate || '-'}`;
			return `${record.startDate || '-'} ← ${record.endDate || '-'}`;
		}
		case 'W': {
			if (record.weekDays.length === 0) return '-';
			return record.weekDays.map((value) => translate(value)).join(', '); // Use `join(', ')` instead of `concat(',')`
		}
		default:
			return '-'; // Fix: return a value
	}
};

const ColumnTitle = (props: ColumnTitleProps) => {
	return (
		<div className='text-[#000D1D99]  flex items-center cursor-pointer'>
			<span>{props.title}</span>
		</div>
	);
};

export const columns = ({
	isMobile,
	translate,
	refetch,
	isLtr,
}: {
	isMobile: boolean;
	translate: TFunction;
	refetch: () => void;
	isLtr: boolean;
}): TableColumnsType<Holiday> => {
	const showDeleteConfirmation = (id: any) => {
		Modal.confirm({
			title: translate('confirmDelete'),
			content: translate('deleteHolidayConfirmation'),
			okText: translate('yes'),
			cancelText: translate('no'),
			onOk() {
				axios
					.delete(`${API_ENDPOINTS.getHoliday}`.replace('%id%', id))
					.then((res) => {
						refetch();
					})
					.catch((err) => console.error(err));
			},
			onCancel() {
				Modal.destroyAll();
			},
		});
	};
	return [
		{
			responsive: ['lg'],
			title: (
				<Tooltip placement='top' title={translate<string>('add')}>
					<Link to={`/add-holiday`}>
						<PlusOutlined className='mx-2 cursor-pointer text-black font-bold' />
					</Link>
				</Tooltip>
			),
			dataIndex: 'add',
			key: 'add',
			ellipsis: true,
			render: (value, record) => {
				return (
					<div className='flex items-center'>
						<Tooltip placement='top' title={translate<string>('edit')}>
							<Link to={`/add-holiday?id=${record.id}`}>
								<EditFilled className='flex items-center cursor-pointer  text-black font-bold' />
							</Link>
						</Tooltip>
						<Tooltip placement='top' title={translate<string>('delete')}>
							<DeleteFilled
								onClick={() => showDeleteConfirmation(record.id)}
								className='flex mx-2 items-center cursor-pointer  text-black font-bold'
							/>
						</Tooltip>
					</div>
				);
			},
		},
		{
			key: 'name',
			dataIndex: 'name',
			title: <ColumnTitle title={translate('name')} />,
			render: (value) => <div className='word-break-column  text-lightBlue text-[14px] font-medium'>{value}</div>,
			responsive: ['lg'],
		},
		{
			key: 'type',
			dataIndex: 'type',
			title: <ColumnTitle title={translate('type')} />,
			render: (value, record) => {
				return (
					<div
						className='word-break-column   text-[14px] font-medium'
						style={{ color: value === 'D' ? '#f12032' : '#82a374' }}
					>
						{value === 'D' ? translate('dateRange') : translate('weekdays')}{' '}
					</div>
				);
			},
			responsive: ['lg'],
		},
		{
			key: 'Dates/Weekdays',
			dataIndex: 'Dates/Weekdays',
			title: <ColumnTitle title={translate('Holiday Dates / Weekdays')} />,
			width: '30%',
			render: (value, record) => {
				return (
					<div className='word-break-column  text-lightBlue text-[14px] font-medium'>
						{getDateValue(record.type, record, isLtr, translate)}{' '}
					</div>
				);
			},
			responsive: ['lg'],
		},
		{
			key: 'recurring',
			dataIndex: 'recurring',
			title: <ColumnTitle title={translate('recurring')} />,
			render: (value, record) => {
				return <Tag color={value ? 'success' : 'error'}>{value ? translate('yes') : translate('no')}</Tag>;
			},
			responsive: ['lg'],
		},
		{
			key: 'active',
			dataIndex: 'active',
			title: <ColumnTitle title={translate('active')} />,
			render: (value, record) => {
				return <Tag color={value ? 'success' : 'error'}>{value ? translate('yes') : translate('no')}</Tag>;
			},
			responsive: ['lg'],
		},
		// --- Mobile ---
		...(isMobile
			? [
					{
						key: 'wallet',
						dataIndex: 'wallet',
						render: (_value, record) => (
							<div>
								<ul className='list-none p-0 m-0 flex flex-col gap-2'>
									<li className='flex items-center justify-between'>
										<div className='flex flex-row'>
											<div className='flex'>
												<Link to={`/add-holiday`}>
													<PlusOutlined
														className='p-3 flex items-center cursor-pointer  text-[20px] text-black font-bold'
														alt='add'
													/>
												</Link>
												<Tooltip placement='top' title={translate<string>('edit')} className='flex'>
													<Link to={`/add-holiday?id=${record.id}`}>
														<EditFilled className='mx-2 flex items-center cursor-pointer text-[20px] text-black font-bold' />
													</Link>
												</Tooltip>
												<Tooltip placement='top' title={'delete'}>
													<DeleteFilled
														onClick={() => showDeleteConfirmation(record.id)}
														className=' mx-2 flex items-center cursor-pointer text-[20px] text-black font-bold'
													/>
												</Tooltip>
											</div>
										</div>
									</li>
									<li className='flex items-center justify-between'>
										<span>{translate('name')}</span>
										<span>{record.name} </span>
									</li>
									<li className='flex items-center justify-between'>
										<span>{translate('type')}</span>
										<span>{record.type === 'D' ? translate('dateRange') : translate('weekdays')} </span>
									</li>
									<li className='flex items-center justify-between'>
										<span>{translate('Holiday Dates / Weekdays')}</span>
										<span>{getDateValue(record.type, record, isLtr, translate)}</span>
									</li>
									<li className='flex items-center justify-between'>
										<span>{translate('recurring')}</span>
										<span>
											<Tag color={record.recurring ? 'success' : 'error'}>
												{record.recurring ? translate('yes') : translate('no')}
											</Tag>
										</span>
									</li>
									<li className='flex items-center justify-between'>
										<span>{translate('active')}</span>
										<span>
											<Tag color={record.active ? 'success' : 'error'}>
												{record.active ? translate('yes') : translate('no')}
											</Tag>
										</span>
									</li>
								</ul>
							</div>
						),
					} as TableColumnType<Holiday>,
			  ]
			: []),
	];
};
