import { Table, ConfigProvider, Grid } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import Header from '../../components/Header/Header';
import axios from 'axios';
import { themeConfig } from '../WalletTransactions/config';
import { StyledPagination } from '../../components/table.style';
import { useConfigurationContext } from '../../store/configurationContext';
import { columns } from './columns';
import { useDirectionContext } from '../../store/DirectionContext';

const HolidaysList = () => {
	const { t } = useTranslation();
	const { lg } = Grid.useBreakpoint();
	const { directionState } = useDirectionContext();
	const [holidaysListLoading, setHolidaysListLoading] = useState(false);
	const [holidaysList, setHolidaysList] = useState([]);
	const { configurationState } = useConfigurationContext();
	const themeColor = configurationState.find((item) => item.configKey === 'theme_color')?.value || '';
	const secondary_color = configurationState.find((item) => item.configKey === 'secondary_color')?.value || '';

	const getHolidayList = () => {
		setHolidaysListLoading(true);
		axios
			.get(`${API_ENDPOINTS.getHolidayList}`)
			.then((res) => {
				setHolidaysList(res.data);
			})
			.catch(console.error)
			.finally(() => setHolidaysListLoading(false));
	};
	useEffect(() => {
		getHolidayList();
	}, []);

	const holidaysTableColumns = useMemo(
		() =>
			columns({
				isMobile: !lg,
				translate: t,
				refetch: getHolidayList,
				isLtr: directionState === 'ltr',
			}),
		[lg, t],
	);

	return (
		<div className='currencies-container'>
			<Header title={t<string>('Holidays')} description={t<string>('')} />
			<div className='min-h-[84vh] mx-auto xss:px-[10px] md:px-[75px] pt-[30px] pb-20 bg-white '>
				<ConfigProvider theme={themeConfig}>
					<StyledPagination bgColor={themeColor} color={secondary_color}>
						<Table
							columns={holidaysTableColumns}
							dataSource={holidaysList}
							loading={holidaysListLoading}
							tableLayout='fixed'
							pagination={false}
						/>
					</StyledPagination>
				</ConfigProvider>
			</div>
		</div>
	);
};

export default HolidaysList;
