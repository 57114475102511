import { Button, Form, Input, InputNumber, Spin, Switch, notification } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { API_ENDPOINTS } from '../../assets/api/endpoints';
import Header from '../../components/Header/Header';
import { getUserInfo } from '../../helpers/localStorageHandler';
import { OTPModal } from '../Country/OTPModal';

const AddCurrency = () => {
	const [form] = Form.useForm();
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const [showOTPModal, setShowOTPModal] = useState(false);
	const [currency, setCurrency] = useState({
		currencyCode: '',
		isPublic: false,
		isDefault: false,
		buyMin: '',
		buyMax: '',
		sellMin: '',
		sellMax: '',
		exchangeRate: '',
	});
	const [loading, setLoading] = useState(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);
	const token = getUserInfo()?.token;
	const queryParams = new URLSearchParams(location.search);
	const currencyId = queryParams.get('id');

	const submitRecord = (otp: string) => {
		setLoading(true);
		let data = { ...form.getFieldsValue() };
		delete data.isDefault;
		if (currencyId) {
			data['id'] = +currencyId;
		}
		data['otp'] = otp;
		axios(`${API_ENDPOINTS.currency}${currencyId ? `/${currencyId}` : ''}`, {
			method: currencyId ? 'PUT' : 'POST',
			headers: { Authorization: `Bearer ${token}` },
			data,
		})
			.then((_) => navigate('/currency'))
			.catch((err) => console.error(err))
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		if (currencyId) {
			axios
				.get(API_ENDPOINTS.getCurrency.replace('%id%', currencyId), {
					headers: { Authorization: `Bearer ${token}` },
				})
				.then(({ data }): void => {
					setCurrency({ ...data });
					form.setFieldsValue({
						...data,
					});

					form.validateFields();
				});
		}
	}, [currencyId, form, token]);

	const handleFormChange = (_: any, allValues: any) => {
		const isChanged = Object.keys(allValues).some((key) => allValues[key] !== currency[key as keyof typeof currency]);
		setIsButtonDisabled(!isChanged);
	};
	return (
		<>
			<div>
				<Header title={t<string>('Currency')} description={t<string>('CurrencySubtitle')} />
				<div className='border border-solid  border-[#E6E6E6] rounded-md max-w-[900px] mx-auto px-[75px] p-10  mt-4 bg-white'>
					<Spin spinning={loading}>
						<div>
							<Form layout='vertical' form={form} onValuesChange={handleFormChange}>
								<div className='flex flex-col items-center justify-center w-full'>
									<div className='w-full flex justify-center'>
										<Form.Item
											hasFeedback
											name='currencyCode'
											label={<b>{t<string>('currencyCode')}</b>}
											className='min-w-[300px]'
											rules={[{ required: true }]}
										>
											<Input disabled={currency.isDefault} />
										</Form.Item>
									</div>
									<div className='w-full flex justify-center'>
										<Form.Item
											hasFeedback
											name='buyMin'
											dependencies={['buyMax']}
											className='min-w-[300px]'
											label={<b>{t('buyMin')}</b>}
											rules={[
												{ required: true },
												({ getFieldValue }) => ({
													validator(_, value) {
														if (!value) return Promise.reject(new Error(t<string>('INVALID_MIN_MAX_RANGE')));
														if (!value || Number(getFieldValue('buyMax')) > Number(value)) {
															return Promise.resolve();
														} else {
															setIsButtonDisabled(true);
															return Promise.reject(new Error(t<string>('INVALID_MIN_MAX_RANGE')));
														}
													},
												}),
											]}
										>
											<InputNumber className='w-full' />
										</Form.Item>
									</div>
									<div className='w-full flex justify-center'>
										<Form.Item
											hasFeedback
											name='buyMax'
											dependencies={['buyMin']}
											label={<b>{t('buyMax')}</b>}
											className='min-w-[300px]'
											rules={[
												{ required: true },
												({ getFieldValue }) => ({
													validator(_, value) {
														if (Number(getFieldValue('buyMin')) < Number(value)) {
															return Promise.resolve();
														} else {
															setIsButtonDisabled(true);
															return Promise.reject(new Error(t<string>('INVALID_MIN_MAX_RANGE')));
														}
													},
												}),
											]}
										>
											<InputNumber className='w-full' />
										</Form.Item>
									</div>

									<div className='w-full flex justify-center'>
										<Form.Item
											hasFeedback
											name='sellMin'
											dependencies={['sellMax']}
											label={<b>{t('sellMin')}</b>}
											className='min-w-[300px]'
											rules={[
												{ required: true },
												({ getFieldValue }) => ({
													validator(_, value) {
														if (!value || Number(getFieldValue('sellMax')) > Number(value)) {
															return Promise.resolve();
														} else {
															setIsButtonDisabled(true);
															return Promise.reject(new Error(t<string>('INVALID_MIN_MAX_RANGE')));
														}
													},
												}),
											]}
										>
											<InputNumber className='w-full' />
										</Form.Item>
									</div>
									<div className='w-full flex justify-center'>
										<Form.Item
											hasFeedback
											name='sellMax'
											dependencies={['sellMin']}
											label={<b>{t('sellMax')}</b>}
											className='min-w-[300px]'
											rules={[
												{ required: true },
												({ getFieldValue }) => ({
													validator(_, value) {
														if (!value || Number(getFieldValue('sellMin')) < Number(value)) {
															return Promise.resolve();
														} else {
															setIsButtonDisabled(true);
															return Promise.reject(new Error(t<string>('INVALID_MIN_MAX_RANGE')));
														}
													},
												}),
											]}
										>
											<InputNumber className='w-full' />
										</Form.Item>
									</div>

									{currency.isDefault ? null : (
										<div className='w-full flex justify-center'>
											<Form.Item
												hasFeedback
												name='exchangeRate'
												label={<b>{t('exchangeRate')}</b>}
												className='min-w-[300px]'
											>
												<InputNumber className='w-full' />
											</Form.Item>
										</div>
									)}
									<div className='flex justify-center'>
										<Form.Item
											name='isPublic'
											valuePropName='checked'
											label={t<string>('public')}
											className='min-w-[300px]'
										>
											<Switch
												disabled={currency.isDefault}
												checkedChildren={t<string>('yes')}
												unCheckedChildren={t<string>('no')}
											/>
										</Form.Item>
									</div>
								</div>
							</Form>
							<div className='flex justify-center'>
								<Button
									onClick={() => {
										form
											.validateFields()
											.then(() => {
												setShowOTPModal(true);
											})
											.catch(() => {
												notification.error({ message: t<string>('FORM_IS_INVALID') });
											});
									}}
									className='px-10 bg-[#01A2FF] font-bold'
									style={{ boxShadow: 'none' }}
									type='primary'
									disabled={isButtonDisabled}
								>
									{t<string>(currencyId ? 'update' : 'add')}
								</Button>
							</div>
						</div>
					</Spin>
				</div>
			</div>
			<OTPModal
				showOTPModal={showOTPModal}
				setOTPModal={setShowOTPModal}
				handelAccept={submitRecord}
				code={form.getFieldValue('currencyCode')}
				getOtpUrl={API_ENDPOINTS.getCurrencyOtp}
				validateOtpUrl={API_ENDPOINTS.validateCurrencyOtp}
				accountGroup={''}
			/>
		</>
	);
};

export default AddCurrency;
